import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'customDuration'
})
export class CustomDurationPipe implements PipeTransform {

  constructor(public translate: TranslateService) { }

  transform(
    value: any | moment.Moment,
  ): any {
    const asHours = moment.duration(value, 'seconds').asHours();
    const asDays = moment.duration(value, 'seconds').asDays();

    if (asHours < 48) {
      return ({ value: asHours, unit: 'hours' });
    }

    return ({ value: Math.floor(asDays), unit: 'days' });
  }

}
