import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service'; // does not support angular universal
import { extractDomainSuffixOrCNAME } from '@app/shared/helpers/location';
import { isPlatformBrowser } from '@angular/common';
export type CookieGroupName = 'marketing' | 'social' | 'analytics';
export type CookieKey = 'lang' | 'lastActiveTeam' | 'userConsentSet' | 'ga4Analytics' | 'identity' | 'cgu' | 'fbPixelConsent' | 'linkedinConsent' | 'googleAdsConsent' | 'vimeoConsent' | 'youtubeConsent' | 'onBoarding' | 'SSOLoginOrigin' | 'lastSelectedCampaign';


export interface CookieConsent {
  enabled: boolean;
}
export interface CookieGroup {
  enabled: boolean;
}

export interface CookiesConfiguration {
  groups: { [name in CookieGroupName]?: CookieGroup };
}


export const cookies: { [name in CookieKey]: { key: string, type?: 'consent' | 'system', group?: CookieGroupName, domain?: string, expires?: number } } = {
  identity: { key: '_sid', type: 'system', expires: 2628000000 },
  lastActiveTeam: { key: '_stid', type: 'system', expires: 2628000000 },
  cgu: { key: '_scgu', type: 'system', expires: 2628000000 },
  userConsentSet: { key: '_sucs', type: 'system', expires: 15778800000 },
  fbPixelConsent: { key: '_sfbpc', type: 'consent', group: 'social', expires: 15778800000 },
  googleAdsConsent: { key: '_sgaac', type: 'consent', group: 'social', expires: 15778800000 },
  linkedinConsent: { key: '_slkic', type: 'consent', group: 'social', expires: 15778800000 },
  youtubeConsent: { key: '_syc', type: 'consent', group: 'marketing', expires: 15778800000 },
  vimeoConsent: { key: '_svc', type: 'consent', group: 'marketing', expires: 15778800000 },
  ga4Analytics: { key: '_sga4c', type: 'consent', group: 'analytics', expires: 15778800000 },
  onBoarding: { key: '_sob', type: 'system', expires: 15778800000 },
  lang: { key: '_slang', type: 'system', expires: 15778800000 },
  SSOLoginOrigin: { key: '_ssolo', type: 'system', expires: 15778800000 },
  lastSelectedCampaign: { key: '_slsc', type: 'system', expires: 15778800000 },
};

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any) {
  }

  getCookie(key: CookieKey): any {
    if (this.cookieService.get(cookies[key].key)) {
      try {
        return JSON.parse(decodeURIComponent(this.cookieService.get(cookies[key].key)));
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  setCookie(key: CookieKey, object: any): void {
    if (isPlatformBrowser(this.platformId)) {
      const cookie = cookies[key];
      this.cookieService.set(
        cookie.key,
        JSON.stringify(object),
        {
          domain: extractDomainSuffixOrCNAME(),
          path: '/',
          expires: cookie.expires ? moment().add(cookie.expires, 'ms').toDate() : cookie.expires,
        });
    }
  }

  acceptCookiesConsentGroup(group: CookieGroupName): void {
    Object.entries(cookies).forEach(([key, value]) => {
      if (cookies[key].group === group && cookies[key].type === 'consent') {
        this.setCookie(key as CookieKey, true);
      }
    });
  }

  denyCookiesConsentGroup(group: CookieGroupName): void {
    Object.entries(cookies).forEach(([key, value]) => {
      if (cookies[key].group === group && cookies[key].type === 'consent') {
        this.setCookie(key as CookieKey, false);
      }
    });
  }

  acceptAllConsentCookies(): void {
    this.setCookie('userConsentSet' as CookieKey, true);
    Object.entries(cookies).forEach(([key, value]) => {
      if (cookies[key].type === 'consent') {
        this.setCookie(key as CookieKey, true);
      }
    });
  }

  denyAllConsentCookies(): void {
    this.setCookie('userConsentSet' as CookieKey, true);
    Object.entries(cookies).forEach(([key, value]) => {
      if (cookies[key].type === 'consent') {
        this.setCookie(key as CookieKey, false);
      }
    });
  }

  getAllCookies(): any {
    const allCookies = this.cookieService.getAll();
    return allCookies;
  }

  removeCookie(key: CookieKey): void {
    return this.cookieService.delete(cookies[key].key, '/', extractDomainSuffixOrCNAME(), false, 'Lax');
  }

  cookieExists(key: CookieKey): boolean {
    return this.cookieService.check(cookies[key].key);
  }
}
