import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Inject, NgModule, Optional, PLATFORM_ID, TransferState } from '@angular/core';

import { REQUEST, RESPONSE } from '../../../../express.tokens';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Request, Response } from 'express';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { translateLoaderFactory } from './translate-loaders';
import { getCurrentLangFromPath, getMainDomainSuffixServerSide } from '../../helpers/location';
import { CookiesService, cookies } from '@app/core/services/cookies.service';
import moment from 'moment';
import { Constant } from 'src/constant';

export function TranslateMessageFormatCompilerFactory() {
    return new TranslateMessageFormatCompiler();
}


@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient, TransferState, PLATFORM_ID]
            },
            compiler: {
                provide: TranslateCompiler,
                useFactory: (TranslateMessageFormatCompilerFactory)
            },
            isolate: false
        }),
    ],
    exports: [TranslateModule]
})
export class I18nModule {
    supportedLanguages = ['en', 'fr', 'de', 'pt', 'es', 'it'];

    constructor(
        translate: TranslateService,
        private cookiesService: CookiesService,
        @Optional() @Inject(RESPONSE) private res: Response,
        @Optional() @Inject(REQUEST) private req: Request,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        const pathName = isPlatformBrowser(this.platformId) ? window.location.pathname : req.originalUrl;
        translate.addLangs(this.supportedLanguages);
        const langFromPath = getCurrentLangFromPath(pathName);

        let targetLang;
        if (langFromPath === 'en') {
            const cookieFromReq = this.getLangFromCookie();
            if (cookieFromReq) {
                targetLang = cookieFromReq
            } else if (isPlatformServer(this.platformId) && this.req.headers['accept-language']) {
                targetLang = this.parseAcceptLanguage(this.req.headers['accept-language']);
            } else {
                targetLang = langFromPath;
            }
        } else {
            targetLang = langFromPath;
        }
        if (isPlatformServer(this.platformId)) {
            this.res.cookie('_slang', JSON.stringify(targetLang), {
                domain: getMainDomainSuffixServerSide(`${this.req.headers['x-forwarded-host']}`),
                path: '/',
                expires: moment().add(cookies['lang'].expires, 'ms').toDate(),
            });
        }
        translate.use(targetLang);
    }

    getLangFromCookie() {
        if (this?.req?.cookies['_slang']) {
            return JSON.parse(this.req.cookies['_slang']);
        } else {
            return this.cookiesService.getCookie('lang');
        }
    }

    parseAcceptLanguage(acceptLanguage: string): string {
        const values = acceptLanguage.split(';').shift().split(',').map(l => l.split('-').shift());
        const preferredLanguage = values.find(value => this.supportedLanguages.indexOf(value) > -1);
        return preferredLanguage ? preferredLanguage : Constant.languageFallback;
    }
}
