import { ThemeSimplified } from '@app/shared/helpers/theme';
import { createAction, props } from '@ngrx/store';
import { ListManagedThemesPublicOutput } from '@smash-sdk/theme/04-2024';

export const LoadTheme = createAction(
  '[Theme] Load Theme',
  props<{ target: ListManagedThemesPublicOutput['themes'][0]['target'] }>()
);

export const LoadThemeSuccess = createAction(
  '[Theme] Load Theme Success',
  props<{ theme: ThemeSimplified }>()
);
