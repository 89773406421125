import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, PLATFORM_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { AppInitService } from './app-init.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { GlobalErrorHandler } from './core/errorHandler/errorHandler';
import { AppModule } from './app.module';
import { HomeComponent } from './home/home.component';
import { AuthInterceptor } from './core/http/interceptors/auth.interceptors';
import { BodyInterceptor } from './core/http/interceptors/body.interceptors';
import { EndpointInterceptor } from './core/http/interceptors/endpoint.interceptors';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { provideDialogConfig } from '@ngneat/dialog';

function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.init();
    };
}

@NgModule({
    declarations: [],
    imports: [
        AppModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule, // https://github.com/ngneat/dialog/issues/22
        ReactiveFormsModule,
        NgSelectModule, // https://github.com/ng-select/ng-select/issues/1464
        environment.additionalModules,
    ],
    providers: [
        provideDialogConfig({
            sizes: {
                xs: {
                    width: 300,
                    height: 'auto',
                    minHeight: 'none'
                },
                sm: {
                    width: 400,
                    height: 'auto',
                    minHeight: 'none'
                },
                lg: {
                    width: 450,
                    height: 'auto',
                    minHeight: 'none',
                },
                xl: {
                    height: 'auto',
                    width: 575,
                    minHeight: 'none',
                }
            }
        }),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
        {
            provide: 'externalUrlRedirectResolver',
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                if (isPlatformBrowser(PLATFORM_ID)) {
                    window.location.href = (route.data as any).externalUrl;
                }
            }
        },
        { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BodyInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [HomeComponent, AppComponent],
})
export class AppBrowserModule { }
