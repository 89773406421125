import { Action, createReducer, on } from '@ngrx/store';
import * as PaymentActions from '@app/core/actions/payment.actions';
import { SubscriptionUpdateActions } from '@app/client/subscription-update/actions';
import { SubscriptionActions } from '@app/client/signup/subscription/actions';

export const paymentFeatureKey = 'payment';

export interface State {
  paymentType: string;
  paymentProcessing: boolean;
  paymentSucceed: boolean;
  paymentFailed: boolean;
  paymentError: any;
  paypal: any;
  resetPayment: boolean;
  cardToken: any;
}

export const initialState: State = {
  paymentType: null,
  paymentProcessing: false,
  paymentSucceed: false,
  paymentFailed: false,
  paymentError: null,
  paypal: null,
  resetPayment: false,
  cardToken: null
};

const paymentReducer = createReducer(
  initialState,
  on(PaymentActions.processPayment, (state) => ({
    ...state,
    paymentProcessing: true,
    paymentSucceed: false,
    paymentFailed: false,
    paymentError: null,
  })),
  on(SubscriptionUpdateActions.renewSubscription, (state) => ({
    ...state,
    paymentSucceed: false,
    paymentFailed: false,
    paymentError: null,
  })),
  // catch payment_type
  on(SubscriptionActions.updateFormData, (state, { data }) => ({
    ...state,
    paymentType: data.payment_type,
    cardToken: data.card_token
  })),
  on(SubscriptionUpdateActions.updateFormData, (state, { data }) => ({
    ...state,
    paymentType: data.payment_type,
    cardToken: data.card_token
  })),

  // stripe
  on(PaymentActions.processStripePaymentSuccess, (state) => ({
    ...state,
    paymentProcessing: false,
    paymentSucceed: true,
  })),
  on(PaymentActions.processStripePaymentFailure, (state, error) => ({
    ...state,
    paymentProcessing: false,
    paymentFailed: true,
    paymentError: error,
  })),
  on(PaymentActions.resetSignupProcess, (state) => ({
    ...state,
    signupProcessing: false,
    accountCreationProcessing: false,
    accountCreationFailed: false,
    paymentProcessing: false,
    paymentSucceed: false,
    paymentFailed: false,
    paymentError: null,
    resetPayment: true,
  })),
  on(PaymentActions.resetSignupProcessSuccess, (state) => ({
    ...state,
    resetPayment: false,
  })),

  // paypal
  on(PaymentActions.paypalWaitUserAgreement, (state, { approvalUrl }) => ({
    ...state,
    paypal: { approvalUrl },
  })),
  on(PaymentActions.processPaypalPaymentSuccess, (state) => ({
    ...state,
    paymentProcessing: false,
    paymentSucceed: true,
    paypal: null
  })),
  on(PaymentActions.processPaypalPaymentFailure, (state, error) => ({
    ...state,
    paymentProcessing: false,
    paymentFailed: true,
    paymentError: error,
    paypal: null
  })),
  on(PaymentActions.cancelPaypal, (state) => ({
    ...state,
    paypal: null,
    paymentProcessing: false,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return paymentReducer(state, action);
}
