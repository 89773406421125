import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fromRoot from '@app/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, delay, map, mergeMap, retryWhen, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { SystemActions, TeamActions } from '../actions';
import { CustomizationService } from '../services/customization.service';

@Injectable()
export class TeamEffects {

  loadTeamCustomization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.loadTeamCustomization),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveTeam))),
      switchMap(([action, team]) => {
        if (team && team.id) {
          return this.customizationService.listTeamCustomizations(team.id).pipe(
            mergeMap((response: any) => {
              if (response && response.customizations && response.customizations.length) {
                return this.customizationService.getTeamCustomization(team.id, response.customizations[0].id).pipe(
                  mergeMap((resTeamCustomization: any) => {
                    if (!resTeamCustomization.customization.background || !resTeamCustomization.customization.logo) {
                      return this.customizationService.listManagedCustomizations().pipe(
                        mergeMap((resManagedCustomizations: any) => {
                          if (resManagedCustomizations.customizations && resManagedCustomizations.customizations.length) {
                            return this.customizationService.getManagedCustomization(resManagedCustomizations.customizations[0].id).pipe(
                              map((resManagedCustomization: any) => {
                                const logo = resTeamCustomization.customization.logo ? resTeamCustomization.customization.logo : { ...resManagedCustomization.customization.logo, type: 'SYSTEM' };
                                const background = resTeamCustomization.customization.background ? resTeamCustomization.customization.background : { ...resManagedCustomization.customization.background, type: 'SYSTEM' };
                                return TeamActions.loadTeamCustomizationSuccess(
                                  {
                                    customization: { ...resTeamCustomization.customization, logo, background }
                                  });
                              }),
                              catchError((error: HttpErrorResponse) =>
                                of(TeamActions.loadTeamCustomizationFailure({ error: error.message }))
                              )
                            );
                          }
                        }), catchError((error: HttpErrorResponse) =>
                          of(TeamActions.loadTeamCustomizationFailure({ error: error.message }))
                        ));
                    } else {
                      return of(TeamActions.loadTeamCustomizationSuccess({ customization: resTeamCustomization.customization }));
                    }
                  }),
                  retryWhen(error =>
                    error.pipe(
                      take(3),
                      delay(2000)
                    )
                  )
                );
              } else {
                return of(SystemActions.loadManagedCustomization());
              }
            }),
            catchError((error: any) =>
              of(TeamActions.loadTeamCustomizationFailure({ error: error.message }))
            )
          );
        } else {
          return of(SystemActions.loadManagedCustomization());
        }
      })
    )
  );

  loadTeamBackgrounds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.loadTeamBackgrounds),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveTeam))),
      switchMap(([action, team]) => {
        if (team && team.id) {
          return this.customizationService.listTeamBackgrounds(team.id).pipe(
            mergeMap((response: any) => {
              if (response.backgrounds.length) {
                return of(TeamActions.loadTeamBackgroundsSuccess({ backgrounds: response.backgrounds }));
              } else {
                return of(SystemActions.loadManagedBackgrounds());
              }
            }),
            retryWhen(error =>
              error.pipe(
                take(3),
                delay(2000)
              )
            ),
            catchError((error: HttpErrorResponse) =>
              of(TeamActions.loadTeamBackgroundsFailure({ error: error.message }))
            )
          );
        } else {
          return of(SystemActions.loadManagedBackgrounds());
        }
      }))
  );

  loadTeamLogos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.loadTeamLogos),
      withLatestFrom(this.store$.pipe(select(fromRoot.getActiveTeam))),
      switchMap(([action, team]) => {
        if (team && team.id) {
          return this.customizationService.listTeamLogos(team.id).pipe(
            mergeMap((response: any) => {
              if (response.logos.length) {
                return of(TeamActions.loadTeamLogosSuccess({ logos: response.logos }));
              } else {
                return of(SystemActions.loadManagedLogos());
              }
            }),
            retryWhen(error =>
              error.pipe(
                take(3),
                delay(2000)
              )
            ),
            catchError((error: HttpErrorResponse) =>
              of(TeamActions.loadTeamLogosFailure({ error: error.message }))
            )
          );
        } else {
          return of(SystemActions.loadManagedLogos());
        }
      }))
  );

  constructor(
    private actions$: Actions,
    private readonly customizationService: CustomizationService,
    private store$: Store<fromRoot.State>,
  ) { }

}
