import { Action, createReducer, on } from '@ngrx/store';
import { CaptchaActions } from '../actions';

export const captchaFeatureKey = 'captcha';

export interface State {
  challenge: string;
  error: string;

}

export const initialState: State = {
  challenge: '',
  error: '',
};

const scriptReducer = createReducer(
  initialState,
  on(CaptchaActions.verifyCaptcha, CaptchaActions.resetVerifyCaptcha, (state) => ({
    ...state,
    challenge: '',
    error: '',
  })),
  on(CaptchaActions.verifyCaptchaSuccess, (state, { challenge }) => ({
    ...state,
    challenge,
  })),
  on(CaptchaActions.verifyCaptchaFailure, (state, { error }) => ({
    ...state,
    error,
  })),

);

export function reducer(state: State | undefined, action: Action) {
  return scriptReducer(state, action);
}



