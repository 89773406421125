import { cleanDeep } from './clean-deep';
import moment from 'moment';

const LEGEND = {
    REGIONS: {
        'eu-west-1': 'a',
        'eu-west-2': 'b',
        'eu-west-3': 'c',
        'eu-central-1': 'd',
        'us-east-1': 'e',
        'us-east-2': 'f',
        'us-west-1': 'g',
        'us-west-2': 'h',
        'ca-central-1': 'i',
        'sa-east-1': 'j',
        'ap-northeast-1': 'k',
        'ap-northeast-2': 'l',
        'ap-south-1': 'm',
        'ap-southeast-1': 'n',
        'ap-southeast-2': 'o',
        'eu-north-1': 'p',
    },
    TYPES: {
        transfer: 't',
        report: 'r',
        officer: 'o',
        service: 's',
        account: 'a',
        user: 'u',
        plan: 'p',
        team: '7',
        promotion: '9',
    },
};

export function isUndefined(value: any) {
    return typeof value === 'undefined';
}

export function generateRandomString() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function makeid(length) {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

export function isFunction(value: any) {
    return typeof value === 'function';
}

export function isNumber(value: any) {
    return typeof value === 'number';
}

export function isString(value: any) {
    return typeof value === 'string';
}

export function isBoolean(value: any) {
    return typeof value === 'boolean';
}

export function isObject(value: any) {
    return value !== null && typeof value === 'object';
}

export function isInThePast(date) {
    if (!date) {
        return false;
    }
    return getTimestamp(date) < getTimestamp();
}

export function isInTheFuture(date) {
    if (!date) {
        return false;
    }
    return getTimestamp(date) > getTimestamp();
}

function getTimestamp(date = new Date()) {
    return Math.round(new Date(date).getTime() / 1000);
}

export function isNumberFinite(value: any) {
    return isNumber(value) && isFinite(value);
}

export function isVowel(letter: string): boolean {
    const vowels = ['a', 'e', 'i', 'o', 'u'];

    return vowels.indexOf(letter) !== -1;
}

export function ucFirst(text: string) {
    const [part, ...split] = text.split(/\s/g);

    const ucd = part
        .toLowerCase()
        .split(/(?=['|-])/g)
        .map(
            (word: any) =>
                word.indexOf('-') + word.indexOf('\'') > -2
                    ? word.slice(0, 2).toUpperCase() + word.slice(2)
                    : word.slice(0, 1).toUpperCase() + word.slice(1)
        )
        .join('');

    return [ucd, ...split].join(' ');
}

export function applyPrecision(num: number, precision: number) {
    if (precision <= 0) {
        return Math.round(num);
    }

    const tho = 10 ** precision;

    return Math.round(num * tho) / tho;
}

export function extractDeepPropertyByMapKey(obj: any, map: string): any {
    const keys = map.split('.');
    const head = keys.shift();

    return keys.reduce((prop: any, key: string) => {
        return !isUndefined(prop) && !isUndefined(prop[key]) ? prop[key] : undefined;
    }, obj[head || '']);
}

export function extractDeepPropertyByParentMapKey(obj: any, map: string): any {
    const keys = map.split('.');
    const tail = keys.pop();
    const props = extractDeepPropertyByMapKey(obj, keys.join('.'));

    return { props, tail };
}

export function getKeysTwoObjects(obj: any, other: any): any {
    return [...Object.keys(obj), ...Object.keys(other)].filter((key, index, array) => array.indexOf(key) === index);
}

export function isDeepEqual(obj: any, other: any): any {
    if (!isObject(obj) || !isObject(other)) {
        return obj === other;
    }

    return getKeysTwoObjects(obj, other).every(
        (key: any): boolean => {
            if (!isObject(obj[key]) && !isObject(other[key])) {
                return obj[key] === other[key];
            }
            if (!isObject(obj[key]) || !isObject(other[key])) {
                return false;
            }

            return isDeepEqual(obj[key], other[key]);
        }
    );
}

export function cleanEmptyValuesFromBody(obj: any): any {
    return cleanDeep(obj);
}

export function getRegions() {
    return LEGEND.REGIONS;
}

export function getTypes() {
    return LEGEND.TYPES;
}

export function swap(json) {
    const ret = {};
    for (const key in json) {
        ret[json[key]] = key;
    }
    return ret;
}

export function decodeRegionFromId(id: string): string {
    return swap(getRegions())[id[id.length - 2]];
}

export function sliceFile(file, { startIndex, lengthToRead }) {
    const blob = file.slice(startIndex, startIndex + lengthToRead);
    return blob;
}

export function getMatches(string, regex, index = 1) {
    const matches = [];
    let match;
    while (match = regex.exec(string)) {
        matches.push(match[index]);
    }
    return matches;
}

// https://github.com/epoberezkin/fast-deep-equal#readme
export function equal(a, b) {
    if (a === b) { return true; }

    if (a && b && typeof a === 'object' && typeof b === 'object') {
        if (a.constructor !== b.constructor) { return false; }

        let length;
        let i;
        let keys;

        if (Array.isArray(a)) {
            length = a.length;
            if (length !== b.length) { return false; }
            for (i = length; i-- !== 0;) { if (!equal(a[i], b[i])) { return false; } }
            return true;
        }

        if (a.constructor === RegExp) {
            return a.source === b.source && a.flags === b.flags;
        }
        if (a.valueOf !== Object.prototype.valueOf) {
            return a.valueOf() === b.valueOf();
        }
        if (a.toString !== Object.prototype.toString) {
            return a.toString() === b.toString();
        }

        keys = Object.keys(a);
        length = keys.length;
        // if (length !== Object.keys(b).length) { return false; }

        // for (i = length; i-- !== 0;) {
        //     if (!Object.prototype.hasOwnProperty.call(b, keys[i])) { return false; }
        // }

        for (i = length; i-- !== 0;) {
            const key = keys[i];
            if (!equal(a[key], b[key])) { return false; }
        }

        return true;
    }

    // true if both NaN, false otherwise
    return a !== a && b !== b;
}

export function formatDate(locale = 'en', date, unit) {
    moment.locale(locale);
    const dateLocale = moment.utc(date).local();
    switch (unit) {
        case 'day':
            if (locale === 'fr') {
                return dateLocale.format('D MMM');
            } else {
                return dateLocale.format('MMM D');
            }
        case 'week':
            if (locale === 'fr') {
                return dateLocale.startOf('isoWeek').format('D MMM') + ' - ' + dateLocale.endOf('isoWeek').format('D MMM');
            } else {
                return dateLocale.startOf('isoWeek').format('MMM D') + ' - ' + dateLocale.endOf('isoWeek').format('MMM D');
            }
        case 'month':
            return dateLocale.format('MMM YYYY');
        case 'year':
            return dateLocale.format('YYYY');
        default:
            if (locale === 'fr') {
                return dateLocale.format('D MMM');
            } else {
                return dateLocale.format('MMM D');
            }
    }
}

export function buildDates(period) {
    let startDate;
    let endDate;
    switch (period) {
        case '7_DAYS':
            startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            return { startDate, endDate };
        case '4_WEEKS':
            startDate = moment().startOf('isoWeek').subtract(3, 'weeks').format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            return { startDate, endDate };
        case '3_MONTHS':
            startDate = moment().startOf('month').subtract(2, 'months').format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            return { startDate, endDate };
        case '12_MONTHS':
            startDate = moment().startOf('month').subtract(11, 'months').format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            return { startDate, endDate };
        case 'LAST_YEAR':
            startDate = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
            endDate = moment().startOf('year').subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
            return { startDate, endDate };
        default:
            startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            return { startDate, endDate };
    }
}

export function buildCounterDates(unit) {
    let startDate;
    let endDate;
    switch (unit) {
        case 'TODAY':
            startDate = moment().format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            return { startDate, endDate };
        case 'THIS_WEEK':
            startDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
            endDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
            return { startDate, endDate };
        case 'THIS_MONTH':
            startDate = moment().startOf('month').format('YYYY-MM-DD');
            endDate = moment().startOf('month').format('YYYY-MM-DD');
            return { startDate, endDate };
        case 'THIS_YEAR':
            startDate = moment().startOf('year').format('YYYY-MM-DD');
            endDate = moment().startOf('year').format('YYYY-MM-DD');
            return { startDate, endDate };
        case 'LAST_YEAR':
            startDate = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
            endDate = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
            return { startDate, endDate };
    }
}

export function buildArrayFromRange(start: number, stop: number, step: number) {
    return Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );
}

export function trimObjectValues(obj: any) {
    const replacer = (key, value) => {
        if (typeof value === 'string') {
            return value.trim();
        }
        return value;
    };

    return JSON.parse(JSON.stringify(obj, replacer));
}

export function getPropByString(obj, propString, separator = '.') {
    if (!propString) {
        return obj;
    }
    const prop = propString.split(separator).reduce((o, p) => o && o[p], obj);
    return prop;
}

export function cleanInputText(str: string) {
    if (!str) {
        return '';
    }

    let stripped = str.replace(/[\n\r]/g, '');

    if (stripped === '') {
        return '';
    }

    return str;
}

export function getExponentialBackoffDelay(retryCount: number, delayTime: number, maxDelayTime?: number) {
    const delay = Math.pow(2, retryCount) * delayTime;
    if (!maxDelayTime) {
        return delay;
    }
    return delay > maxDelayTime ? maxDelayTime : delay;
}
