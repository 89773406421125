import { animate, query as q, style, transition, trigger } from '@angular/animations';

export function query(s, a, o = { optional: true }): any {
    return q(s, a, o);
}
// animate the first splash screen animation
export const splashTransition =

    trigger('splashTransition', [
        transition('* <=> *', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' })),
            query(':enter',
                [
                    style({ opacity: 0 })
                ],
                { optional: true }
            ),
            query(':leave',
                [
                    style({ opacity: 1 }),
                    animate('0.1s', style({ opacity: 0 }))
                ],
                { optional: true }
            ),

            query(':enter',
                [
                    style({ opacity: 0 }),
                    animate('.2s', style({ opacity: 1 }))
                ],
                { optional: true }
            )
        ])
    ]);
