import { Action, createReducer } from '@ngrx/store';

export const landingFeatureKey = 'landingMobile';

export interface State { }

export const initialState: State = {};

const landingReducer = createReducer(
  initialState,
);

export function reducer(state: State | undefined, action: Action) {
  return landingReducer(state, action);
}
