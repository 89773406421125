import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountActions } from '@app/core/actions';
import * as fromRoot from '@app/reducers';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, take, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private store$: Store<fromRoot.State>) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get('Authorization') === 'true') {
            return this.useSmashAuthentication(request, next);
        } else {
            return next.handle(request);
        }
    }

    applyCredentials(request, token) {
        return request.clone({
            setHeaders: { Authorization: 'Bearer ' + token },
        });
    }

    useSmashAuthentication(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.refreshTokenSubject.next(null);
        return this.store$.select(fromRoot.getToken).pipe(
            take(1),
            concatMap((token: any) => {
                return next.handle(this.applyCredentials(request, token)).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            return this.reloadAuth(request, next, error);
                        } else {
                            return throwError(error);
                        }
                    })
                );
            }),
        );
    }

    reloadAuth(request: HttpRequest<any>, next: HttpHandler, error): Observable<HttpEvent<any>> {
        return this.store$.select(fromRoot.getRefreshTokenLoading).pipe(
            take(1),
            concatMap((loading) => {
                if (!loading) {
                    this.store$.dispatch(AccountActions.refreshToken());
                    return this.store$.select(fromRoot.getRefreshTokenLoading).pipe(
                        filter(loading => !loading),
                        withLatestFrom(
                            this.store$.select(fromRoot.getRefreshTokenLoaded),
                            this.store$.select(fromRoot.getRefreshTokenError),
                            this.store$.select(fromRoot.getToken),
                        ),
                        take(1),
                        concatMap(([loading, loaded, refreshTokenError, refreshedToken]: any[]) => {
                            if (refreshTokenError) {
                                return throwError(refreshTokenError);
                            } else if (loaded) {
                                this.refreshTokenSubject.next(refreshedToken);
                                return next.handle(this.applyCredentials(request, refreshedToken));
                            } else {
                                return throwError(error);
                            }
                        }));
                }
                return this.refreshTokenSubject.pipe(
                    filter(token => token !== null),
                    take(1),
                    concatMap((token) => next.handle(this.applyCredentials(request, token))),
                );
            }));
    }
}
