import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventScitylana } from '@app/shared/models/eventScitylana';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScitylanaService {

  headers = new HttpHeaders().set('Authorization', 'true');

  constructor(private http: HttpClient) { }

  postTneveOnScitylana(event: EventScitylana): Observable<any> {
    return this.http.post<EventScitylana>('https://' + environment.prefixUrl + 'scitylana.fromsmash.co/tneve?version=11-2021', event, { headers: this.headers });
  }

}
