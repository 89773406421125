import { buildGenericTags } from "@app/shared/helpers/tags";

export const Roles = {
    teamRoles: {
        marketer: {
            name: '${team}$Marketer',
            description: 'Marketer',
            policies: [
                'baseMember',
                'domainReadAccess',
                'domainCustomizationFullAccess',
                'promotionFullAccess',
                'customizationFullAccess',
                'imageFullAccess',
                'linkReadAccess',

            ],
            tags: { ...buildGenericTags() },
        },
        collaborator: {
            name: '${team}$Collaborator',
            description: 'Collaborator',
            policies: [
                'baseMember',
                'domainReadAccess',
                'promotionReadAccess',
                'imageFullAccess',
                'domainCustomizationReadAccess', // probably useless and wrong
                'customizationReadAccess',
                'linkReadAccess',
            ],
            tags: { ...buildGenericTags() },
        },
        reporter: {
            name: '${team}$Reporter',
            description: 'Reporter',
            policies: [
                'baseMember',
                'domainReadAccess',
                'promotionReadAccess',
                'imageFullAccess',
                'domainCustomizationReadAccess', // useless
                'customizationReadAccess',
                'linkReadAccess',
                'transferStatsTeamAccess',
            ],
            tags: { ...buildGenericTags() },
        },
        administrator: {
            name: '${team}$Administrator',
            description: 'Administrator',
            policies: [
                'baseMember',
                'domainReadAccess',
                'teamFullAccess',
                'teamAdministratorMemberAccess',
                'teamAdministratorGroupAccess',
                'domainCustomizationFullAccess',
                'transferStatsTeamAccess',
                'domainWriteAccess',
                'imageFullAccess',
                'promotionFullAccess',
                'customizationFullAccess',
            ],
            tags: { ...buildGenericTags() },
        }
    }
};
