import { Action, createReducer, on } from '@ngrx/store';
import { AccountActions, ScriptActions } from '../actions';

export const scriptFeatureKey = 'script';

export interface State {
  scripts: string[];
  errorMessage: string;

}

export const initialState: State = {
  scripts: [],
  errorMessage: '',
};

const scriptReducer = createReducer(
  initialState,
  on(ScriptActions.loadScripts, (state, { scripts }) => ({
    ...state,
    scripts,
  })),
  on(ScriptActions.loadScriptsSuccess, (state, { scripts }) => ({
    ...state,
    scripts,
  })),
  on(ScriptActions.addScriptSuccess, (state, { script }) => ({
    ...state,
    scripts: [...state.scripts, script],
  })),
  on(ScriptActions.loadScriptsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),
  on(AccountActions.signOutSuccess, (state) => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return scriptReducer(state, action);
}

export const getLoadedScripts = (state: State) => state.scripts;

