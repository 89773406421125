import { Constant } from 'src/constant';
import { Domain } from '../models/domain';
import { AvailabilityDurationSettings, DeliverySettings, DomainSettings, TransferSettings } from '../models/domainSettings';
import mergeWith from 'lodash/mergeWith';
import { GetRecordOutput } from '@smash-sdk/domain/01-2024';

/* Translate real delivery settings to forms values */
export function translateDeliverySettings(settings: DeliverySettings): any {
    const simplifiedDeliverySettings = {
        emailDeliveryEnabled: isEmailDeliveryEnabled(settings),
        linkDeliveryEnabled: isLinkDeliveryEnabled(settings),
    };
    return simplifiedDeliverySettings;
}

/* Build real transfer mode settings from forms values */
export function buildTransferModeSettings(simplifiedTransferModeSettings: {
    transferModeTransferEnabled: boolean;
    transferModeDropboxEnabled: boolean;
    transferModeDefault: GetRecordOutput['record']['options']['transferMode']['default'];
}): DomainSettings['transferMode'] {
    const options = [];

    if (simplifiedTransferModeSettings.transferModeTransferEnabled) {
        options.push(Constant.transferMode.transfer);
    }

    if (simplifiedTransferModeSettings.transferModeDropboxEnabled) {
        options.push(Constant.transferMode.dropbox);
    }

    const transferModeSettings: DomainSettings['transferMode'] = {
        active: true,
        default: simplifiedTransferModeSettings.transferModeDefault,
        options: options as DomainSettings['transferMode']['options'],
    };

    return transferModeSettings;
}

/* Build real delivery settings from delivery forms values */
export function buildDeliverySettings(simplifiedDeliverySettings: any): any {
    const deliverySettings: any = {
        active: true,
        options: [],
    };

    if (simplifiedDeliverySettings.emailDeliveryEnabled) {
        deliverySettings.options.push(Constant.deliveryTypes.Email);
    }

    if (simplifiedDeliverySettings.linkDeliveryEnabled) {
        deliverySettings.options.push(Constant.deliveryTypes.Link);
    }

    // FIX ME Should be configurable through the interface ?
    deliverySettings.default = deliverySettings.options[0];

    return deliverySettings;
}

function isLinkDeliveryEnabled(settings): boolean {
    return !!settings?.options?.find(option => option === Constant.deliveryTypes.Link);
}

function isEmailDeliveryEnabled(settings): boolean {
    return !!settings?.options?.find(option => option === Constant.deliveryTypes.Email);
}

function isTransferModeTransferEnabled(settings): boolean {
    return !!settings?.options?.find(option => option === Constant.transferMode.transfer);
}

function isTransferModeDropboxEnabled(settings): boolean {
    return !!settings?.options?.find(option => option === Constant.transferMode.dropbox);
}

export function translateMainDomainSettings(settings: DomainSettings) {
    return {
        redirectionMode: settings.redirection.value,
        transferModeTransferEnabled: isTransferModeTransferEnabled(settings.transferMode),
        transferModeDropboxEnabled: isTransferModeDropboxEnabled(settings.transferMode),
        defaultTransferMode: settings.transferMode.default,
    };
}
/* Translate real transfer settings to forms values */
export function translateTransferSettings(settings: DomainSettings): any {
    const simplifiedDeliverySettings = {
        updateCustomizationActive: settings.transfer.customization.active,
        updatePromotionActive: settings.transfer.promotion.active,
        updateAvailabilityEndDateActive: settings.transfer.availabilityDuration.active,
        addPasswordAllowed: settings.transfer.password.active,
        updatePreviewActive: settings.transfer.preview.active,
        downloadNotificationActive: settings.transfer.notification.download.active,
        noDownloadNotificationActive: settings.transfer.notification.noDownload.active,
        senderNotificationActive: settings.transfer.notification.sender.active,
        linkNotificationActive: settings.transfer.notification.link.active,
        updateAccessTrackingActive: settings.transfer.accessTracking.active,
        emailDeliveryEnabled: isEmailDeliveryEnabled(settings.transfer.delivery),
        linkDeliveryEnabled: isLinkDeliveryEnabled(settings.transfer.delivery),
    };
    return simplifiedDeliverySettings;
}

/* Build real transfer settings from forms values */
export function updateTransferSettingInterface(currentTransferSettings: TransferSettings, simplifiedNewTransferSettings: any): any {
    const copyCurrentTransferSettings = JSON.parse(JSON.stringify(currentTransferSettings)); // Necesssary because of Store.freeze, and Object.Assign doesn't perform deepClone

    const {
        updateCustomizationActive,
        updatePromotionActive,
        updateAvailabilityEndDateActive,
        addPasswordAllowed,
        updatePreviewActive,
        downloadNotificationActive,
        noDownloadNotificationActive,
        senderNotificationActive,
        linkNotificationActive,
        updateAccessTrackingActive,
    } = simplifiedNewTransferSettings;

    copyCurrentTransferSettings.customization.active = typeof (updateCustomizationActive) === 'boolean' ? updateCustomizationActive : copyCurrentTransferSettings.customization.active;
    copyCurrentTransferSettings.promotion.active = typeof (updatePromotionActive) === 'boolean' ? updatePromotionActive : copyCurrentTransferSettings.promotion.active;
    copyCurrentTransferSettings.password.active = typeof (addPasswordAllowed) === 'boolean' ? addPasswordAllowed : copyCurrentTransferSettings.password.active;
    copyCurrentTransferSettings.preview.active = typeof (updatePreviewActive) === 'boolean' ? updatePreviewActive : copyCurrentTransferSettings.customization.active;
    copyCurrentTransferSettings.notification.download.active = downloadNotificationActive;
    copyCurrentTransferSettings.notification.noDownload.active = noDownloadNotificationActive;
    copyCurrentTransferSettings.notification.sender.active = senderNotificationActive;
    copyCurrentTransferSettings.notification.link.active = linkNotificationActive;
    copyCurrentTransferSettings.accessTracking.active = typeof (updateAccessTrackingActive) === 'boolean' ? updateAccessTrackingActive : copyCurrentTransferSettings.accessTracking.active;
    copyCurrentTransferSettings.delivery = buildDeliverySettings(simplifiedNewTransferSettings);
    copyCurrentTransferSettings.availabilityDuration.active = typeof (updateAvailabilityEndDateActive) === 'boolean' ? updateAvailabilityEndDateActive : copyCurrentTransferSettings.availabilityDuration.active;

    return copyCurrentTransferSettings;
}

/* Build real transfer availability settings from forms values */
export function buildTransferAvailabilitySettings(currentTransferAvailabilitySettings: AvailabilityDurationSettings, newTransferAvailabilitySettings: any): any {
    const copyCurrentTransferAvailabilitySettings = JSON.parse(JSON.stringify(currentTransferAvailabilitySettings)); // Necesssary because of Store.freeze, and Object.Assign doesn't perform deepClone

    copyCurrentTransferAvailabilitySettings.default = parseInt(newTransferAvailabilitySettings.defaultAvailabilityDuration);

    return copyCurrentTransferAvailabilitySettings;
}

export function translateTransferAvailabilitySettingsMinMax(settings: AvailabilityDurationSettings): any {
    const availabilitySettingsMinMax = {
        minAvailabilityDuration: settings.min,
        maxAvailabilityDuration: settings.max,
    };
    return availabilitySettingsMinMax;
}

export function translateTransferAvailabilitySettings(settings: AvailabilityDurationSettings): any {
    const simplifiedTransferAvailabilityDurationSettings = {
        defaultAvailabilityDuration: settings.default,
    };
    return simplifiedTransferAvailabilityDurationSettings;
}

export function translateDefaultTransferSettings(settings: DomainSettings): any {
    return {
        senderNotificationEnabled: settings.transfer.notification.sender.enabled,
        linkNotificationEnabled: settings.transfer.notification.link.enabled,
        downloadNotificationEnabled: settings.transfer.notification.download.enabled,
        noDownloadNotificationEnabled: settings.transfer.notification.noDownload.enabled,
        defaultPreviewActive: settings.transfer.preview.default === Constant.previewStatus.Full,
        defaultAccessTrackingActive: settings.transfer.accessTracking.default === Constant.accessTracking.Email,
        isPasswordRequired: settings.transfer.password.required,
        defaultAvailabilityDuration: settings.transfer.availabilityDuration.default,
        defaultTransferMode: settings.transferMode.default,
    };
}

export function updateDefaultTransferSettings(settings: DomainSettings, simplifiedNewTransferSettings: any): any {
    const copyCurrentTransferSettings = JSON.parse(JSON.stringify(settings)); // Necesssary because of Store.freeze, and Object.Assign doesn't perform deepClone

    const {
        senderNotificationEnabled,
        linkNotificationEnabled,
        downloadNotificationEnabled,
        noDownloadNotificationEnabled,
        defaultPreviewActive,
        defaultAccessTrackingActive,
        isPasswordRequired,
        defaultAvailabilityDuration,
    } = simplifiedNewTransferSettings;

    copyCurrentTransferSettings.notification.sender.enabled = senderNotificationEnabled;
    copyCurrentTransferSettings.notification.link.enabled = linkNotificationEnabled;
    copyCurrentTransferSettings.notification.download.enabled = downloadNotificationEnabled;
    copyCurrentTransferSettings.notification.noDownload.enabled = noDownloadNotificationEnabled;
    copyCurrentTransferSettings.preview.default = defaultPreviewActive ? Constant.previewStatus.Full : Constant.previewStatus.None;
    copyCurrentTransferSettings.password.required = typeof (isPasswordRequired) === 'boolean' ? isPasswordRequired : copyCurrentTransferSettings.password.required;
    copyCurrentTransferSettings.accessTracking.default = defaultAccessTrackingActive ? Constant.accessTracking.Email : Constant.accessTracking.None;
    copyCurrentTransferSettings.availabilityDuration.default = parseInt(defaultAvailabilityDuration);

    return copyCurrentTransferSettings;
}

/* Customizer function for lodash to replace arrays and not concatenate when using mergeWith method */
function mergeCopyArrays(objValue, srcValue) {
    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
        return srcValue;
    }
    return undefined;
}

function buildDomainPremiumSettings(domain: Domain) {
    /* mergeWith is used here to merge default domain options with existing domain options */
    return mergeWith({}, Constant.defaultPremiumDomainSettings, domain.options, mergeCopyArrays);
}

function buildManagedDomainSettings(domain: Domain) {
    /* mergeWith is used here to merge default domain options with existing domain options */
    return mergeWith({}, Constant.defaultManagedDomainSettings, domain.options, mergeCopyArrays);
}

export function buildDomainSettings(domain: Domain): DomainSettings {
    return !!domain?.team ? buildDomainPremiumSettings(domain) : buildManagedDomainSettings(domain);
}
