import { createAction, props } from '@ngrx/store';
import { Subscription } from '@app/shared/models/subscription';
import { GetStripePaymentMethodOutput } from '@smash-sdk/billing/01-2024/types/GetStripePaymentMethod/GetStripePaymentMethod';

export const loadInvoices = createAction(
  '[Billing] Load Invoices'
);

export const loadInvoicesSuccess = createAction(
  '[Billing] Load Invoices Success',
  props<{ invoices: any[] }>()

);

export const loadInvoicesFailure = createAction(
  '[Billing] Load Invoices Failure',
  props<{ error: any }>()
);

export const loadSubscriptionAndPlan = createAction(
  '[Billing] Load Subscription and Plan'
);

export const loadSubscriptionAndPlanSuccess = createAction(
  '[Billing] Load Subscription and Plan Success',
  props<{ subscription: any, plan: any }>()

);

export const loadSubscriptionAndPlanFailure = createAction(
  '[Billing] Load Subscription and Plan Failure',
  props<{ error: any }>()
);

export const unsubscribe = createAction(
  '[Billing] Unsubscribe',
  props<{ reason?: any }>()
);

export const unsubscribeSuccess = createAction(
  '[Billing] Unsubscribe Success',
  props<{ subscription: Subscription }>()
);

export const unsubscribeFailure = createAction(
  '[Billing] Unsubscribe Failure',
  props<{ error: any }>()
);

export const UpdateBillingInformations = createAction(
  '[Billing] Update Billing Informations',
  props<{ data: any }>()
);

export const UpdateBillingInformationsSuccess = createAction(
  '[Billing] Update Billing Informations Success',
  props<{ billingInformations: any }>()
);

export const UpdateBillingInformationsFailure = createAction(
  '[Billing] Update Billing Informations Failure',
  props<{ error: any }>()
);

export const computePlan = createAction(
  '[Billing] Compute Plan',
  props<{ country: any }>()
);

export const computePlanSuccess = createAction(
  '[Billing] Compute Plan Success',
  props<{ computedPlan: any }>()
);

export const computePlanFailure = createAction(
  '[Billing] Compute Plan Failure',
  props<{ error: any }>()
);

export const updateStripePaymentMethod = createAction(
  '[Billing] Update Stripe Payment Method',
  props<{ cardToken: any }>()
);

export const updateStripePaymentMethodSuccess = createAction(
  '[Billing] Update Stripe Payment Method Success',
);

export const updateStripePaymentMethodError = createAction(
  '[Billing] Update Stripe Payment Method Failure',
  props<{ error: any }>()
);

export const retrieveStripePaymentMethod = createAction(
  '[Billing] Retrieve Stripe Payment Method',
);

export const retrieveStripePaymentMethodSuccess = createAction(
  '[Billing] Retrieve Stripe Payment Method Success',
  props<{ paymentMethod: GetStripePaymentMethodOutput['paymentMethod'] }>()
);

export const retrieveStripePaymentMethodError = createAction(
  '[Billing] Retrieve Stripe Payment Method Failure',
  props<{ error: any }>()
);

export const resetStripePaymentMethodError = createAction(
  '[Billing] Reset Stripe Payment Method Error',
);
