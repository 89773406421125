import { createAction, props } from '@ngrx/store';
import { Promotion } from '@app/shared/models/promotion';

// Load internal promotions
export const loadPromotionsInternal = createAction(
  '[Send] Load Internal Promotions'
)

export const loadPromotionsSuccess = createAction(
  '[Send] Load Promotions success',
  props<{ promotions: Promotion[], language?: string }>()
);

export const loadPromotionsFailure = createAction(
  '[Send] Load Promotions Failure',
  props<{ error: string }>()
);

export const loadPromotion = createAction(
  '[Send] Load Promotion',
  props<{ id: string }>()
);

export const loadPromotionSuccess = createAction(
  '[Send] Load Promotion Success',
  props<{ promotion: Promotion }>()
);

export const loadPromotionFailure = createAction(
  '[Send] Load Promotion Failure',
  props<{ error: string }>()
);
