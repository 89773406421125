import { Customization } from '@app/shared/models/customization';
import { Domain } from '@app/shared/models/domain';
import { Provider } from '@app/shared/models/provider';
import { Team } from '@app/shared/models/team';
import { createAction, props } from '@ngrx/store';
import { GetDomainCustomizationOutput, GetRecordOutput } from '@smash-sdk/domain/01-2024';
import { CreateImageOutput } from '@smash-sdk/image/10-2019/types/CreateImage/CreateImage';

export const loadDomain = createAction(
  '[Domain] Load Domain',
  props<{ id: string }>()
);
export const loadDomainSuccess = createAction(
  '[Domain] Load Domain Success',
  props<{ domain: GetRecordOutput['record'], providers: Provider[], mainDomain?: string }>()
);
export const loadDomainFailure = createAction(
  '[Domain] Load Domain Failure',
  props<{ error: any }>()
);
export const updateDomain = createAction(
  '[Domain] Update Domain',
  props<{ team: Team }>()
);
export const updateDomainSuccess = createAction(
  '[Domain] Update Domain Success'
);
export const updateDomainFailure = createAction(
  '[Domain] Update Domain Url Failure',
  props<{ error: any }>()
);

export const createDomain = createAction(
  '[Domain] Create Domain',
);

export const createDomainSuccess = createAction(
  '[Domain] Create Domain Success',
  props<{ domain: Domain }>()
);

export const createDomainFailure = createAction(
  '[Domain] Create Domain Url Failure',
  props<{ error: number }>()
);

export const updateDomainToCreate = createAction(
  '[Domain] Update Domain To Create',
  props<{ domain: string }>()
);

export const selectDomain = createAction(
  '[Domain] Select Domain',
  props<{ domain: Domain }>()
);

export const loadDomainCustomization = createAction(
  '[Domain] Load Domain Customization'
);

export const loadDomainCustomizationSuccess = createAction(
  '[Domain] Load Domain Customization Success',
  props<{ customization: GetDomainCustomizationOutput['customization'] }>()
);

export const loadDomainCustomizationFailure = createAction(
  '[Domain] Load Domain Customization Failure',
  props<{ error: any }>()
);

export const updateDomainCustomization = createAction(
  '[Domain] Update Domain Customization'
);

export const updateDomainCustomizationSuccess = createAction(
  '[Domain] Update Domain Customization Success',
  props<{ customization: Customization }>()
);

export const updateDomainCustomizationFailure = createAction(
  '[Domain] Update Domain Customization Failure',
  props<{ error: any }>()
);

export const addNewLogoToDomainCustomization = createAction(
  '[Domain] Add New Logo To Domain Customization',
  props<{ image: any }>()
);

export const addNewLogoToDomainCustomizationSuccess = createAction(
  '[Domain] Add New Logo To Domain Customization Success',
  props<{ logo: CreateImageOutput['image'] }>()
);

export const addNewLogoToDomainCustomizationFailure = createAction(
  '[Domain] Add New Logo To Domain Customization Failure',
  props<{ error: any }>()
);

export const addNewBackgroundImageToDomainCustomization = createAction(
  '[Domain] Add New Background Image To Domain Customization',
  props<{ image: any }>()
);

export const addNewBackgroundImageToDomainCustomizationSuccess = createAction(
  '[Domain] Add New Background Image To Domain Customization Success',
  props<{ background: CreateImageOutput['image'] }>()
);

export const addNewBackgroundImageToDomainCustomizationFailure = createAction(
  '[Domain] Add New Background Image To Domain Customization Failure',
  props<{ error: any }>()
);

export const addNewBackgroundVideoToDomainCustomization = createAction(
  '[Domain] Add New Background Video To Domain Customization',
  props<{ url: any }>()
);

export const addNewBackgroundVideoToDomainCustomizationSuccess = createAction(
  '[Domain] Add New Background Video To Domain Customization Success',
  props<{ video: any }>()
);

export const addNewBackgroundVideoToDomainCustomizationFailure = createAction(
  '[Domain] Add New Background Video To Domain Customization Failure',
  props<{ error: any }>()
);
