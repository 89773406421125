import { Observable, pipe, retry, timer } from "rxjs";
import { getExponentialBackoffDelay } from "../helpers/helpers";

const DEFAULT_DELAY_TIME = 1000;
const DEFAULT_MAX_DELAY_TIME = 5000;

export interface ErrorRetryConfiguration {
    error: any;
    delay?: number;
    exponentialBackoff?: boolean;
    maxRetry: number;
}

export function genericRetry<T>({
    retryConfig = []
}: {
    retryConfig?: ErrorRetryConfiguration[]
}): (source: Observable<T>) => Observable<T> {
    return pipe(
        retry({
            delay: (error, count) => {
                if (retryConfig.length > 0) {
                    const errorRetryConfig = retryConfig.find(config => error instanceof config.error);
                    if (errorRetryConfig) {
                        const { delay = DEFAULT_DELAY_TIME, maxRetry } = errorRetryConfig;
                        if (count >= maxRetry) {
                            throw error;
                        }
                        return timer(errorRetryConfig.exponentialBackoff ? getExponentialBackoffDelay(count, delay, DEFAULT_MAX_DELAY_TIME) : delay);
                    }
                }
                throw error;
            },
        })
    );
}
