import { createAction, props } from '@ngrx/store';
import { Customization } from '@app/shared/models/customization';
import { Background } from '@app/shared/models/background';
import { Logo } from '@app/shared/models/logo';
import { Team } from '@app/shared/models/team';
import { GetDomainCustomizationOutput } from '@smash-sdk/domain/01-2024';

export const loadTeamCustomization = createAction(
  '[Team] Load Team Customization',
);

export const loadTeamCustomizationSuccess = createAction(
  '[Team] Load Team Customization Success',
  props<{ customization: GetDomainCustomizationOutput['customization'] }>()
);

export const loadTeamCustomizationFailure = createAction(
  '[Team] Load Team Customization Failure',
  props<{ error: any }>()
);

export const loadTeamBackgrounds = createAction(
  '[Team] Load Team Backgrounds',
);

export const loadTeamBackgroundsSuccess = createAction(
  '[Team] Load Team Backgrounds Success',
  props<{ backgrounds: Background[] }>()
);

export const loadTeamBackgroundsFailure = createAction(
  '[Team] Load Team Backgrounds Failure',
  props<{ error: any }>()
);

export const loadTeamLogos = createAction(
  '[Team] Load Team Logos',
);

export const loadTeamLogosSuccess = createAction(
  '[Team] Load Team Logos Success',
  props<{ logos: Logo[] }>()
);

export const loadTeamLogosFailure = createAction(
  '[Team] Load Team Logos Failure',
  props<{ error: any }>()
);

export const updateTeamSelected = createAction(
  '[Team] Update Team Selected',
  props<{ team: any }>()
);

export const createTeam = createAction(
  '[Team] Create Team',
  props<{ team: any }>()
);
export const createTeamSuccess = createAction(
  '[Team] Create Team Success',
  props<{ team: Team }>()
);
export const createTeamFailure = createAction(
  '[Team] Create Team Url Failure',
  props<{ error: number }>()
);

export const updateTeam = createAction(
  '[Team] Update Team',
  props<{ team: Team }>()
);
export const updateTeamSuccess = createAction(
  '[Team] Update Team Success',
  props<{ team: Team }>()
);
export const updateTeamFailure = createAction(
  '[Team] Update Team Url Failure',
  props<{ error: number }>()
);

export const resetTeamCreateForm = createAction(
  '[Team] Reset Team Create Form',
);

export const resetTeamEditForm = createAction(
  '[Team] Reset Team Edit Form',
);

export const findTeam = createAction(
  '[Team] find Team',
  props<{ id: string  }>()
);

export const findTeamSuccess = createAction(
  '[Team] find Team Success',
  props<{ team: Team }>()
);


export const findTeamFailure = createAction(
  '[Team] find Team Failure',
  props<{ error: any }>()
);

export const findTeamWithUsers = createAction(
  '[Team] find Team With Users',
  props<{ id: string  }>()
);

export const findTeamWithUsersSuccess = createAction(
  '[Team] find Team With Users Success',
  props<{ team: Team }>()
);


export const findTeamWithUsersFailure = createAction(
  '[Team] find Team With Users Failure',
  props<{ error: any }>()
);


export const redirectToDomainCreation = createAction(
  '[Team] redirect to create domain'
)