import { FileSystemMetadata } from '@app/shared/components/smash-file-drop/interfaces';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UploaderStatus } from '@smash-sdk/uploader';
import { UploadCompleteOutput, UploadResumedOutput } from '@smash-sdk/uploader/dist/es/interface/Output';
import { RawFileError } from '../../interfaces/file';
import * as UploadActions from '../actions/upload.actions';

export const uploadFeatureKey = 'uploadMobile';

export interface UploadState {
  startDate: number;
  endDate: number;
  status: UploaderStatus;
  progress: number;
  speed: number;
  uploadedBytes: number;
  remainingSize: number;
  error: any; // FIXME
}

const uploadInitialState: UploadState = {
  startDate: null,
  endDate: null,
  status: UploaderStatus.Pending,
  progress: 0,
  speed: 0,
  uploadedBytes: 0,
  remainingSize: 0,
  error: null,
};

export interface TransferState {
  transfer: any | UploadCompleteOutput['transfer'] | UploadResumedOutput['transfer'];

}

const transferInitialState: TransferState = {
  transfer: null,
};

export interface RawFilesState extends EntityState<FileSystemMetadata> { error: RawFileError; }
export interface FilesState {
  rawFilesState: RawFilesState;
  error: any;
}

const adapterRawFiles = createEntityAdapter<FileSystemMetadata>({ selectId: selectRawFileName });

export function selectRawFileName(file: Partial<FileSystemMetadata>): string {
  return file.relativePath;
}

export function sortFileBySize(a: FileSystemMetadata, b: FileSystemMetadata): number {
  const compare = b.size - a.size;
  if (compare > 0) {
    return 1;
  } else if (compare < 0) {
    return -1;
  } else { return 0; }
}

const rawFilesInititalState: RawFilesState = adapterRawFiles.getInitialState({ error: null });

const filesInitialState: FilesState = {
  rawFilesState: rawFilesInititalState,
  error: null,
};

export interface State {
  uploadState: UploadState;
  transferState: TransferState;
  filesState: FilesState;
}

export const initialState: State = {
  uploadState: uploadInitialState,
  transferState: transferInitialState,
  filesState: filesInitialState,
};


export const reducer = createReducer(
  initialState,

  on(UploadActions.Upload, (state) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
      error: initialState.uploadState.error,
    },
  })),
  on(UploadActions.UploadSuccess, (state, { transfer }) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
    },
    transferState: {
      ...state.transferState,
      transfer,
    },
  })),
  on(UploadActions.UploadFailure, (state, { error }) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
      error,
    },
  })),

  on(UploadActions.UploadQueued, (state, { queue, queuedUntil }) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
    },
    transferState: {
      ...state.transferState,
      transfer: {
        ...state.transferState.transfer,
        queue,
        queuedUntil,
      }
    },
  })),

  on(UploadActions.SetProgress, (state, { progress }) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
      progress,
    }
  })),
  on(UploadActions.SetUploadSpeed, (state, { speed }) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
      speed,
    }
  })),
  on(UploadActions.SetUploadedBytes, (state, { uploadedBytes }) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
      uploadedBytes,
    }
  })),
  on(UploadActions.SetRemainingSize, (state, { remainingSize }) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
      remainingSize,
    }
  })),
  on(UploadActions.SetUploadStatus, (state, { status }) => ({
    ...state,
    uploadState: {
      ...state.uploadState,
      status,
    }
  })),

  on(UploadActions.AddRawFilesSuccess, (state, { files }) => ({
    ...state,
    filesState: {
      ...state.filesState,
      rawFilesState: adapterRawFiles.addMany(files, state.filesState.rawFilesState)
    },
  })),
  on(UploadActions.AddRawFilesFailure, (state, { error }) => ({
    ...state,
    filesState: {
      ...state.filesState,
      rawFilesState: {
        ...state.filesState.rawFilesState,
        error
      }
    },
  })),
  on(UploadActions.ResetRawFilesError, (state) => ({
    ...state,
    filesState: {
      ...state.filesState,
      rawFilesState: {
        ...state.filesState.rawFilesState,
        error: initialState.filesState.rawFilesState.error
      }
    },
  })),
  on(UploadActions.RemoveRawFile, (state, { file }) => {
    return {
      ...state,
      filesState: {
        ...state.filesState,
        rawFilesState: adapterRawFiles.removeOne(selectRawFileName(file), state.filesState.rawFilesState),
      },
    };
  }),
  on(UploadActions.RemoveRawFiles, (state, { files }) => {
    return {
      ...state,
      filesState: {
        ...state.filesState,
        rawFilesState: adapterRawFiles.removeMany(files.map(file => selectRawFileName(file)), state.filesState.rawFilesState),
      },
    };
  }),

  on(UploadActions.CancelUpload, (state) => ({
    ...state,
    transferState: initialState.transferState,
    uploadState: initialState.uploadState,
  })),

  on(UploadActions.ResetUpload, (state) => ({
    ...state,
    transferState: initialState.transferState,
    uploadState: initialState.uploadState,
    filesState: initialState.filesState,
  })),
  on(UploadActions.ResetUploadSuccess, (state) => ({
    ...state,
    uploadState: { ...initialState.uploadState, status: UploaderStatus.Canceled },
    filesState: initialState.filesState,
    transferState: initialState.transferState,
  })),

);

export const getTransferStartDate = (state: State) => state.uploadState.startDate;
export const getTransferQueue = (state: State) => state.transferState.transfer?.queue;
export const getTransfer = (state: State) => state.transferState.transfer;
export const getUploadStatus = (state: State) => state.uploadState.status;
export const getRawFilesError = (state: State) => state.filesState.rawFilesState.error;
export const getUploadProgress = (state: State) => state.uploadState?.progress;
export const getUploadedBytes = (state: State) => state.uploadState?.uploadedBytes;
export const getRemainingSize = (state: State) => state.uploadState?.remainingSize;
export const getUploadSpeed = (state: State) => state.uploadState?.speed;
export const getUploadError = (state: State) => state.uploadState?.error;

export const {
  selectAll: selectAllRawFiles,
  selectEntities: selectEntitiesRawFiles,
  selectIds: selectIdsRawFiles,
  selectTotal: selectTotalRawFiles,
} = adapterRawFiles.getSelectors();

