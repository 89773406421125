import { createAction, props } from '@ngrx/store';
import { GetRecordOutput } from '@smash-sdk/domain/01-2024';

export const resetStep = createAction(
  '[Workflow] Reset Step',
);

export const updateStep = createAction(
  '[Workflow] Update Step',
  props<{ step: string }>()
);

export const updateMode = createAction(
  '[Workflow] Update Mode',
  props<{ mode: GetRecordOutput['record']['options']['transferMode']['default'] }>()
);
