import { Constant } from 'src/constant';

export function getLanguageWithFallback(language) {
    if (isLanguageSupported(language)) {
        return language;
    }
    return Constant.languageFallback;
}

function isLanguageSupported(language): boolean {
    return !!Constant.supportedLanguages.find(item => language === item.id);
}