import { createAction, props } from '@ngrx/store';

export const verifyCaptcha = createAction(
  '[Captcha] Verify Captcha',
  props<{ action: string }>()
);

export const verifyCaptchaSuccess = createAction(
  '[Captcha] Verify Captcha Success',
  props<{ challenge: string }>()

);

export const verifyCaptchaFailure = createAction(
  '[Captcha] Verify Captcha Failure',
  props<{ error: string }>()
);

export const resetVerifyCaptcha = createAction(
  '[Captcha] Verify Captcha Reset',
)


