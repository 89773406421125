import { Background } from '@app/shared/models/background';
import { Customization } from '@app/shared/models/customization';
import { Logo } from '@app/shared/models/logo';
import { Promotion } from '@app/shared/models/promotion';
import { createReducer, on } from '@ngrx/store';
import { Constant } from 'src/constant';
import { UploadActions } from '../actions';
import { DeliveryType, TransferCommonParameters, TransferEmailParameters, TransferLinkParameters } from '../../interfaces/transfer';
import { PrepareActions } from '../actions';

export const prepareFeatureKey = 'prepareMobile';

export interface State {
  uploadFormMode: DeliveryType;
  prepareStep: 'emailSelection' | 'emailVerificationSection' | 'deliveryTypeSelection' | 'parametersSelection';

  transferParameters: {
    linkParameters?: TransferLinkParameters;
    emailParameters?: TransferEmailParameters;
    commonParameters?: TransferCommonParameters;
  };

  configDrawerExpanded: boolean;
  configFormStatus: string;

  customization: Customization;
  customizationLoading: boolean;
  customizationLoaded: boolean;
  customizationError: any;

  customizationBackgrounds: Background[];
  customizationLogos: Logo[];
  customizationEdited: Partial<Customization>; // Used in edit customization modal

  externalPromotions: Promotion[];
  externalPromotionsLoading: boolean;
  externalPromotionsLoaded: boolean;
  externalPromotionsError: any;
  externalPromotionSelected: Promotion;

  externalPromotionLoading: boolean;
  externalPromotionLoaded: boolean;
  externalPromotionError: any;

  modalSizePremiumDisplayed: boolean;

  verifyEmailResponse: any;
  verifyEmailLoading: boolean;
  verifyEmailLoaded: boolean;
  verifyEmailError: any;

  validateEmailVerificationCodeResponse: any;
  validateEmailVerificationCodeLoading: boolean;
  validateEmailVerificationCodeLoaded: boolean;
  validateEmailVerificationCodeError: any;

  sendEmailVerificationLoading: boolean;
  sendEmailVerificationLoaded: boolean;
  sendEmailVerificationError: any;

  error: string;
}

export const initialState: State = {
  uploadFormMode: 'Email',
  prepareStep: 'emailSelection',

  transferParameters: {
    linkParameters: {
      title: null,
      delivery: null,
      customUrl: null,
      accessTracking: null,
    },
    emailParameters: {
      title: null,
      description: null,
      delivery: null,
    },
    commonParameters: {
      size: 0,
      domain: null,
      filesNumber: 0,
      availabilityDuration: 0,
      preview: null,
      password: null,
      notification: null,
      promotion: null,
      customization: null,
    }
  },

  configDrawerExpanded: false,
  configFormStatus: null,

  customization: null,
  customizationLoading: false,
  customizationLoaded: false,
  customizationError: null,

  customizationBackgrounds: [],
  customizationLogos: [],
  customizationEdited: null,

  externalPromotions: [],
  externalPromotionsLoading: false,
  externalPromotionsLoaded: false,
  externalPromotionsError: null,
  externalPromotionSelected: null,

  externalPromotionLoading: false,
  externalPromotionLoaded: false,
  externalPromotionError: null,

  modalSizePremiumDisplayed: false,

  verifyEmailResponse: null,
  verifyEmailLoading: false,
  verifyEmailLoaded: false,
  verifyEmailError: null,

  validateEmailVerificationCodeResponse: null,
  validateEmailVerificationCodeLoading: false,
  validateEmailVerificationCodeLoaded: false,
  validateEmailVerificationCodeError: null,

  sendEmailVerificationLoading: false,
  sendEmailVerificationLoaded: false,
  sendEmailVerificationError: null,

  error: null,
};

export const reducer = createReducer(
  initialState,
  on(PrepareActions.loadCustomization, (state) => ({
    ...state,
    customizationLoading: true,
    customizationLoaded: initialState.customizationLoaded,
    customization: initialState.customization,
    customizationError: initialState.customizationError,
  })),
  on(PrepareActions.loadCustomizationSuccess, (state, { customization }) => ({
    ...state,
    customizationLoading: false,
    customizationLoaded: true,
    customization,
    customizationEdited: customization,
  })),
  on(PrepareActions.loadCustomizationFailure, (state, { error }) => ({
    ...state,
    customizationLoading: false,
    customizationError: error,
  })),

  on(PrepareActions.loadBackgroundsSuccess, (state, { backgrounds }) => ({ ...state, customizationBackgrounds: backgrounds })),
  on(PrepareActions.loadLogosSuccess, (state, { logos }) => ({ ...state, customizationLogos: logos })),

  on(PrepareActions.addBackgroundImageToAttachSuccess, (state, { background }) => ({
    ...state,
    customizationBackgrounds: [...state.customizationBackgrounds, background],
    customizationEdited: { ...state.customizationEdited, background },
  })),
  on(PrepareActions.addBackgroundVideoToAttachSuccess, (state, { background }) => ({
    ...state,
    customizationBackgrounds: [...state.customizationBackgrounds, background],
    customizationEdited: { ...state.customizationEdited, background },
  })),
  on(PrepareActions.addLogoToAttachSuccess, (state, { logo }) => ({
    ...state,
    customizationLogos: [...state.customizationLogos, logo],
    customizationEdited: { ...state.customizationEdited, logo },
  })),

  on(PrepareActions.ResetCustomisationEdited, (state) => ({
    ...state,
    customizationEdited: state.customization,
  })),

  on(PrepareActions.loadExternalPromotions, (state) => ({
    ...state,
    externalPromotionsLoading: true,
    externalPromotionsLoaded: initialState.externalPromotionsLoaded,
    externalPromotions: initialState.externalPromotions,
    externalPromotionsError: initialState.externalPromotionsError,
    externalPromotionSelected: initialState.externalPromotionSelected,
  })),
  on(PrepareActions.loadExternalPromotionsSuccess, (state, { promotions }) => ({
    ...state,
    externalPromotionsLoading: false,
    externalPromotionsLoaded: true,
    externalPromotions: promotions,
  })),
  on(PrepareActions.loadExternalPromotionsFailure, (state, { error }) => ({
    ...state,
    externalPromotionsError: error,
    externalPromotionsLoading: false,
  })),

  on(PrepareActions.selectRandomExternalPromotionSuccess, (state, { promotion }) => ({
    ...state,
    externalPromotionSelected: promotion,
  })),

  on(PrepareActions.loadPromotion, (state) => ({
    ...state,
    externalPromotionLoading: true,
    externalPromotionSelected: initialState.externalPromotionSelected,
    externalPromotionError: initialState.externalPromotionError,
  })),
  on(PrepareActions.loadPromotionSuccess, (state, { promotion }) => ({
    ...state,
    externalPromotionLoading: false,
    externalPromotionLoaded: true,
    externalPromotionSelected: promotion,
  })),
  on(PrepareActions.loadPromotionFailure, (state, { error }) => ({
    ...state,
    externalPromotionLoading: false,
    externalPromotionError: error,
  })),

  on(PrepareActions.setConfigFormStatus, (state, { configFormStatus }) => ({ ...state, configFormStatus })),
  on(PrepareActions.resetDrawer, (state) => ({
    ...state,
    configDrawerExpanded: false,
    configFormStatus: null
  })),

  on(PrepareActions.toggleConfigDrawerExpanded, (state) => ({
    ...state,
    configDrawerExpanded: !state.configDrawerExpanded,
  })),
  on(PrepareActions.setUploadFormMode, (state, { uploadFormMode }) => ({ ...state, uploadFormMode })),

  on(UploadActions.AddRawFilesSuccess, (state, { files }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      commonParameters: {
        ...state.transferParameters.commonParameters,
        filesNumber: state.transferParameters.commonParameters.filesNumber + files.length,
        size: state.transferParameters.commonParameters.size + files.reduce((acc, curr) => acc + curr.size, 0),
      }
    }
  })),
  on(UploadActions.RemoveRawFile, (state, { file }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      commonParameters: {
        ...state.transferParameters.commonParameters,
        filesNumber: state.transferParameters.commonParameters.filesNumber - 1,
        size: state.transferParameters.commonParameters.size - file.size
      }
    }
  })),
  on(UploadActions.RemoveRawFiles, (state, { files }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      commonParameters: {
        ...state.transferParameters.commonParameters,
        filesNumber: state.transferParameters.commonParameters.filesNumber - files.length,
        size: state.transferParameters.commonParameters.size - files.reduce((acc, curr) => acc + curr.size, 0)
      }
    }
  })),
  on(PrepareActions.SetTransferConfigurationParameters, (state, { parameters }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      commonParameters: {
        ...state.transferParameters.commonParameters,
        password: parameters.password,
        availabilityDuration: parameters.availabilityDuration * Constant.oneDayInSecond,
        preview: parameters.preview,
        notification: parameters.notification,
      }
    },
  })),
  on(PrepareActions.SetTransferPassword, (state, { password }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      commonParameters: {
        ...state.transferParameters.commonParameters,
        password,
      }
    },
  })),
  on(PrepareActions.SetTransferPromotion, (state, { promotion }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      commonParameters: {
        ...state.transferParameters.commonParameters,
        promotion: { id: promotion.id },
      }
    },
  })),
  on(PrepareActions.SetTransferCustomization, (state, { logo, background }) => ({
    ...state,
    customizationEdited: { logo, background },
    transferParameters: {
      ...state.transferParameters,
      commonParameters: {
        ...state.transferParameters.commonParameters,
        customization: { logo: logo?.sourceUrl, background: background?.sourceUrl, },
      }
    },
  })),
  on(PrepareActions.SetTransferSender, (state, { sender }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      emailParameters: {
        ...state.transferParameters.emailParameters,
        delivery: {
          ...state.transferParameters.emailParameters.delivery,
          sender: { email: sender.email, name: sender.name }
        },
      },
      linkParameters: {
        ...state.transferParameters.linkParameters,
        delivery: {
          ...state.transferParameters.linkParameters.delivery,
          sender: { email: sender.email }
        },
      },
    },
  })),
  on(PrepareActions.SetLinkTitle, (state, { title }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      linkParameters: { ...state.transferParameters.linkParameters, title },
    },
  })),
  on(PrepareActions.SetTransferAccessTracking, (state, { accessTracking }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      linkParameters: { ...state.transferParameters.linkParameters, accessTracking },
    },
  })),
  on(PrepareActions.SetLinkCustomUrl, (state, { customUrl }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      linkParameters: { ...state.transferParameters.linkParameters, customUrl },
    },
  })),
  on(PrepareActions.SetEmailTitle, (state, { title }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      emailParameters: { ...state.transferParameters.emailParameters, title },
    },
  })),
  on(PrepareActions.SetEmailDescription, (state, { description }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      emailParameters: { ...state.transferParameters.emailParameters, description },
    },
  })),
  on(PrepareActions.SetEmailReceivers, (state, { receivers }) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      emailParameters: {
        ...state.transferParameters.emailParameters,
        delivery: {
          ...state.transferParameters.emailParameters.delivery,
          receivers
        },
      },
    },
  })),

  on(UploadActions.CancelUploadSuccess, (state) => ({
    ...state,
    transferParameters: {
      ...state.transferParameters,
      linkParameters: {
        ...state.transferParameters.linkParameters,
        customUrl: null,
        title: null,
      }
    },
  })),
  on(UploadActions.ResetUploadSuccess, (state) => ({
    ...state,
    transferParameters: initialState.transferParameters,
  })),

  on(PrepareActions.UpdatePrepareStep, (state, { step }) => ({
    ...state,
    prepareStep: step,
  })),

  on(PrepareActions.DisplayModalSizePremium, (state) => ({
    ...state,
    modalSizePremiumDisplayed: true,
  })),
  on(PrepareActions.HideModalSizePremium, (state) => ({
    ...state,
    modalSizePremiumDisplayed: false,
  })),
  on(PrepareActions.VerifyEmail, (state) => ({
    ...state,
    verifyEmailResponse: initialState.verifyEmailResponse,
    verifyEmailLoading: true,
    verifyEmailLoaded: initialState.verifyEmailLoaded,
    verifyEmailError: initialState.verifyEmailError,
  })),
  on(PrepareActions.VerifyEmailSuccess, (state, { status }) => ({
    ...state,
    verifyEmailResponse: status,
    verifyEmailLoading: false,
    verifyEmailLoaded: true,
  })),
  on(PrepareActions.VerifyEmailFailure, (state, { error }) => ({
    ...state,
    verifyEmailLoading: false,
    verifyEmailError: error,
  })),
  on(PrepareActions.ResetVerifyEmail, (state) => ({
    ...state,
    verifyEmailResponse: initialState.verifyEmailResponse,
    verifyEmailLoading: initialState.verifyEmailLoading,
    verifyEmailLoaded: initialState.verifyEmailLoaded,
    verifyEmailError: initialState.verifyEmailError,
    validateEmailVerificationCodeResponse: initialState.validateEmailVerificationCodeResponse,
    validateEmailVerificationCodeLoading: initialState.validateEmailVerificationCodeLoading,
    validateEmailVerificationCodeLoaded: initialState.validateEmailVerificationCodeLoaded,
    validateEmailVerificationCodeError: initialState.validateEmailVerificationCodeError,
  })),

  on(PrepareActions.ValidateEmailVerificationCode, (state) => ({
    ...state,
    validateEmailVerificationCodeResponse: initialState.validateEmailVerificationCodeResponse,
    validateEmailVerificationCodeLoading: true,
    validateEmailVerificationCodeLoaded: initialState.validateEmailVerificationCodeLoaded,
    validateEmailVerificationCodeError: initialState.validateEmailVerificationCodeError,
  })),
  on(PrepareActions.ValidateEmailVerificationCodeSuccess, (state, { status }) => ({
    ...state,
    validateEmailVerificationCodeResponse: status,
    validateEmailVerificationCodeLoading: false,
    validateEmailVerificationCodeLoaded: true,
  })),
  on(PrepareActions.ValidateEmailVerificationCodeFailure, (state, { error }) => ({
    ...state,
    validateEmailVerificationCodeLoading: false,
    validateEmailVerificationCodeError: error,
  })),

  on(PrepareActions.SendEmailVerificationCode, (state) => ({
    ...state,
    sendEmailVerificationLoading: true,
    sendEmailVerificationLoaded: initialState.sendEmailVerificationLoaded,
    sendEmailVerificationError: initialState.sendEmailVerificationError,
  })),
  on(PrepareActions.SendEmailVerificationCodeSuccess, (state, { status }) => ({
    ...state,
    sendEmailVerificationLoading: false,
    sendEmailVerificationLoaded: true,
  })),
  on(PrepareActions.SendEmailVerificationCodeFailure, (state, { error }) => ({
    ...state,
    sendEmailVerificationLoading: false,
    sendEmailVerificationError: error,
  })),
);

export const getPrepareStep = (state: State) => state.prepareStep;

export const getExternalPromotions = (state: State) => state.externalPromotions;
export const getExternalPromotionsLoaded = (state: State) => state.externalPromotionsLoaded;
export const getExternalPromotionsLoading = (state: State) => state.externalPromotionsLoading;
export const getExternalPromotionSelected = (state: State) => state.externalPromotionSelected;

export const getUploadFormMode = (state: State) => state.uploadFormMode;
export const getErrorMessage = (state: State) => state.error;
export const getConfigDrawerExpanded = (state: State) => state.configDrawerExpanded;
export const getLogos = (state: State) => state.customizationLogos;
export const getConfigFormStatus = (state: State) => state.configFormStatus;

export const getCustomization = (state: State) => state.customization;
export const getCustomizationBackgrounds = (state: State) => state.customizationBackgrounds;
export const getCustomizationLogos = (state: State) => state.customizationLogos;
export const getCustomizationEdited = (state: State) => state.customizationEdited;
export const getCustomizationError = (state: State) => state.customizationError;
export const getCustomizationLoading = (state: State) => state.customizationLoading;

export const getTransferCommonParameters = (state: State) => state.transferParameters.commonParameters;
export const getTransferEmailParameters = (state: State) => state.transferParameters.emailParameters;
export const getTransferLinkParameters = (state: State) => state.transferParameters.linkParameters;
export const getTransferSize = (state: State) => state.transferParameters.commonParameters.size;
export const getTransferPassword = (state: State) => state.transferParameters.commonParameters.password;
export const getTransferCustomization = (state: State) => state.transferParameters.commonParameters.customization;
export const getTransferPromotion = (state: State) => state.transferParameters.commonParameters.promotion;
export const getTransferAvailabilityDuration = (state: State) => state.transferParameters.commonParameters.availabilityDuration;
export const getTransferPreview = (state: State) => state.transferParameters.commonParameters.preview;
export const getTransferNotification = (state: State) => state.transferParameters.commonParameters.notification;
export const getTransferSender = (state: State) => {
  if (state?.transferParameters?.emailParameters?.delivery?.sender?.email) {
    return state.transferParameters.emailParameters.delivery.sender.email;
  }
  if (state?.transferParameters?.linkParameters?.delivery?.sender?.email) {
    return state.transferParameters.linkParameters.delivery.sender.email;
  }
};
export const getModalSizePremiumDisplayed = (state: State) => state.modalSizePremiumDisplayed;

export const getVerifyEmailResponse = (state: State) => state.verifyEmailResponse;
export const getVerifyEmailLoading = (state: State) => state.verifyEmailLoading;
export const getVerifyEmailLoaded = (state: State) => state.verifyEmailLoaded;
export const getVerifyEmailError = (state: State) => state.verifyEmailError;

export const getValidateEmailVerificationCodeResponse = (state: State) => state.validateEmailVerificationCodeResponse;
export const getValidateEmailVerificationCodeLoaded = (state: State) => state.validateEmailVerificationCodeLoaded;
export const getValidateEmailVerificationCodeLoading = (state: State) => state.validateEmailVerificationCodeLoading;
export const getValidateEmailVerificationCodeError = (state: State) => state.validateEmailVerificationCodeError;

export const getSendEmailVerificationLoading = (state: State) => state.sendEmailVerificationLoading;
export const getSendEmailVerificationLoaded = (state: State) => state.sendEmailVerificationLoaded;
export const getSendEmailVerificationError = (state: State) => state.sendEmailVerificationError;
