import { getMatches } from '@app/shared/helpers/helpers';
import { Policies } from './config/policies';

const REGEXP_INTERPOLATION = /\${([a-zA-Z]+)\}/g;

export class Policy {

    name: string;
    description: string;
    statement: Statement[];
    tags?: { [key: string]: unknown };

    constructor(policyKey: string, params) {
        if (!Policies[policyKey]) {
            throw new Error(`Policy ${policyKey} doesn't exists`);
        }
        this.description = Policies[policyKey].description;
        this.statement = JSON.parse(JSON.stringify(Policies[policyKey].statement));
        this.tags = Policies[policyKey].tags;

        this.buildPolicyName(policyKey, params);
        this.buildStatements(params);
    }

    buildPolicyName(policyKey: string, data: any): void {
        this.name = Policies[policyKey].name.replace(REGEXP_INTERPOLATION, (prop, $1) => {
            if (data[$1]) {
                return data[$1];
            }
        });
    }

    buildStatements(data): void {
        this.statement = this.statement.map(_statement => {
            _statement.resource = _statement.resource.map(_resource => {
                const matches = getMatches(_resource, REGEXP_INTERPOLATION, 1);
                matches.forEach(match => {
                    _resource = _resource.replace('${' + match + '}', data[match]);
                });
                return _resource;
            });
            return _statement;
        });
    }
}

interface Statement {
    action: string[];
    effect: 'Allow' | 'Deny';
    resource: string[];
}
