import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ImageService {

  headers = new HttpHeaders().set('Authorization', 'true');
  headersImage = new HttpHeaders({
    'Content-type': 'application/octet-stream',
    'x-amz-server-side-encryption': 'AES256',
  });

  constructor(private http: HttpClient) { }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  uploadImage(url: string, body: any) {
    const req = new HttpRequest('PUT', url, body, { headers: this.headersImage });
    return this.http.request(req);
  }

}
