import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'countdown'
})
export class CountdownPipe implements PipeTransform {
  constructor(public translate: TranslateService) { }
  transform(
    value: any | moment.Moment,
  ): string {
    const lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
    moment.relativeTimeThreshold('ss', 3);
    moment.relativeTimeThreshold('s', 40);
    moment.relativeTimeThreshold('m', 40);
    moment.relativeTimeThreshold('h', 20);
    moment.relativeTimeThreshold('d', 28);
    moment.relativeTimeThreshold('M', 10);
    if (value) {
      return moment(value).locale(lang).fromNow();
    } else {
      return '';
    }
  }

}
