import { Background } from '@app/shared/models/background';
import { Customization } from '@app/shared/models/customization';
import { Logo } from '@app/shared/models/logo';
import { Promotion } from '@app/shared/models/promotion';
import { DeliveryType, PreviewType, AccessTrackingType, TransferCongifurationParameters } from '@app/shared/models/transferConfiguration';
import { createAction, props } from '@ngrx/store';
import { GetEmailChallengeOutput } from '@smash-sdk-private/kyc/11-2023';

export const toggleEmailFormExtended = createAction('[PrepareMobile] Toggle Email Form extended');
export const toggleConfigDrawerExpanded = createAction('[PrepareMobile] Toggle Config Drawer');
export const resetDrawer = createAction('[PrepareMobile] Reset Drawer');
export const setUploadFormMode = createAction('[PrepareMobile] Set Upload Form Mode', props<{ uploadFormMode: DeliveryType }>());
export const setConfigFormStatus = createAction('[PrepareMobile] Set configForm status valid/invalid', props<{ configFormStatus: string }>())

export const loadPromotion = createAction('[PrepareMobile] Load  Promotion', props<{ promotion: Promotion }>());
export const loadPromotionSuccess = createAction('[PrepareMobile] Load Promotion Success', props<{ promotion: Promotion }>());
export const loadPromotionFailure = createAction('[PrepareMobile] Load Promotion Failure', props<{ error: string }>());

export const loadExternalPromotions = createAction('[PrepareMobile] Load External Promotions');
export const loadExternalPromotionsSuccess = createAction('[PrepareMobile] Load External Promotions Success', props<{ promotions: Promotion[], language?: string }>());
export const loadExternalPromotionsFailure = createAction('[PrepareMobile] Load External Promotions Failure', props<{ error: string }>());

export const selectRandomExternalPromotion = createAction('[PrepareMobile] Select Random External Promotion', props<{ promotions: Promotion[], language?: string }>());
export const selectRandomExternalPromotionSuccess = createAction('[PrepareMobile] Select Random External Promotion Success', props<{ promotion: Promotion }>());
export const selectRandomExternalPromotionFailure = createAction('[PrepareMobile] Select Random External Promotion Failure', props<{ error: string }>());

export const loadCustomization = createAction('[PrepareMobile] Load Customization');
export const loadCustomizationSuccess = createAction('[PrepareMobile] Load Customization Success', props<{ customization: Customization }>());
export const loadCustomizationFailure = createAction('[PrepareMobile] Load Customization Failure', props<{ error: any }>());

export const loadBackgrounds = createAction('[PrepareMobile] Load Backgrounds');
export const loadBackgroundsSuccess = createAction('[PrepareMobile] Load Backgrounds Success', props<{ backgrounds: Background[] }>());
export const loadBackgroundsFailure = createAction('[PrepareMobile] Load Backgrounds Failure', props<{ error: any }>());

export const loadLogos = createAction('[PrepareMobile] Load Logos');
export const loadLogosSuccess = createAction('[PrepareMobile] Load Logos Success', props<{ logos: Logo[] }>());
export const loadLogosFailure = createAction('[PrepareMobile] Load Logos Failure', props<{ error: any }>());

export const addBackgroundImageToAttach = createAction('[PrepareMobile] Add Background Image To Attach', props<{ image?: any[], url?: string }>());
export const addBackgroundImageToAttachSuccess = createAction('[PrepareMobile] Add Background Image To Attach Success', props<{ background: any }>());
export const addBackgroundImageToAttachFailure = createAction('[PrepareMobile] Add Background Image To Attach Failure', props<{ error: any }>());

export const addBackgroundVideoToAttach = createAction('[PrepareMobile] Add Background Video To Attach', props<{ video: any }>());
export const addBackgroundVideoToAttachSuccess = createAction('[PrepareMobile] Add Background Video To Attach Success', props<{ background: any }>());
export const addBackgroundVideoToAttachFailure = createAction('[PrepareMobile] Add Background Video To Attach Failure', props<{ error: any }>());

export const addLogoToAttach = createAction('[PrepareMobile] Add Team Logo', props<{ image?: any[], url?: string }>());
export const addLogoToAttachSuccess = createAction('[PrepareMobile] Add Team Logo Success', props<{ logo: any }>());
export const addLogoToAttachFailure = createAction('[PrepareMobile] Add Team Logo Failure', props<{ error: any }>());

export const ResetCustomisationEdited = createAction('[PrepareMobile] Reset Customization Edited');
export const saveCustomization = createAction('[PrepareMobile] Save Customization', props<{ logo?: Logo, background?: Background }>());

export const SetTransferAvailability = createAction('[PrepareMobile] Set Transfer Availability', props<{ availability: number }>());
export const SetTransferPassword = createAction('[PrepareMobile] Set Transfer Password', props<{ password: string }>());
export const SetTransferAccessTracking = createAction('[PrepareMobile] Set Transfer AccessTracking', props<{ accessTracking: AccessTrackingType }>());
export const SetTransferPreviewType = createAction('[PrepareMobile] Set Transfer Preview Type', props<{ preview: PreviewType }>());
export const SetTransferCustomization = createAction('[PrepareMobile] Set Transfer Customization', props<{ logo: Logo, background: Background }>());
export const SetTransferPromotion = createAction('[PrepareMobile] Set Transfer Promotion', props<{ promotion: Promotion }>());
export const SetTransferSender = createAction('[PrepareMobile] Set Transfer Sender', props<{ sender: { email: string, name?: string } }>());

export const SetLinkCustomUrl = createAction('[PrepareMobile] Set Link Custom Url', props<{ customUrl: string }>());
export const SetLinkTitle = createAction('[PrepareMobile] Set Link Title', props<{ title: string }>());

export const SetEmailTitle = createAction('[PrepareMobile] Set Email Title', props<{ title: string }>());
export const SetEmailDescription = createAction('[PrepareMobile] Set Email Description', props<{ description: string }>());
export const SetEmailReceivers = createAction('[PrepareMobile] Set Email Receivers', props<{ receivers: string[] }>());

export const SetTransferConfigurationParameters = createAction('[PrepareMobile] Set Transfer Configuration Parameters', props<{ parameters: TransferCongifurationParameters }>());

export const DisplayModalSizePremium = createAction('[PrepareMobile] Display Modal Size Premium');
export const HideModalSizePremium = createAction('[PrepareMobile] Hide Modal Size Premium');
export const UpdatePrepareStep = createAction('[PrepareMobile] Set Prepare Step', props<{ step: 'emailSelection' | 'emailVerificationSection' | 'deliveryTypeSelection' | 'parametersSelection' }>());

export const VerifyEmail = createAction('[Prepare] Verify Email', props<{ username: string }>());
export const VerifyEmailSuccess = createAction('[Prepare] Verify Email Success', props<{ status: GetEmailChallengeOutput['emailChallenge']['status'] }>());
export const VerifyEmailFailure = createAction('[Prepare] Verify Email Failure', props<{ error: any }>());
export const ResetVerifyEmail = createAction('[Prepare] Reset Verify Email');

export const ValidateEmailVerificationCode = createAction('[Prepare] Validate Email Verification Code', props<{ username: string, code: number }>());
export const ValidateEmailVerificationCodeSuccess = createAction('[Prepare] Validate Email Verification Code Success', props<{ status: string }>());
export const ValidateEmailVerificationCodeFailure = createAction('[Prepare] Validate Email Verification Code Failure', props<{ error: any }>());

export const SendEmailVerificationCode = createAction('[Prepare] Send Email Verification Code', props<{ username: string }>());
export const SendEmailVerificationCodeSuccess = createAction('[Prepare] Send Email Verification Code Success', props<{ status: string }>());
export const SendEmailVerificationCodeFailure = createAction('[Prepare] Send Email Verification Code Failure', props<{ error: any }>());
