import { CGUConsent } from '@app/shared/models/cguConsent';
import { EventScitylana } from '@app/shared/models/eventScitylana';
import { createAction, props } from '@ngrx/store';
import { DeviceInfo } from 'ngx-device-detector';
import { CookiesConfiguration } from '../services/cookies.service';
import * as fromEnvironment from '@app/core/reducers/environment.reducer';
import { Language } from '@app/shared/types/Language';
export const loadDevice = createAction(
  '[Environment] Load Device',
);

export const loadDeviceSuccess = createAction(
  '[Environment] Load Device Success',
  props<{ device: { deviceInfo: DeviceInfo, isDesktop: boolean, isMobile: boolean, isTablet: boolean } }>()
);

export const loadDeviceFailure = createAction(
  '[Environment] Load Device Failure',
  props<{ error: any }>()
);

// Load Services from main namespace
export const loadServicesFromCurrentLocation = createAction(
  '[Environment] Load Services From Current Location',
);

export const loadServicesFromToken = createAction(
  '[Environment] Load Services From Token',
);

export const loadServicesFromDomain = createAction(
  '[Environment] Load Services From Domain',
);

export const loadServicesFromRegion = createAction(
  '[Environment] Load Services From Region',
  props<{ region: string }>()
);

export const loadServices = createAction(
  '[Environment] Load Services',
  props<{ region?: string }>()
);

export const loadServicesSuccess = createAction(
  '[Environment] Load Services Success',
  props<{ region: string, country: string, countryCode: string }>()
);

export const loadServicesFailure = createAction(
  '[Environment] Load Services Failure',
  props<{ error: string }>()
);

export const SetupLanguage = createAction(
  '[Environment] Setup language',
);

export const SetupLanguageSuccess = createAction(
  '[Environment] Setup language success',
  props<{ language: Language }>()
);

export const SelectLanguage = createAction(
  '[Environment] Select language',
  props<{ language: Language, currentPath?: string }>()
);

export const SelectLanguageSuccess = createAction(
  '[Environment] Select language success',
  props<{ language: Language }>()
);

export const loadCountries = createAction(
  '[Environment] Load Countries',
);

export const loadCountriesSuccess = createAction(
  '[Environment] Load Countries Success',
  props<{ countries: any[] }>()
);

export const loadCountriesFailure = createAction(
  '[Environment] Load Countries Failure',
  props<{ error: any }>()
);

export const redirectToSelectedDomain = createAction(
  '[Environment] Redirect to selected team domain',
);

export const redirectToAccountWithSelectedDomain = createAction(
  '[Environment] Redirect to account with selected team domain',
);

// debug
export const ComputePolicies = createAction(
  '[Environnement] Compute Policies'
);

export const ComputePoliciesSuccess = createAction(
  '[Environnement] Compute Policies Success',
  props<{ update?: any }>()
);

export const GetRemotePoliciesAndRoles = createAction(
  '[Environnement] getRemote Policies and Roles'
);

export const GetRemotePoliciesAndRolesSuccess = createAction(
  '[Environnement] getRemote Policies and Roles success',
  props<{ remotePolicies: any, remoteRoles: any }>()
);

export const GetRemotePoliciesAndRolesFailure = createAction(
  '[Environnement] getRemote Policies and Roles failure',
  props<{ error: any }>()
);

export const ExecutePoliciesUpdate = createAction(
  '[Environnement] Execute Policies Update',
  props<{ PoliciesToCreate: any[], PoliciesToUpdate: any[], RolesToDelete: any[], RolesToCreate: any[], RolesToUpdate: any[], PoliciesToDelete: any[] }>()
);

export const ExecutePoliciesUpdateSuccess = createAction(
  '[Environnement] Execute Policies Update success',
  props<{ update?: any }>()
);

export const ScanUpdates = createAction(
  '[Environnement] Scanning Update'
);

export const ScanUpdatesSuccess = createAction(
  '[Environnement] Scanning Update Success'
);

export const ScanUpdatesFailure = createAction(
  '[Environnement] Scanning Update Failure'
);

export const RunUpdates = createAction(
  '[Environnement] Running Updates'
);

export const FinalizeUpdates = createAction(
  '[Environnement] Finalize Updates'
);

export const RunUpdatesSuccess = createAction(
  '[Environnement] Running Updates Success',
  props<{ refresh?: boolean }>()
);

export const RunUpdatesFailure = createAction(
  '[Environnement] Running Updates Failure'
);

export const AddPlayer = createAction(
  '[Viewer] Add Player',
  props<{ id: string, url: string, allowConcurrency: boolean, unstoppable: boolean }>()
);

export const UpdatePlayerPlayingAndStopOthers = createAction(
  '[Viewer] Update Player Playing And Stop Others',
  props<{ id: string }>()
);

export const StopAllPlayers = createAction(
  '[Viewer] Stop All Player',
);


// Cookies
export const LoadCookiesConsentConfiguration = createAction(
  '[Environnement] Load Cookies Consent Configuration',
);

export const LoadCookiesConsentConfigurationSuccess = createAction(
  '[Environnement] Load Cookies Consent Configuration Success',
  props<{ cookiesConsentConfiguration: CookiesConfiguration, cookiesConsentSetByUser: boolean }>()
);

export const CloseCookiesConsentConfiguration = createAction(
  '[Environnement] Close Cookies Consent Configuration',
);

export const UpdateCookiesConsentConfiguration = createAction(
  '[Environnement] Update Cookies Consent Configuration',
  props<{ cookiesConsentConfiguration: CookiesConfiguration }>()
);

export const UpdateCookiesConsentConfigurationSuccess = createAction(
  '[Environnement] Update Cookies Consent Configuration Success',
  props<{ cookiesConsentConfiguration: CookiesConfiguration, oldCookiesConsentConfiguration: CookiesConfiguration }>()
);

export const UpdateCookiesConsentConfigurationFailure = createAction(
  '[Environnement] Update Cookies Consent Configuration Failure',
  props<{ error: any }>()
);

export const AcceptAllCookies = createAction(
  '[Environnement] Accept All Cookies',
);

export const AcceptAllCookiesSuccess = createAction(
  '[Environnement] Accept All Cookies Success',
);

export const AcceptAllCookiesFailure = createAction(
  '[Environnement] Accept All Cookies Failure',
  props<{ error: any }>()
);

export const DenyAllCookies = createAction(
  '[Environnement] Deny All Cookies',
);

export const DenyAllCookiesSuccess = createAction(
  '[Environnement] Deny All Cookies Success',
);

export const DenyAllCookiesFailure = createAction(
  '[Environnement] Deny All Cookies Failure',
  props<{ error: any }>()
);

// CGU
export const AcceptCGU = createAction(
  '[Environnement] Accept CGU',
);

export const AcceptCGUSuccess = createAction(
  '[Environnement] Accept CGU Success',
  props<{ cgu: CGUConsent }>()
);

// Stripe

export const LoadStripe = createAction(
  '[Environnement] Load Stripe',
);

export const LoadStripeSuccess = createAction(
  '[Environnement] Load Stripe Success',
);

export const LoadStripeFailure = createAction(
  '[Environnement] Load Stripe Failure',
  props<{ error: any }>()
);

// Scitylana

export const CreateEventScitylana = createAction(
  '[Environnement] Create Event Scitylana',
  props<{ event: EventScitylana }>()
);

export const CreateEventScitylanaSuccess = createAction(
  '[Environnement] Create Event Scitylana Success',
);

export const CreateEventScitylanaFailure = createAction(
  '[Environnement] Create Event Scitylana Failure',
  props<{ error: any }>()
);

export const FollowDomainRedirect = createAction(
  '[Environnement] Follow Domain Redirect',
  props<{ redirect: string }>()
);

export const SetDurationChoice = createAction(
  '[Environnement] Set plan duration choice from squareSpace',
  props<{ durationChoice: string }>()
);

export const OnBoarding = createAction(
  '[Environnement] OnBoarding event',
  props<{ onBoardingState: fromEnvironment.State['onBoarding'], redirectToPricing?: string }>()
)

export const OnBoardingSuccess = createAction(
  '[Environnement] OnBoarding event success',
  props<{ onBoardingState: fromEnvironment.State['onBoarding'] }>()
)
