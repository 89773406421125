import { Roles } from './config/roles';
import { Policy } from './policies';

const REGEXP_INTERPOLATION = /\${([a-zA-Z]+)\}/;

export class TeamRole {
    name: string;
    shortName: string;
    description: string;
    policies: Policy[];
    tags?: { [key: string]: unknown };

    constructor({ roleName, role }, params) {
        this.buildRoleName(roleName, params);
        this.buildShortName();
        this.buildPolicies(roleName, params);
        this.description = role.description;
        this.tags = role.tags;
    }

    buildRoleName(roleName: string, data: any): void {
        this.name = Roles.teamRoles[roleName].name.replace(REGEXP_INTERPOLATION, (prop, $1) => {
            if (data[$1]) {
                return data[$1];
            }
        });
    }

    buildShortName(): void {
        this.shortName = this.name.split('$').pop();
    }

    buildPolicies(roleName, params): void {
        this.policies = Roles.teamRoles[roleName].policies.map(policy => new Policy(policy, params));
    }
}
