import { createAction, props } from '@ngrx/store';

export const ToggleMenu = createAction(
  '[Layout] Toggle Menu',
  props<{ menuDisplayed: boolean }>()
);

export const UpdateFileOver = createAction(
  '[Layout] Update File Over',
  props<{ fileOver: boolean }>()
);
