import { Injectable } from '@angular/core';
import * as PaymentActions from '@app/core/actions/payment.actions';
import { PaymentStripeService } from '@app/core/services/payment-stripe.service';
import * as fromRoot from '@app/reducers';
import { getProtocolHostnameAndPort } from '@app/shared/helpers/location';
import { genericRetry } from '@app/shared/operators/genericRetry';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Billing } from '@smash-sdk/billing/01-2024';
import ExecutePaypalPaymentError from '@smash-sdk/billing/01-2024/types/ExecutePaypalPayment/ExecutePaypalPaymentError';
import ExecuteStripePaymentError from '@smash-sdk/billing/01-2024/types/ExecuteStripePayment/ExecuteStripePaymentError';
import GetInformationsError from '@smash-sdk/billing/01-2024/types/GetInformations/GetInformationsError';
import GetPlanGroupError from '@smash-sdk/billing/01-2024/types/GetPlanGroup/GetPlanGroupError';
import GetSubscriptionError from '@smash-sdk/billing/01-2024/types/GetSubscription/GetSubscriptionError';
import InitiatePaypalPaymentError from '@smash-sdk/billing/01-2024/types/InitiatePaypalPayment/InitiatePaypalPaymentError';
import { defer, from, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Constant } from 'src/constant';

@Injectable()
export class PaymentEffects {

  processPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processPayment),
      mergeMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store$.select(fromRoot.getPaymentType)
          )
        )
      ),
      switchMap(([action, paymentType]: any) => {
        switch (paymentType) {
          case Constant.paymentType.CARD:
            return [PaymentActions.processStripePayment()];
          case Constant.paymentType.PAYPAL:
            return [PaymentActions.processPaypalPayment()];
          default:
            break;
        }
      })
    )
  );

  processStripePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processStripePayment),
      mergeMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store$.select(fromRoot.getCardToken)
          )
        )
      ),
      switchMap(([_, cardToken]: any) => {
        const billingSdk = new Billing();
        return from(billingSdk.initiateStripePayment()).pipe(
          mergeMap((resPaymentIntent) => {
            const { paymentIntent } = resPaymentIntent;
            const dataToPay = {
              secret: paymentIntent.clientSecret,
              token: cardToken.id
            };
            return this.paymentStripeService.stripeHandleCardPayment(dataToPay).then((resStripe) => {
              if (resStripe.error) {
                return PaymentActions.processStripePaymentFailure({ error: resStripe.error });
              } else {
                const { paymentIntent } = resStripe;
                return PaymentActions.processStripePaymentCheck({ paymentIntent: paymentIntent.id });
              }
            });
          }),
          catchError((error: any) => {
            return of(PaymentActions.processStripePaymentFailure({ error: error.message }));
          }
          )
        );
      }),
      catchError((error: any) => {
        return of(PaymentActions.processStripePaymentFailure({ error: error.message }));
      })
    )
  );

  processStripePaymentCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processStripePaymentCheck),
      concatMap((action) => {
        const billingSdk = new Billing();
        return defer(() => billingSdk.getSubscription()).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: GetSubscriptionError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          concatMap(({ subscription }) => {
            return defer(() => billingSdk.getInformations()).pipe(
              genericRetry({
                retryConfig: [
                  ...Constant.NetworkErrorsRetryConfiguration,
                  { error: GetInformationsError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                  { error: GetInformationsError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                  { error: GetInformationsError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
                ]
              }),
              concatMap(({ informations }) => {
                return defer(() => billingSdk.executeStripePayment({ paymentIntentId: action.paymentIntent })).pipe(
                  genericRetry({
                    retryConfig: [
                      ...Constant.NetworkErrorsRetryConfiguration,
                      { error: ExecuteStripePaymentError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                      { error: ExecuteStripePaymentError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                      { error: ExecuteStripePaymentError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
                    ]
                  }),
                  mergeMap(({ payment }) => {
                    if (payment.status === Constant.paymentStatus.SUCCESS) {
                      return [PaymentActions.processStripePaymentSuccess({ response: { payment, subscription, plan: subscription.plan, informations } })];
                    } else {
                      return [{ type: 'noop' }];
                    }
                  }),
                  catchError((error: any) => {
                    return of(PaymentActions.processStripePaymentFailure({ error: error.message }));
                  }),
                );
              }));
          }));
      }),
      catchError((error: any) => {
        return of(PaymentActions.processStripePaymentFailure({ error: error.message }));
      })
    )
  );

  processStripePaymentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processStripePaymentSuccess),
      map(action => action),
      tap(() => {
        this.store$.dispatch(PaymentActions.processPaymentSuccess());
      })
    ),
    { dispatch: false }
  );

  // paypal
  processPaypalPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processPaypalPayment),
      concatMap((action) => {
        const billingSdk = new Billing();
        return defer(() => billingSdk.initiatePaypalPayment())
          .pipe(
            genericRetry({
              retryConfig: [
                ...Constant.NetworkErrorsRetryConfiguration,
                { error: InitiatePaypalPaymentError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                { error: InitiatePaypalPaymentError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                { error: InitiatePaypalPaymentError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              ]
            }),
            map(({ approvalUrl }) => {
              // wait for user approval
              return PaymentActions.paypalWaitUserAgreement({ approvalUrl });
            }),
            catchError((error: any) => {
              return of(PaymentActions.processPaypalPaymentFailure({ error: error.message }));
            }),
          );
      })
    )
  );

  executePaypal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.executePaypal),
      map(action => action.token),
      concatMap((token) => {
        const billingSdk = new Billing();
        return defer(() => billingSdk.getSubscription()).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: GetSubscriptionError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          concatMap(({ subscription }) => {
            return defer(() => billingSdk.getInformations()).pipe(
              genericRetry({
                retryConfig: [
                  ...Constant.NetworkErrorsRetryConfiguration,
                  { error: GetInformationsError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                  { error: GetInformationsError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                  { error: GetInformationsError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
                ]
              }),
              concatMap(({ informations }) => {
                return defer(() => billingSdk.executePaypalPayment({ token })).pipe(
                  genericRetry({
                    retryConfig: [
                      ...Constant.NetworkErrorsRetryConfiguration,
                      { error: ExecutePaypalPaymentError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                      { error: ExecutePaypalPaymentError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                      { error: ExecutePaypalPaymentError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
                    ]
                  }),
                  mergeMap(({ payment }: any) => {
                    if (payment.status === Constant.paymentStatus.SUCCESS) {
                      return [PaymentActions.processPaypalPaymentSuccess({ response: { payment, subscription, plan: subscription.plan, informations } })];
                    } else {
                      return [{ type: 'noop' }];
                    }
                  }),
                  catchError((error: any) => {
                    return of(PaymentActions.processPaypalPaymentFailure({ error: error.message }));
                  }),
                );
              }));
          }));
      }),
      catchError((error: any) => {
        return of(PaymentActions.processPaypalPaymentFailure({ error: error.message }));
      })
    )
  );

  cancelPaypal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.cancelPaypal),
      switchMap(() => {
        const billingSdk = new Billing();
        return defer(() => billingSdk.getSubscription()).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: GetSubscriptionError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              { error: GetSubscriptionError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          concatMap(({ subscription }) => {
            return defer(() => billingSdk.getPlanGroup({ planId: subscription.plan.id })).pipe(
              genericRetry({
                retryConfig: [
                  ...Constant.NetworkErrorsRetryConfiguration,
                  { error: GetPlanGroupError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                  { error: GetPlanGroupError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                  { error: GetPlanGroupError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
                ]
              }),
              concatMap(({ group }: any) => {
                const baseUrl = getProtocolHostnameAndPort();
                window.location.href = baseUrl + '/signup/plan/' + encodeURIComponent(group.id);
                return [{ 'type': 'noop' }];
              })
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromRoot.State>,
    private readonly paymentStripeService: PaymentStripeService,
  ) { }

}
