import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountActions } from '@app/core/actions';
import { DomainService } from '@app/core/services/domain.service';
import { IamService } from '@app/core/services/iam.service';
import { TokenService } from '@app/core/services/token.service';
import * as fromRoot from '@app/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, from, of } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, map, mergeMap, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { Constant } from 'src/constant';
import { TeamsActions } from '../actions';
import { getMainDomainSuffix } from '@app/shared/helpers/location';
import { Domain } from '@smash-sdk/domain/01-2024';
import { environment } from 'src/environments/environment';


@Injectable()
export class TeamsEffects {

  loadAccountTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.loadAccountTeams),
      concatMap(() => {
        return forkJoin([this.iamService.listTeams(), this.iamService.listRoles()]).pipe(
          mergeMap(([resAccountTeams, resRoles]: any[]) => {
            const { teams } = resAccountTeams;
            const { roles } = resRoles;
            const reqs = [];
            if (teams.length) {
              teams.forEach(team => {
                reqs.push(this.iamService.findTeamWithUsers(team.id));
              });
              return forkJoin(reqs).pipe(map((resTeams: any[]) => {
                const teamsBuilded = resTeams.map(team => {
                  team.roles = roles.filter(role => role.name.startsWith(team.id)).map(role => {
                    role.shortName = role.name.split('$').pop().toUpperCase(); // Get role name without teamId
                    return role;
                  });
                  return team;
                });
                return TeamsActions.loadAccountTeamsSuccess({ teams: teamsBuilded.sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0) });
              }
              ));
            }
            return of(TeamsActions.loadAccountTeamsSuccess({ teams: [] }));
          }),
          catchError((error: HttpErrorResponse) => of(TeamsActions.loadAccountTeamsFailure({ error: error.status })))
        );
      }),
      catchError((error: HttpErrorResponse) => of(TeamsActions.loadAccountTeamsFailure({ error: error.status })))
    )
  );

  addTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.addTeam),
      withLatestFrom(this.store$.select(fromRoot.getActiveIdentity)),
      switchMap(([action, activeIdentity]) => {
        const domainToCreate = action.team.domain + "." + environment.appDomain;
        // TODO to switch with subscription's plan values
        // but plan walues are not enough .. need to get the redirection default settings from somewhere as well
        const options = Constant.defaultPremiumDomainSettings;
        const domainSdk = new Domain();
        return from(domainSdk.createDomain({ domain: domainToCreate, options })).pipe(concatMap(({ domain: domainCreated }) => {
          const { team, region, domain } = domainCreated;
          const hydratedTeam = { id: team, domain };
          const account = this.tokenService.getAccountFromToken(activeIdentity.token.token);
          return this.iamService.setupTeamRoles({ account, team: hydratedTeam, region }).pipe(concatMap(() => {
            return this.iamService.updateTeam(team, { name: action.team.name }).pipe(concatMap(({ team: teamUpdated }) => {
              return of(TeamsActions.addTeamSuccess({ team: teamUpdated }));
            }));
          }));
        }),
          catchError((error: HttpErrorResponse) => of(TeamsActions.addTeamFailure({ error: error.status })))
        );
      })
    ));

  addTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.addTeamSuccess),
      tap(() => this.store$.dispatch(AccountActions.LoadUserRolesAndTeams()))),
    { dispatch: false }
  );

  deleteTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.DeleteTeam),
      switchMap(async ({ teamId, domain }) => {

        // List Users and Groups
        const [{ users }, { groups }] = await Promise.all(
          [
            this.iamService.listTeamUsers(teamId).toPromise(),
            this.iamService.listTeamGroups(teamId).toPromise(),
          ]
        );

        if (users.length > 0 || groups.length > 0) {
          return TeamsActions.DeleteTeamFailure({ error: 'PREMIUM_ADMIN_MEMBER_MODAL_DELETE_TEAM_ERROR_ATTACHED_RESSOURCES' });
        } else {
          // List hydrated roles
          const roles = await this.iamService.listTeamHydratedRoles(teamId).toPromise();

          // Detach Policies from Role
          const reqsDetachRoleFromTeam = [];
          const reqsDetachPolicies = [];
          if (roles.length > 0) {
            for (const role of roles) {
              reqsDetachRoleFromTeam.push(this.iamService.detachTeamRole(teamId, role.name).toPromise());
              if (role?.policies.length > 0) {
                for (const policy of role.policies) {
                  reqsDetachPolicies.push(this.iamService.detachRolePolicy(role.name, policy.name).toPromise());
                }
              }
            }
          }
          await Promise.all(reqsDetachRoleFromTeam);
          await Promise.all(reqsDetachPolicies);

          // Delete Role and Policies
          const reqsDeleteRoles = [];
          const reqsDeletePolicies = [];
          reqsDeleteRoles.push(...roles.map((role) => this.iamService.deleteRole(role.name).toPromise()));
          const policiesToDeleteDic = {};
          reqsDeletePolicies.push(...roles.map((role) => role.policies.map(policy => {
            if (!policiesToDeleteDic[policy.name]) {
              policiesToDeleteDic[policy.name] = policy; return this.iamService.deletePolicy(policy.name).toPromise();
            }
          })));
          await Promise.all([...reqsDeleteRoles, ...reqsDeletePolicies]);

          const domainSdk = new Domain();
          await domainSdk.deleteDomain({ domainId: domain });

          return TeamsActions.DeleteTeamSuccess();
        }
      })
    )
  );

  deleteTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.DeleteTeamSuccess),
      tap(() => this.store$.dispatch(AccountActions.LoadUserRolesAndTeams()))),
    { dispatch: false }
  );

  updateTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.updateTeam),
      withLatestFrom(
        this.store$.select(fromRoot.getActiveTeam),
      ),
      concatMap(([action, activeTeam]) => {
        const teamToUpdate = { ...action.team };
        teamToUpdate.oldDomain = teamToUpdate.oldDomain + getMainDomainSuffix();
        teamToUpdate.domain = action.team.domain + getMainDomainSuffix();
        const domainSdk = new Domain();
        return from(domainSdk.updateDomain({ domainId: teamToUpdate.oldDomain, domain: teamToUpdate.domain })).pipe(
          concatMap(() => {
            return this.iamService.updateTeam(action.team.id, { name: teamToUpdate.name }).pipe(
              concatMap(({ team }: any) => {
                const mergedTeam = Object.assign({}, activeTeam, team);
                return [
                  TeamsActions.updateTeamSuccess({ team: mergedTeam }),
                  AccountActions.updateActiveTeam({ team: mergedTeam }),
                  TeamsActions.loadAccountTeams(),
                ];
              }));
          }),
          catchError((err: HttpErrorResponse) => {
            const error = err.status;
            return of(TeamsActions.updateTeamFailure({ error }));
          })
        );
      }
      )
    ));

  updateTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.updateTeamSuccess),
      tap(() => this.store$.dispatch(AccountActions.LoadUserRolesAndTeams()))),
    { dispatch: false }
  );

  addMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.addMember),
      concatMap((action) => {
        return this.iamService.createUser(action.member).pipe(
          concatMap((resMember: any) => {
            return forkJoin([
              this.iamService.attachUserToTeam(action.team, resMember.user.id),
              this.iamService.attachUserRole(resMember.user.id, action.role.name)
            ]).pipe(concatMap(res => {
              return [TeamsActions.addMemberSuccess({ member: resMember.user })];
            }));
          }),
          catchError((error: HttpErrorResponse) => {
            return of(TeamsActions.addMemberFailure({ error: error.error }));
          })
        );
      })
    )
  );

  detachMemberFromTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.DetachMemberFromTeam),
      switchMap(action => {
        return this.iamService.listUserRoles(action.member.id).pipe(concatMap(resRoles => {
          const batchDetachUserTeamRoles = resRoles.roles.filter(role => role.name.startsWith(action.team)).map(role => this.iamService.detachUserRole(action.member.id, role.name));
          return forkJoin([this.iamService.detachUserFromTeam(action.team, action.member.id), ...batchDetachUserTeamRoles]).pipe(
            map((response: any) => {
              return TeamsActions.DetachMemberFromTeamSuccess();
            }),
            catchError(error =>
              of(TeamsActions.DetachMemberFromTeamFailure({ error: error.status })))
          );
        }));
      }))
  );


  loadTeamDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.LoadTeamDetails),
      switchMap((action) => {
        return forkJoin([this.iamService.findTeamWithUsers(action.id), this.iamService.listRoles()]).pipe(concatMap(([team, resRoles]: any) => {
          const { roles } = resRoles;
          team.roles = roles
            .filter(role => role.name.startsWith(team.id))
            .map(role => {
              role.shortName = role.name.split('$').pop();
              return role;
            });
          return of(TeamsActions.LoadTeamDetailsSuccess({ team }));
        }));
      })
    )
  );

  loadTeamGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.LoadTeamGroups),
      switchMap((action) => {
        return this.iamService.findTeam(action.id).pipe(concatMap(({ team }) => {
          return this.iamService.listTeamGroups(team.id).pipe(concatMap(({ groups }) => {
            return of(TeamsActions.LoadTeamGroupsSuccess({ groups }));
          }));
        })
        );
      })
    )
  );

  loadTeamMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.LoadTeamMembers),
      switchMap((action) => {
        return forkJoin([this.iamService.findTeamWithUsers(action.id), this.iamService.listTeamGroups(action.id)]).pipe(concatMap(([{ users }, { groups }]) => {
          const teamUsers = [];
          if (users?.length > 0) {
            teamUsers.push(...users);
          }
          const reqsRetrieveGroupUsers = [];
          if (groups?.length > 0) {
            groups.forEach(group => {
              reqsRetrieveGroupUsers.push(this.iamService.listGroupUsers(group.id));
            });
          }
          return forkJoin(reqsRetrieveGroupUsers).pipe(
            defaultIfEmpty([]),
            concatMap(listRes => {
              const associatedGroupUsers = [];
              if (listRes?.length > 0) {
                listRes.map(({ users }) => associatedGroupUsers.push(...users));
              }
              /* Filtering users, because they can be both directly attached to team (domain) or indrectly through groups */
              const members = [...teamUsers, ...associatedGroupUsers];
              const mapUsername = new Map();
              const filteredMembers = members.filter(member => {
                const val = mapUsername.get(member.username);
                if (!val) {
                  mapUsername.set(member.username, member);
                  return true;
                } else {
                  return false;
                }
              });
              return of(TeamsActions.LoadTeamMembersSuccess({ members: filteredMembers }));
            }));
        })
        );
      })
    )
  );

  addEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.AddEmail),
      switchMap((action) => {
        return this.iamService.findTeam(action.team).pipe(concatMap((res: any) => {
          const { team } = res;
          let config = team?.config;
          let authentication = team?.config?.authentication;
          let rules = team?.config?.authentication?.rules;
          if (config) {
            if (authentication) {
              if (rules && rules.length) {
                const emailExists = rules.find(rule => rule.type === Constant.teamConfig.rulesNames.email && rule.value === action.email);
                if (emailExists) {
                  return of(TeamsActions.AddEmailFailure({ error: 'EMAIL_ALREADY_EXISTS' }));
                }
                rules = [...rules, { type: Constant.teamConfig.rulesNames.email, value: action.email }];
              } else {
                rules = [{ type: Constant.teamConfig.rulesNames.email, value: action.email }];
              }
              authentication = {
                mode: Constant.teamConfig.authenticationMode.anonymous,
                rules,
              };
            } else {
              authentication = {
                mode: Constant.teamConfig.authenticationMode.anonymous,
                rules: [
                  { type: Constant.teamConfig.rulesNames.email, value: action.email }
                ]
              };
            }
            config = {
              ...config,
              authentication,
            };
          } else {
            config = {
              authentication: {
                mode: Constant.teamConfig.authenticationMode.anonymous,
                rules: [
                  { type: Constant.teamConfig.rulesNames.email, value: action.email }
                ]
              }
            };
          }
          return of(TeamsActions.UpdateTeamConfig({ team: action.team, config }));
        }));
      })
    )
  );

  addEmailPattern$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.AddEmailPattern),
      switchMap((action) => {
        return this.iamService.findTeam(action.team).pipe(concatMap((res: any) => {
          const { team } = res;
          let config = team?.config;
          let authentication = team?.config?.authentication;
          let rules = team?.config?.authentication?.rules;
          if (config) {
            if (authentication) {
              if (rules && rules.length) {
                const emailExists = rules.find(rule => rule.type === Constant.teamConfig.rulesNames.wildcard && rule.value === action.pattern);
                if (emailExists) {
                  return of(TeamsActions.AddEmailPatternFailure({ error: 'PATTERN_ALREADY_EXISTS' }));
                }
                rules = [...rules, { type: Constant.teamConfig.rulesNames.wildcard, value: action.pattern }];
              } else {
                rules = [{ type: Constant.teamConfig.rulesNames.wildcard, value: action.pattern }];
              }
              authentication = {
                mode: Constant.teamConfig.authenticationMode.anonymous,
                rules,
              };
            } else {
              authentication = {
                mode: Constant.teamConfig.authenticationMode.anonymous,
                rules: [
                  { type: Constant.teamConfig.rulesNames.wildcard, value: action.pattern }
                ]
              };
            }
            config = {
              ...config,
              authentication,
            };
          } else {
            config = {
              authentication: {
                mode: Constant.teamConfig.authenticationMode.anonymous,
                rules: [
                  { type: Constant.teamConfig.rulesNames.wildcard, value: action.pattern }
                ]
              }
            };
          }
          return of(TeamsActions.UpdateTeamConfig({ team: action.team, config }));
        }));
      })
    )
  );

  detachGuestFromTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.DetachGuestFromTeam),
      switchMap((action) => {
        return this.iamService.findTeam(action.team).pipe(concatMap(resTeam => {
          const config = resTeam.team.config;
          // Remove email from team
          const emailsWithoutOldValue = config.authentication.rules.filter(email => email.value !== action.guest.value);
          config.authentication.rules = emailsWithoutOldValue;
          return this.iamService.updateTeam(action.team, { config }).pipe(concatMap(resUpdate => {
            return [TeamsActions.DetachGuestFromTeamSuccess({ team: resUpdate.team })];
          }));
        }));
      })
    )
  );

  updateTeamConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.UpdateTeamConfig),
      switchMap((action) => {
        return this.iamService.findTeam(action.team).pipe(concatMap((res: any) => {
          const { team } = res;
          return this.iamService.updateTeam(team.id, { config: action.config }).pipe(
            concatMap((res: any) => {
              return [
                TeamsActions.UpdateTeamConfigSuccess({ team: res.team }),
                TeamsActions.loadAccountTeams(),
              ];
            }),
            catchError(() =>
              of(TeamsActions.UpdateTeamConfigFailure({ error: 'FAILED_TO_UPDATE_CONFIG' }))
            )
          );
        }));
      })
    )
  );


  constructor(
    private actions$: Actions,
    private domainService: DomainService,
    private tokenService: TokenService,
    private iamService: IamService,
    private store$: Store<fromRoot.State>
  ) { }

}

