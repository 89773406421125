import { SigninActions } from '@app/client/signin/actions';
import { Country } from '@app/shared/models/country';
import { Device } from '@app/shared/models/device';
import { Service } from '@app/shared/models/service';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Language } from '@app/shared/types/Language';
import { Constant } from 'src/constant';
import { EnvironmentActions } from '../actions';
import { CookiesConfiguration } from '../services/cookies.service';
import { environment } from 'src/environments/environment';


export const environmentFeatureKey = 'environment';

export interface PlayerObject {
  id: string;
  url: string;
  playing: boolean;
  allowConcurrency: boolean;
  unstoppable: boolean;
}
export interface PlayersAudioState extends EntityState<PlayerObject> { }


const adapterPlayers = createEntityAdapter<PlayerObject>();
const playersInitialState: PlayersAudioState = adapterPlayers.getInitialState({});
export interface State {
  device: Device;
  services: Service[];
  selectedLanguage: Language;
  supportedLanguages: any[];
  countries: Country[];
  countriesLoading: boolean;
  countriesLoaded: boolean;
  countriesError: any;

  stripeLoading: boolean;
  stripeLoaded: boolean;
  stripeLoadingError: any;

  country: string;
  countryCode: string;

  region: string;
  error: string;
  loadServicesError: string;

  // Running Updates
  updateRunning: boolean;
  updateRolesAndPoliciesSuccess: boolean;
  pendingUpdates: any[];
  remotePolicies: any[];

  players: PlayersAudioState;

  // CGU
  cgu: {
    cguLastVersion: number;
    cguLastConsentUpdated: string;
    cguLastConsentValue: boolean;
  };

  // Cookies
  cookiesConsentConfiguration: CookiesConfiguration;
  cookiesConsentConfigurationLoaded: boolean;
  cookiesConsentSetByUser: boolean;

  // choice of plan duration from squareSpace:
  durationChoice: string;

  // Onboarding
  onBoarding: {
    discoverProModal: 'firstVisit' | 'showNextVisit' | 'hideForNow' | 'hide';
    counter: number;
    lastVisit?: string;
  };
}

export const initialState: State = {
  device: null,
  services: environment.services,
  selectedLanguage: null,
  supportedLanguages: Constant.supportedLanguages,
  countries: [],
  countriesLoading: false,
  countriesLoaded: false,
  countriesError: null,

  country: null,
  countryCode: null,

  stripeLoading: false,
  stripeLoaded: false,
  stripeLoadingError: null,

  region: null,
  error: null,
  loadServicesError: null,

  updateRunning: false,
  updateRolesAndPoliciesSuccess: false,
  pendingUpdates: [],
  remotePolicies: null,

  players: playersInitialState,

  // CGU
  cgu: {
    cguLastVersion: 0,
    cguLastConsentUpdated: null,
    cguLastConsentValue: false,
  },

  // Cookies
  cookiesConsentConfiguration: {
    groups: {
      marketing: {
        enabled: false,
      },
      social: {
        enabled: false,
      },
      analytics: {
        enabled: false,
      },
    }
  },
  cookiesConsentConfigurationLoaded: false,
  cookiesConsentSetByUser: false,

  // plan duration choice from squareSpace
  durationChoice: null,
  onBoarding: {
    discoverProModal: 'firstVisit',
    counter: 0,
  },
};

const environmentReducer = createReducer(
  initialState,
  on(EnvironmentActions.loadDeviceSuccess, (state, { device }) => ({
    ...state,
    device,
    error: initialState.error,
  })),
  on(EnvironmentActions.loadDeviceFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(EnvironmentActions.loadServicesSuccess, (state, { region, country, countryCode }) => ({
    ...state,
    region,
    country,
    countryCode,
    loadServicesError: initialState.loadServicesError,
  })),
  on(EnvironmentActions.loadServicesFailure, (state, { error }) => ({
    ...state,
    loadServicesError: error
  })),
  on(SigninActions.signinSuccess, (state, { region }) => ({
    ...state,
    region,
  })),
  on(EnvironmentActions.SelectLanguageSuccess, (state, { language }) => ({
    ...state,
    selectedLanguage: language
  })),
  on(EnvironmentActions.SetupLanguageSuccess, (state, { language }) => ({
    ...state,
    selectedLanguage: language
  })),
  on(EnvironmentActions.loadCountries, (state) => ({
    ...state,
    countries: initialState.countries,
    countriesLoading: true,
    countriesLoaded: initialState.countriesLoaded,
  })),
  on(EnvironmentActions.loadCountriesSuccess, (state, { countries }) => ({
    ...state,
    countries,
    countriesLoading: false,
    countriesLoaded: true,
  })),
  on(EnvironmentActions.loadCountriesFailure, (state, { error }) => ({
    ...state,
    countriesLoading: false,
    countriesError: error,
  })),
  on(EnvironmentActions.loadCountries, (state) => ({
    ...state,
    countries: initialState.countries,
    countriesLoading: true,
  })),
  on(EnvironmentActions.loadCountriesSuccess, (state, { countries }) => ({
    ...state,
    countries,
    countriesLoading: false,
    countriesLoaded: true,
  })),
  on(EnvironmentActions.loadCountriesFailure, (state, { error }) => ({
    ...state,
    countriesLoading: false,
    countriesError: error,
  })),

  on(EnvironmentActions.LoadStripe, (state) => ({
    ...state,
    stripeLoading: true,
    stripeLoaded: initialState.stripeLoaded,
    stripeLoadingError: initialState.stripeLoadingError,
  })),
  on(EnvironmentActions.LoadStripeSuccess, (state) => ({
    ...state,
    stripeLoading: false,
    stripeLoaded: true,
  })),
  on(EnvironmentActions.LoadStripeFailure, (state, { error }) => ({
    ...state,
    stripeLoading: false,
    stripeLoadingError: error,
  })),

  // Reset ConfigUpdateError
  on(EnvironmentActions.ScanUpdates, (state) => ({
    ...state,
    pendingUpdates: initialState.pendingUpdates,
  })),
  on(EnvironmentActions.RunUpdates, (state) => ({
    ...state,
    updateRunning: true,
    updateRolesAndPoliciesSuccess: false,
  })),
  on(EnvironmentActions.RunUpdatesSuccess, (state) => ({
    ...state,
    updateRunning: false,
    updateRolesAndPoliciesSuccess: true,
  })),
  on(EnvironmentActions.RunUpdatesFailure, (state) => ({
    ...state,
    updateRunning: false,
  })),
  on(EnvironmentActions.GetRemotePoliciesAndRolesSuccess, (state, { remotePolicies }) => ({
    ...state,
    remotePolicies,
  })),
  on(EnvironmentActions.ComputePoliciesSuccess, (state, { update }) => ({
    ...state,
    pendingUpdates: update ? [...state.pendingUpdates, update] : [...state.pendingUpdates],
  })),
  on(EnvironmentActions.ExecutePoliciesUpdateSuccess, (state, { update }) => ({
    ...state,
    pendingUpdates: [...state.pendingUpdates].filter(u => u.type !== update.type),
  })),

  // Players
  on(EnvironmentActions.AddPlayer, (state, { id, url, allowConcurrency, unstoppable }) => ({
    ...state,
    players: adapterPlayers.addOne({ id, url, allowConcurrency, unstoppable, playing: false }, state.players),
  })),
  on(EnvironmentActions.UpdatePlayerPlayingAndStopOthers, (state, { id }) => {
    const ids = [...state.players.ids];
    const playersToStop = state.players.entities[id]?.allowConcurrency ? [] : ids.filter((_id: string) => _id !== id && !state.players.entities[_id].allowConcurrency);
    const updates = [];
    playersToStop.forEach(player => {
      updates.push({ id: player, changes: { playing: false } });
    });
    return {
      ...state,
      players: adapterPlayers.updateMany(updates, state.players),
    };
  }),
  on(EnvironmentActions.StopAllPlayers, (state) => {
    const playersToStop = [...state.players.ids].filter((_id: string) => !state.players.entities[_id].unstoppable);
    const updates = [];

    playersToStop.forEach(id => {
      updates.push({ id, changes: { playing: false } });
    });
    return {
      ...state,
      players: adapterPlayers.updateMany(updates, state.players),
    };
  }),

  // CGU
  on(EnvironmentActions.AcceptCGUSuccess, (state, { cgu }) => ({
    ...state,
    cgu,
  })),
  // Cookies
  on(EnvironmentActions.LoadCookiesConsentConfiguration, (state) => ({
    ...state,
    cookiesConsentConfiguration: initialState.cookiesConsentConfiguration,
    cookiesConsentConfigurationLoaded: initialState.cookiesConsentConfigurationLoaded,
  })),
  on(EnvironmentActions.LoadCookiesConsentConfigurationSuccess, (state, { cookiesConsentConfiguration, cookiesConsentSetByUser }) => ({
    ...state,
    cookiesConsentConfiguration,
    cookiesConsentConfigurationLoaded: true,
    cookiesConsentSetByUser,
  })),
  on(EnvironmentActions.AcceptAllCookiesSuccess, (state) => ({
    ...state,
    cookiesConsentConfiguration: {
      ...state.cookiesConsentConfiguration,
      groups: {
        marketing: {
          enabled: true,
        },
        social: {
          enabled: true,
        },
        analytics: {
          enabled: true,
        },
      }
    },
    cookiesConsentSetByUser: true,
  })),
  on(EnvironmentActions.DenyAllCookiesSuccess, (state) => ({
    ...state,
    cookiesConsentConfiguration: {
      ...state.cookiesConsentConfiguration,
      groups: {
        marketing: {
          enabled: false,
        },
        social: {
          enabled: false,
        },
        analytics: {
          enabled: false,
        },
      }
    },
    cookiesConsentSetByUser: true,
  })),
  on(EnvironmentActions.UpdateCookiesConsentConfigurationSuccess, (state, { cookiesConsentConfiguration }) => ({
    ...state,
    cookiesConsentConfiguration,
    cookiesConsentSetByUser: true,
  })),
  on(EnvironmentActions.CloseCookiesConsentConfiguration, (state) => ({
    ...state,
    cookiesConsentConfigurationLoaded: false,
  })),

  on(EnvironmentActions.SetDurationChoice, (state, { durationChoice }) => ({
    ...state,
    durationChoice
  })),

  on(EnvironmentActions.OnBoardingSuccess, (state, { onBoardingState }) => ({
    ...state,
    onBoarding: { ...state.onBoarding, ...onBoardingState, counter: state.onBoarding.counter + onBoardingState.counter }
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return environmentReducer(state, action);
}

export const getPlayersSelectors = adapterPlayers.getSelectors();
export const getPlayers = getPlayersSelectors.selectAll;


