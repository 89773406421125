import { config, Region } from '@smash-sdk/core';
import { Constant } from 'src/constant';
import { Service } from '../models/service';

export function setConfigHosts(services: Service[]) {    
    const serviceKeys = ['link', 'vat', 'billing', 'directory', 'domain', 'iam', 'transfer', 'scitylana', 'customization', 'promotion', 'kyc', 'theme', 'image'];
    const globalDic = {} as { [key: string]: { [key: string]: string } };
    services.forEach((service) => {
        if (serviceKeys.includes(service.name)) {
            if (!globalDic[service.name]) {
                globalDic[service.name] = {};
            }
            globalDic[service.name] = { ...globalDic[service.name], [getSdkRegion(service.region, service.url)]: service.url };
        }
    });
    for (const key in globalDic) {
        if (globalDic[key]) {
            config.setHosts(key, globalDic[key]);
        }
    }
}

export function setRegion(region: Region) {
    config.setRegion(region);
}

export function getSdkRegion(region: string, url: string) { // FIX ME Get it from smash-discovery
    if (url.indexOf(region) >= 0) {
        return region;
    } else {
        return Constant.globalRegion;
    }
}
