import { Pipe, PipeTransform } from '@angular/core';
import { applyPrecision, isNumberFinite, isUndefined } from '../helpers/helpers';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'bytes' })
export class BytesPipe implements PipeTransform {

  constructor(public translateService: TranslateService) { }

  private dictionary: Array<{ max: number; type: string }> = [
    { max: 1000, type: 'UNIT_B' },
    { max: 1000000, type: 'UNIT_KB' },
    { max: 1000000000, type: 'UNIT_MB' },
    { max: 1000000000000, type: 'UNIT_GB' },
    { max: 1000000000000000, type: 'UNIT_TB' },
    { max: Infinity, type: 'UNIT_TB' }, // TO FIX don't know what to set here
  ];

  transform(value: number, precision?: number | undefined): string | number {
    if (!isNumberFinite(value)) {
      return null;
    }

    const format = this.dictionary.find(d => value < d.max) || this.dictionary[this.dictionary.length - 1];
    const calc = value / (format.max / 1000);
    const num = isUndefined(precision) ? calc : applyPrecision(calc, precision);
    this.translateService.get(format.type).subscribe((res: string) => {
      format.type = res;
    });

    return `${num} ${format.type}`;
  }
}
