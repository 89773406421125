import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StripeJsPayload } from '@app/shared/models/stripeJsPayload';
import { environment } from 'src/environments/environment';

declare var Stripe: any;
@Injectable({
  providedIn: 'root'
})
export class PaymentStripeService {

  headers = new HttpHeaders().set('Authorization', 'true');

  async stripeHandleCardPayment(data: StripeJsPayload): Promise<any> {
    const stripe = Stripe(environment.stripeKey);
    const result = await stripe.confirmCardPayment(data.secret,
      {
        payment_method: { card: { token: data.token } }
      });
    return result;
  }

  async stripeConfirmSetupIntent(data: StripeJsPayload): Promise<any> {
    const stripe = Stripe(environment.stripeKey);
    const result = await stripe.confirmCardSetup(data.secret, {
      payment_method: { card: { token: data.token } }
    });
    return result;
  }

}
