import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const processPayment = createAction(
  '[Payment] Process Payment'
);

export const processPaymentSuccess = createAction(
  '[Payment] Process Payment Success'
);

// Stripe
export const processStripePayment = createAction(
  '[Payment] Process Stripe Payment'
);


export const processStripePaymentCheck = createAction(
  '[Payment] Check Stripe Payment',
  props<{ paymentIntent: any }>()
);

export const processStripePaymentSuccess = createAction(
  '[Payment] Process Stripe Payment Success',
  props<{ response: any }>()
);

export const processStripePaymentFailure = createAction(
  '[Payment] Process Stripe Payment Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetSignupProcess = createAction(
  '[Payment] Reset Signup Process',
);

export const resetSignupProcessSuccess = createAction(
  '[Payment] Reset Signup Process Success',
);

// Paypal
export const processPaypalPayment = createAction(
  '[Payment] Process Paypal Payment'
);

export const processPaypalPaymentSuccess = createAction(
  '[Payment] Process Paypal Payment Success',
  props<{ response: any }>()
);

export const processPaypalPaymentFailure = createAction(
  '[Payment] Process Paypal Payment Failure',
  props<{ error: HttpErrorResponse }>()
);

export const paypalWaitUserAgreement = createAction(
  '[Payment] Paypal Wait User Agreement confirmation',
  props<{ approvalUrl: any }>()
);

export const paypalWaitPaymentSuccess = createAction(
  '[Payment] Paypal Wait uiser payment success',
);

export const executePaypal = createAction(
  '[Payment] Execute Paypal agreeement',
  props<{ token: any }>()
);

export const cancelPaypal = createAction(
  '[Payment] Paypal Cancel process',
);
