import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Background } from '@app/shared/models/background';
import { forkJoin, Observable, of } from 'rxjs';
import { Customization } from '../../shared/models/customization';
import { Logo } from '../../shared/models/logo';

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {

  constructor(private http: HttpClient) { }

  headers = new HttpHeaders().set('Authorization', 'true');

  // Team
  getTeamCustomization(team: string, customizationId: string): Observable<{ customization: Customization }> {
    return this.http.get<{ customization: Customization }>('service:customization' + '/team/' + team + '/customization/' + customizationId, { headers: this.headers });
  }

  listTeamCustomizations(team: string): Observable<{ customizations: Customization[] }> {
    return this.http.get<{ customizations: Customization[] }>('service:customization' + '/team/' + team + '/customizations', { headers: this.headers });
  }

  createTeamCustomization(team: string, name?: string, description?: string): Observable<{ logo: Logo }> {
    return this.http.post<{ logo: Logo }>('service:customization' + '/team/' + team + '/customization', { name, description }, { headers: this.headers });
  }

  listTeamBackgrounds(team: string): Observable<{ backgrounds: Background[] }> {
    return this.http.get<{ backgrounds: Background[] }>('service:customization' + '/team/' + team + '/backgrounds', { headers: this.headers });
  }

  listTeamLogos(team: string): Observable<{ logos: Logo[] }> {
    return this.http.get<{ logos: Logo[] }>('service:customization' + '/team/' + team + '/logos', { headers: this.headers });
  }

  addTeamBackground(url: string, team: string): Observable<Background> {
    return this.http.post<Background>('service:customization' + '/team/' + team + '/background', { background: url }, { headers: this.headers });
  }

  addTeamLogo(url: string, team: string): Observable<{ logo: Logo }> {
    return this.http.post<{ logo: Logo }>('service:customization' + '/team/' + team + '/logo', { logo: url }, { headers: this.headers });
  }

  deleteTeamBackground(id: string, team: string): Observable<{}> {
    return this.http.delete<{}>('service:customization' + '/team/' + team + '/background/' + id, { headers: this.headers });
  }

  deleteTeamLogo(id: string, team: string): Observable<{}> {
    return this.http.delete<{}>('service:customization' + '/team/' + team + '/logo/' + id, { headers: this.headers });
  }

  addTeamBackgrounds(urls: string[], team: string): Observable<any> {
    const reqs = [];
    if (urls && urls.length) {
      urls.forEach(url => {
        reqs.push(this.addTeamBackground(url, team));
      });
      return forkJoin(reqs);
    } else { return of([]) };
  }

  addTeamLogos(urls: string[], team: string): Observable<any> {
    const reqs = [];
    if (urls && urls.length) {
      urls.forEach(url => {
        reqs.push(this.addTeamLogo(url, team));
      });
      return forkJoin(reqs);
    } else { return of([]) };
  }

  deleteTeamBackgrounds(ids: string[], team: string): Observable<any> {
    const reqs = [];
    if (ids && ids.length) {
      ids.forEach(id => {
        reqs.push(this.deleteTeamBackground(id, team));
      });
      return forkJoin(reqs);
    } else { return of([]) };
  }

  deleteTeamLogos(ids: string[], team: string): Observable<any> {
    const reqs = [];
    if (ids && ids.length) {
      ids.forEach(id => {
        reqs.push(this.deleteTeamLogo(id, team));
      });
      return forkJoin(reqs);
    } else { return of([]) };
  }

  attachTeamCustomizationBackground(teamId: string, customizationId: string, backgroundId: string): Observable<{}> {
    return this.http.put<{}>('service:customization' + '/team/' + teamId + '/customization/' + customizationId + '/background/' + backgroundId + '/attach', {}, { headers: this.headers });
  }

  attachTeamCustomizationLogo(teamId: string, customizationId: string, logoId: string): Observable<{}> {
    return this.http.put<{}>('service:customization' + '/team/' + teamId + '/customization/' + customizationId + '/logo/' + logoId + '/attach', {}, { headers: this.headers });
  }

  getManagedCustomization(id: string): Observable<{ customization: Customization }> {
    return this.http.get<{ customization: Customization }>('service:customization' + '/managedCustomization/' + id, { headers: this.headers });
  }

  listManagedBackgrounds(): Observable<{ backgrounds: Background[] }> {
    return this.http.get<{ backgrounds: Background[] }>('service:customization' + '/managedBackgrounds', { headers: this.headers });
  }

  listManagedLogos(): Observable<{ logos: Logo[] }> {
    return this.http.get<{ logos: Logo[] }>('service:customization' + '/managedLogos', { headers: this.headers });
  }

  listManagedCustomizations(): Observable<{ customizations: Customization[] }> {
    return this.http.get<{ customizations: Customization[] }>('service:customization' + '/managedCustomizations', { headers: this.headers });
  }

}
