import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Group } from '@app/shared/models/group';
import { Identity } from '@app/shared/models/identity';
import { Provider } from '@app/shared/models/provider';
import { Role } from '@app/shared/models/role';
import { Team } from '@app/shared/models/team';
import { User, UserStatus } from '@app/shared/models/user';
import { retryOn500 } from '@app/shared/operators/retryOn500';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, delay, map, mergeMap, retryWhen, take } from 'rxjs/operators';
import { Constant } from 'src/constant';
import { Roles } from '../accessManagement/config/roles';
import { Policy } from '../accessManagement/policies';
import { TeamRole } from '../accessManagement/roles';
import { TokenService } from './token.service';
import { Account } from '@app/shared/models/account';
import { buildGenericTags } from '@app/shared/helpers/tags';

const iamApiVersion072020 = '07-2020';
const iamApiVersion072021 = '07-2021';
const iamApiVersion112023 = '11-2023';
const SCIMTokenDuration = '100y';
const SCIMGroupName = 'SCIM';
@Injectable({
  providedIn: 'root'
})
export class IamService {

  headers = new HttpHeaders().set('Authorization', 'true');

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  createToken(username: string, password: string): Observable<{ identity: Identity }> {
    return this.http.post<{ identity: Identity }>('service:iam' + '/token', { username, password });
  }

  createUserToken(
    id: string,
    data: {
      tokenDuration?: string,
      refreshTokenDuration?: string,
    }
  ): Observable<{ user: User }> {
    return this.http.post<{ user: User }>('service:iam' + '/user/' + id + '/token', data, { headers: this.headers });
  }

  renewToken(
    data: { refreshToken: string }
  ): Observable<{ identity: Identity }> {
    return this.http.put<{ identity: Identity }>('service:iam' + '/token', data);
  }

  createAccount(
    data: {
      username?: string,
      password?: string,
      lastName?: string,
      firstName?: string,
      language?: string
    } = {}
  ): Observable<{ account: Identity }> {
    return this.http.post<{ account: Identity }>('service:iam' + '/account', data);
  }

  getAccount(): Observable<{ account: Account }> {
    return this.http.get<{ account: Account }>('service:iam' + '/account', { headers: this.headers });
  }

  updateAccount(
    data: {
      username?: string,
      password?: string,
      lastName?: string,
      firstName?: string,
      language?: string
    } = {}
  ): Observable<{ account: Account }> {
    return this.http.put<{ account: Account }>('service:iam' + '/account', data, { headers: this.headers });
  }

  updatePassword(data: {
    currentPassword: string,
    newPassword?: string,
  }
  ): Observable<{ identity: Identity }> {
    return this.http.put<{ identity: Identity }>('service:iam' + '/password/update' + '?version=' + iamApiVersion072021, data, { headers: this.headers });
  }

  deleteAccount(id: string): Observable<Identity> {
    return this.http.delete<Identity>('service:iam' + '/account/' + id, { headers: this.headers });
  }

  listTeams(): Observable<{ teams: Team[] }> {
    return this.http.get<{ teams: Team[] }>('service:iam' + '/teams', { headers: this.headers });
  }

  listUserTeams(id: string): Observable<{ teams: Team[] }> {
    return this.http.get<{ teams: Team[] }>('service:iam' + '/user/' + id + '/teams' + '?version=' + iamApiVersion072020, { headers: this.headers });
  }

  listUserGroupsTeams(id: string): Observable<{ teams: Team[] }> {
    return this.http.get<{ teams: Team[] }>('service:iam' + '/user/' + id + '/groups/teams' + '?version=' + iamApiVersion072020, { headers: this.headers });
  }

  updateTeam(
    id: string,
    data: {
      name?: string,
      description?: string,
      config?: {
        authentication?: {
          mode: 'Anonymous' | 'Authentified',
          rules?: { type: 'Wildcard' | 'Email', value: string },
        }
      }
    }
  ): Observable<{ team: Team }> {
    return this.http.put<{ team: Team }>('service:iam' + '/team/' + id, data, { headers: this.headers });
  }

  findTeam(id: string): Observable<{ team: Team }> {
    return this.http.get<{ team: Team }>('service:iam' + '/team/' + id, { headers: this.headers });
  }

  getUser(id: string): Observable<{ user: User }> {
    return this.http.get<{ user: User }>('service:iam' + '/user/' + id, { headers: this.headers });
  }

  deleteTeam(id: string): Observable<{}> {
    return this.http.delete<{}>('service:iam' + '/team/' + id, { headers: this.headers });
  }

  createUser(
    data: {
      username?: string,
      password?: string,
      lastName?: string,
      firstName?: string,
      language?: string,
      status?: 'Enabled' | 'Disabled',
      authenticationMode?: 'Standard' | 'SSO',
    }
  ): Observable<{ user: User }> {
    return this.http.post<{ user: User }>('service:iam' + '/user', data, { headers: this.headers });
  }

  updateUser(
    id: string,
    data: {
      username?: string,
      lastName?: string,
      firstName?: string,
      language?: string,
      status?: 'Enabled' | 'Disabled',
      authenticationMode?: 'Standard' | 'SSO',
    }
  ): Observable<{ user: User }> {
    return this.http.put<{ user: User }>('service:iam' + '/user/' + id, data, { headers: this.headers });
  }

  updateTeamUser(
    teamId: string,
    userId: string,
    data: {
      username?: string,
      lastName?: string,
      firstName?: string,
      language?: string,
      status?: 'Enabled' | 'Disabled',
      authenticationMode?: 'Standard' | 'SSO',
    }
  ): Observable<{ user: User }> {
    return this.http.put<{ user: User }>('service:iam' + '/team/' + teamId + '/user/' + userId + '?version=' + iamApiVersion072021, data, { headers: this.headers });
  }

  createRole(data: {
    name: string,
    description?: string,
    tags: { [key: string]: unknown },
  }): Observable<{ role: Role }> {
    return this.http.post<{ role: Role }>('service:iam' + '/role', { name: data.name, description: data.description }, { headers: this.headers });
  }

  updateRole(data: {
    name: string,
    description?: string,
    tags: { [key: string]: unknown },
  }): Observable<{ role: Role }> {
    return this.http.put<{ role: Role }>('service:iam' + '/role/' + data.name, { description: data.description, tags: data.tags }, { headers: this.headers });
  }

  deleteRole(name: string): Observable<{ role: Role }> {
    return this.http.delete<{ role: Role }>('service:iam' + '/role/' + name, { headers: this.headers });
  }

  getRole(name: string): Observable<{ role: Role }> {
    return this.http.get<{ role: Role }>('service:iam' + '/role/' + name, { headers: this.headers });
  }

  listRoles(): Observable<{ roles: Role[] }> {
    return this.http.get<{ roles: Role[] }>('service:iam' + '/roles', { headers: this.headers });
  }

  listTeamRoles(teamId: string): Observable<{ roles: Role[] }> {
    return this.http.get<{ roles: Role[] }>('service:iam' + '/team/' + teamId + '/roles' + '?version=' + iamApiVersion072021, { headers: this.headers });
  }

  listUserRoles(id: string): Observable<{ roles: Role[] }> {
    return this.http.get<{ roles: Role[] }>('service:iam' + '/user/' + id + '/roles', { headers: this.headers });
  }

  listTeamUserRoles(teamId: string, userId: string): Observable<{ roles: Role[] }> {
    return this.http.get<{ roles: Role[] }>('service:iam' + '/team/' + teamId + '/user/' + userId + '/roles' + '?version=' + iamApiVersion072021, { headers: this.headers });
  }

  listTeamGroupUsers(teamId: string, groupId: string): Observable<any> {
    return this.http.get<any>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/users' + '?version=' + iamApiVersion072021, { headers: this.headers });
  }

  listUserGroups(id: string): Observable<any> {
    return this.http.get<any>('service:iam' + '/user/' + id + '/groups', { headers: this.headers });
  }

  listGroupRoles(id: string): Observable<{ roles: Role[] }> {
    return this.http.get<{ roles: Role[] }>('service:iam' + '/group/' + id + '/roles', { headers: this.headers });
  }

  listTeamGroupRoles(teamId: string, groupId: string): Observable<{ roles: Role[] }> {
    return this.http.get<{ roles: Role[] }>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/roles' + '?version=' + iamApiVersion072021, { headers: this.headers });
  }

  listUserGroupsRoles(id: string): Observable<{ roles: Role[] }> {
    return this.http.get<{ roles: Role[] }>('service:iam' + '/user/' + id + '/groups/roles' + '?version=' + iamApiVersion072020, { headers: this.headers });
  }

  createPolicy(
    data: {
      name: string,
      description?: string,
      statement: { action: string[], resource: string[], effect: 'Allow' | 'Deny' }[],
      tags: { [key: string]: unknown },
    }
  ): Observable<{ policy: Policy }> {
    return this.http.post<{ policy: Policy }>('service:iam' + '/policy', data, { headers: this.headers }).pipe(
      retryWhen(error =>
        error.pipe(
          take(10),
          delay(2000)
        )
      )
    );
  }

  deletePolicy(name: string): Observable<{}> {
    return this.http.delete<{}>('service:iam' + '/policy/' + name, { headers: this.headers });
  }

  getPolicy(name: string): Observable<{ policy: Policy }> {
    return this.http.get<{ policy: Policy }>('service:iam' + '/policy/' + name, { headers: this.headers });
  }

  listPolicies(): Observable<{ policies: Policy[] }> {
    return this.http.get<{ policies: Policy[] }>('service:iam' + '/policies', { headers: this.headers });
  }

  updatePolicy(
    name: string,
    data: {
      description?: string,
      statement: { action: string[], resource: string[], effect: 'Allow' | 'Deny' }[],
      tags: { [key: string]: unknown },
    }
  ): Observable<{ policy: Policy }> {
    return this.http.put<{ policy: Policy }>('service:iam' + '/policy/' + name, data, { headers: this.headers }).pipe(
      retryWhen(error => error.pipe(take(10), delay(2000)))
    );
  }

  detachRolePolicy(roleName: string, policyName: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/role/' + roleName + '/policy/' + policyName + '/detach', null, { headers: this.headers });
  }

  attachRolePolicy(roleName: string, policyName: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/role/' + roleName + '/policy/' + policyName + '/attach', null, { headers: this.headers });
  }

  attachTeamRole(teamId: string, roleName: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/role/' + roleName + '/attach' + '?version=' + iamApiVersion072021, null, { headers: this.headers });
  }

  detachTeamRole(teamId: string, roleName: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/role/' + roleName + '/detach' + '?version=' + iamApiVersion072021, null, { headers: this.headers });
  }

  listGroups(): Observable<{ groups: Group[] }> {
    return this.http.get<{ groups: Group[] }>('service:iam' + '/groups', { headers: this.headers });
  }

  findGroup(id: string): Observable<{ group: Group }> {
    return this.http.get<{ group: Group }>('service:iam' + '/group/' + id, { headers: this.headers });
  }

  findTeamGroup(teamId: string, groupId: string): Observable<{ group: Group }> {
    return this.http.get<{ group: Group }>('service:iam' + '/team/' + teamId + '/group/' + groupId + '?version=' + iamApiVersion072021, { headers: this.headers });
  }

  createGroup(data: { name: string }): Observable<{ group: Group }> {
    return this.http.post<{ group: Group }>('service:iam' + '/group', data, { headers: this.headers });
  }

  updateGroup(id: string,
    data: {
      name: string,
    }
  ): Observable<{ group: Group }> {
    return this.http.put<{ group: Group }>('service:iam' + '/group/' + id, data, { headers: this.headers });
  }

  updateTeamGroup(teamId: string, groupId,
    data: {
      name: string,
    }
  ): Observable<{ group: Group }> {
    return this.http.put<{ group: Group }>('service:iam' + '/team/' + teamId + '/group/' + groupId + '?version=' + iamApiVersion072021, data, { headers: this.headers });
  }

  deleteGroup(name: string): Observable<{}> {
    return this.http.delete<{}>('service:iam' + '/group/' + name, { headers: this.headers });
  }

  listGroupUsers(id: string): Observable<{ users: User[] }> {
    return this.http.get<{ users: User[] }>('service:iam' + '/group/' + id + '/users', { headers: this.headers });
  }

  listGroupTeams(id: string): Observable<{ teams: Team[] }> {
    return this.http.get<{ teams: Team[] }>('service:iam' + '/group/' + id + '/teams', { headers: this.headers });
  }

  attachUserToGroup(groupId: string, userId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/group/' + groupId + '/user/' + userId + '/attach', null, { headers: this.headers });
  }

  attachTeamGroupUser(teamId: string, groupId: string, userId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/user/' + userId + '/attach' + '?version=' + iamApiVersion072021, null, { headers: this.headers });
  }

  attachTeamToGroup(groupId: string, teamId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/attach', null, { headers: this.headers });
  }

  detachTeamFromGroup(groupId: string, teamId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/detach', null, { headers: this.headers });
  }

  detachTeamGroupUser(teamId: string, groupId: string, userId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/user/' + userId + '/detach' + '?version=' + iamApiVersion072021, null, { headers: this.headers });
  }

  detachUserFromGroup(groupId: string, userId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/group/' + groupId + '/user/' + userId + '/detach', null, { headers: this.headers });
  }

  attachRoleToGroup(groupId: string, roleName: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/group/' + groupId + '/role/' + roleName + '/attach', null, { headers: this.headers });
  }

  attachTeamGroupRole(teamId: string, groupId: string, roleName: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/role/' + roleName + '/attach' + '?version=' + iamApiVersion072021, null, { headers: this.headers });
  }

  detachTeamGroupRole(teamId: string, groupId: string, roleName: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/role/' + roleName + '/detach' + '?version=' + iamApiVersion072021, null, { headers: this.headers });
  }

  attachRoleToUser(id, role): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/user/' + id + '/role/' + role + '/attach', {}, { headers: this.headers });
  }

  detachRoleToUser(id, role): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/user/' + id + '/role/' + role + '/detach', {}, { headers: this.headers });
  }

  detachRoleFromGroup(groupId: string, roleName: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/group/' + groupId + '/role/' + roleName + '/detach', null, { headers: this.headers });
  }

  acceptInvitation(
    data: {
      password: string,
      secret: string,
      account: string,
      user: string,
    }
  ): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/invitation/accept' + '?version=' + iamApiVersion072021, data, { headers: this.headers });
  }

  listTeamUsers(id: string): Observable<{ users: User[] }> {
    return this.http.get<{ users: User[] }>('service:iam' + '/team/' + id + '/users' + '?version=' + iamApiVersion072021, { headers: this.headers });
  }

  listTeamGroups(id: string): Observable<{ groups: Group[] }> {
    return this.http.get<{ groups: Group[] }>('service:iam' + '/team/' + id + '/groups', { headers: this.headers });
  }

  listTeamUserGroups(teamId: string, userId: string): Observable<{ groups: Group[] }> {
    return this.http.get<{ groups: Group[] }>('service:iam' + '/team/' + teamId + '/user/' + userId + '/groups' + '?version=' + iamApiVersion072021, { headers: this.headers });
  }

  detachUserFromTeam(teamId: string, userId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/user/' + userId + '/detach', null, { headers: this.headers });
  }

  detachGroupFromTeam(teamId: string, groupId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/group/' + groupId + '/detach', null, { headers: this.headers });
  }

  getProviders(): Observable<{ providers: Provider[] }> {
    return this.http.get<{ providers: Provider[] }>('service:iam' + '/providers' + '?version=' + iamApiVersion112023, { headers: this.headers });
  }

  getProvider(name: string): Observable<{ provider: Provider }> {
    return this.http.get<{ provider: Provider }>('service:iam' + '/provider/' + name, { headers: this.headers });
  }

  postProvider(
    data: {
      issuer: string,
      name: string,
      type: 'Saml' | 'OpenId',
      idpUrl: string,
      certificate: string,
      status?: 'Enabled' | 'Disabled',
      audienceUris: string[]
    }
  ): Observable<{ provider: Provider }> {
    return this.http.post<{ provider: Provider }>('service:iam' + '/provider' + '?version=' + iamApiVersion112023, data, { headers: this.headers });
  }

  putProvider(
    name: string,
    data: {
      name?: string,
      idpUrl: string,
      certificate: string,
      status?: 'Enabled' | 'Disabled',
    }
  ): Observable<{ provider: Provider }> {
    return this.http.put<{ provider: Provider }>('service:iam' + '/provider/' + name, data, { headers: this.headers });
  }

  deleteProvider(name: string): Observable<{}> {
    return this.http.delete<{}>('service:iam' + '/provider/' + name, { headers: this.headers });
  }

  listUsers(): Observable<{ users: User[] }> {
    return this.http.get<{ users: User[] }>('service:iam' + '/users', { headers: this.headers });
  }

  getTeamUser(teamId: string, userId: string): Observable<{ user: User }> {
    return this.http.get<{ user: User }>('service:iam' + '/team/' + teamId + '/user/' + userId + '?version=' + iamApiVersion072021, { headers: this.headers });
  }

  deleteUser(id: string): Observable<{}> {
    return this.http.delete<{}>('service:iam' + '/user/' + id, { headers: this.headers });
  }

  attachUserRole(id: string, role: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/user/' + id + '/role/' + role + '/attach', {}, { headers: this.headers });
  }

  detachUserRole(id: string, role: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/user/' + id + '/role/' + role + '/detach', {}, { headers: this.headers });
  }

  attachUserToTeam(team: string, user: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + team + '/user/' + user + '/attach', {}, { headers: this.headers });
  }

  attachTeamUserRole(team: string, user: string, role: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + team + '/user/' + user + '/role/' + role + '/attach' + '?version=' + iamApiVersion072021, {}, { headers: this.headers });
  }

  detachTeamUserRole(team: string, user: string, role: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + team + '/user/' + user + '/role/' + role + '/detach' + '?version=' + iamApiVersion072021, {}, { headers: this.headers });
  }

  inviteUser(id: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/user/' + id + '/invite', {}, { headers: this.headers });
  }

  inviteTeamUser(teamId: string, userId: string): Observable<{}> {
    return this.http.put<{}>('service:iam' + '/team/' + teamId + '/user/' + userId + '/invite' + '?version=' + iamApiVersion072021, {}, { headers: this.headers });
  }

  listHydratedRoles(): Observable<any> {
    return this.listRoles().pipe(concatMap(({ roles }) => {
      return forkJoin(roles.map(r => this.getRole(r.name).pipe(map(({ role }) => role)))).pipe(defaultIfEmpty([]));
    }));
  }

  listUserRolesAndTeams(identity: Identity): Observable<{ roles: Role[], teams: Team[] }> {
    const isRoot = this.tokenService.isRoot(identity.token.token);
    if (isRoot) {
      return this.listTeams().pipe(map(({ teams }) => {
        teams.map(team => ({ ...team, userRoles: [{ name: Constant.administratorRoleName }] }));
        return { roles: [], teams };
      }));
    } else {
      return this.listUserRoles(identity.id).pipe(mergeMap(({ roles: directRoles }) => {
        const administratorRole = directRoles.find(role => role.name === Constant.administratorRoleName);
        if (!!administratorRole) {
          return this.listTeams().pipe(map(({ teams }) => {
            teams.map(team => ({ ...team, userRoles: [administratorRole] }));
            return { roles: directRoles, teams };
          }));
        } else {
          return forkJoin([
            this.listUserTeams(identity.id),
            this.listUserGroupsRoles(identity.id),
            this.listUserGroupsTeams(identity.id),
          ]).pipe(map(([{ teams: directTeams }, { roles: groupRoles }, { teams: groupTeams }]) => {
            const distinctTeamsId = new Set();
            const teams = [...directTeams, ...groupTeams];
            const roles = [...directRoles, ...groupRoles];
            // hydrate teams with role 
            // AND deduplicate teams (user can be linked to a team directly and via a group team)
            teams.forEach((team, index) => {
              if (distinctTeamsId.has(team.id)) {
                teams.splice(index);
              } else {
                distinctTeamsId.add(team.id);
                team.userRoles = roles.filter(role => role.name.split('$')[0] === team.id);
              }
            });
            return { roles, teams };
          }));
        }
      }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403) {
            return of({ roles: [], teams: [] })
          }
        })
      );
    }
  }

  getSCIMGroup(): Observable<any> {
    return this.listGroups().pipe(
      retryOn500(),
      concatMap(({ groups }) => of(groups.find(group => group.name === SCIMGroupName))),
      catchError((error: HttpErrorResponse) => error.status === 404 ? of(null) : of(error)),
    );
  }

  getSCIMUser(identity: Identity): Observable<any> {
    return this.listGroups().pipe(
      retryOn500(),
      concatMap(({ groups }) => {
        const SCIMGroup = groups.find(group => group.name === SCIMGroupName);
        const SCIMUsername = this.tokenService.getAccountFromToken(identity.token.token) + '$scim';
        if (SCIMGroup) {
          return this.listGroupUsers(SCIMGroup.id).pipe(concatMap(({ users }) => of(users.find(user => user.username === SCIMUsername))));
        } else {
          return of(null);
        }
      })
    );
  }

  getProvisioningStatus(identity: Identity): Observable<boolean> {
    const SCIMUsername = this.tokenService.getAccountFromToken(identity.token.token) + '$scim';
    return this.getSCIMGroup().pipe(concatMap((SCIMGroup) => {
      if (SCIMGroup) {
        return this.listGroupUsers(SCIMGroup.id).pipe(
          retryOn500(),
          concatMap(({ users }) => {
            const SCIMUser = users.find(user => user.username === SCIMUsername);
            return of(SCIMUser?.status === Constant.userStatus.enabled);
          }),
          catchError(() => of(false)));
      } else {
        return of(false);
      }
    }));
  }

  enableProvisioning(identity: Identity): Observable<{ user: User }> {
    return this.getSCIMGroup().pipe(concatMap((SCIMGroup) => {
      const SCIMUsername = this.tokenService.getAccountFromToken(identity.token.token) + '$scim';
      if (SCIMGroup) {
        return forkJoin([
          this.listGroupUsers(SCIMGroup.id).pipe(concatMap(({ users }) => of(users.find(user => user.username === SCIMUsername)))),
          this.listGroupRoles(SCIMGroup.id).pipe(concatMap(({ roles }) => of(roles.find(role => role.name === Constant.SCIMRoleName)))),
        ]).pipe(
          retryOn500(),
          concatMap(([SCIMUser, SCIMGroupRole]) => {
            if (SCIMGroupRole) {
              if (SCIMUser) {
                if (SCIMUser.status === Constant.userStatus.disabled) {
                  return this.updateUser(SCIMUser.id, { ...SCIMUser, status: Constant.userStatus.enabled as UserStatus });
                } else {
                  return of({ user: SCIMUser });
                }
              } else {
                return this.createUser({ username: SCIMUsername }).pipe(concatMap(({ user }) => {
                  return forkJoin([
                    this.attachUserToGroup(SCIMGroup.id, user.id),
                    this.updateUser(user.id, { ...user, status: Constant.userStatus.enabled as UserStatus }),
                  ]).pipe(concatMap(([resAttach, { user }]) => of({ user })));
                }));
              }
            } else {
              return this.attachRoleToGroup(SCIMGroup.id, Constant.SCIMRoleName).pipe(concatMap(() => {
                if (SCIMUser) {
                  if (SCIMUser.status === Constant.userStatus.disabled) {
                    return this.updateUser(SCIMUser.id, { ...SCIMUser, status: Constant.userStatus.enabled as UserStatus });
                  } else {
                    return of({ user: SCIMUser });
                  }
                } else {
                  return this.createUser({ username: SCIMUsername }).pipe(concatMap(({ user }) => {
                    return forkJoin([
                      this.attachUserToGroup(SCIMGroup.id, user.id),
                      this.updateUser(SCIMUser.id, { ...user, status: Constant.userStatus.enabled as UserStatus }),
                    ]).pipe(concatMap(([resAttach, { user }]) => of({ user })));
                  }));
                }
              }));
            }
          }),
          catchError(() => of(null)));
      } else {
        return forkJoin([
          this.createGroup({ name: SCIMGroupName }),
          this.createUser({ username: SCIMUsername })
        ]).pipe(
          concatMap(([{ group }, { user }]: any[]) => {
            return this.attachUserToGroup(group.id, user.id).pipe(concatMap(() => {
              return this.attachRoleToGroup(group.id, Constant.SCIMRoleName).pipe(concatMap(() => {
                return of({ user });
              }));
            }));
          })
        );
      }
    }));
  }

  disableProvisioning(identity: Identity): Observable<{ user: User }> {
    return this.getSCIMUser(identity).pipe(
      retryOn500(),
      concatMap((SCIMUser: User) => {
        if (SCIMUser) {
          if (SCIMUser.status === Constant.userStatus.disabled) {
            return of({ user: SCIMUser });
          } else {
            return this.updateUser(SCIMUser.id, { ...SCIMUser, status: Constant.userStatus.disabled as UserStatus });
          }
        } else {
          return of(null);
        }
      })
    );
  }

  generateSCIMToken(identity: Identity): Observable<User> {
    return this.getSCIMUser(identity).pipe(
      retryOn500(),
      concatMap((SCIMUser: User) => {
        return this.createUserToken(SCIMUser.id, { tokenDuration: SCIMTokenDuration, refreshTokenDuration: SCIMTokenDuration }).pipe(concatMap(({ user }) => {
          return of(user);
        }));
      })
    );
  }


  executePolicyRoleUpdate({ PoliciesToCreate, PoliciesToUpdate, RolesToDelete, RolesToCreate, RolesToUpdate, PoliciesToDelete }) {
    const batchCreateOrUpdatePolicies = [
      ...PoliciesToCreate.map(policy => this.createPolicy(policy)),
      ...PoliciesToUpdate.map(policy => this.updatePolicy(policy.name, policy)),
    ];

    const batchCreateRoles = RolesToCreate.map(roleToCreate => this.createRole(roleToCreate).pipe(concatMap(({ role }) => {
      const teamId = role.name.split('$')[0];
      return this.attachTeamRole(teamId, role.name);
    })));

    const batchAttachOrDetachPolicies = RolesToUpdate.map(role => {
      const reqs = [];
      if (role.policiesToAttach.length) {
        reqs.push(...role.policiesToAttach.map(toAttach => this.attachRolePolicy(role.role.name, toAttach.name)));
      }
      if (role.policiesToDetach.length) {
        reqs.push(...role.policiesToDetach.map(todetach => this.detachRolePolicy(role.role.name, todetach.name)));
      }
      if (role.roleToUpdate) {
        reqs.push(this.updateRole({ name: role.role.name, description: role.roleToUpdate.description, tags: role.roleToUpdate.tags }));
      }
      return forkJoin(reqs);
    });

    const batchDeleteRoles = RolesToDelete.map(roleToRemove => {
      const teamId = roleToRemove.name.split('$')[0];
      return this.listTeamRoles(teamId).pipe(concatMap(({ roles }) => {
        if (roles.find(role => roleToRemove.name === role.name)) {
          return this.detachTeamRole(teamId, roleToRemove.name).pipe(concatMap(() => {
            return this.deleteRole(roleToRemove.name);
          }));
        } else {
          return this.deleteRole(roleToRemove.name);
        }
      }), catchError(error => error.status === 404 ? this.deleteRole(roleToRemove.name) : error));
    });

    const batchDeletePolicies = PoliciesToDelete.map(policy => this.deletePolicy(policy.name));

    return forkJoin(batchCreateOrUpdatePolicies).pipe(defaultIfEmpty([]), concatMap(() => {
      // console.log('Done Create/Update Policies')
      return forkJoin(batchCreateRoles).pipe(defaultIfEmpty([]), concatMap(() => {
        // console.log('Done Create Roles')
        return forkJoin(batchAttachOrDetachPolicies).pipe(defaultIfEmpty([]), concatMap(() => {
          // console.log('Done Attach/Detach Policies')
          return forkJoin(batchDeleteRoles).pipe(defaultIfEmpty([]), concatMap(() => {
            // console.log('Done Deleting roles')
            return forkJoin(batchDeletePolicies).pipe(defaultIfEmpty([]), concatMap(res => {
              // console.log('Done Deleting Policies')
              return of(res);
            }));
          }));
        }));
      }));
    }));
  }


  setupTeamRoles({ account, team, region }): Observable<any> {
    // dans les params je pense pas que account region soient necessaire
    const roles = Object.keys(Roles.teamRoles).map(roleName => new TeamRole({ roleName, role: Roles.teamRoles[roleName] }, { team: team.id, domain: team.domain }));
    const policies = [];
    for (const role of roles) {
      policies.push(...role.policies);
    }
    // Filtering unique policy in order to prevent 409
    const policiesUnique = [];
    const policiesToCreate = new Map(); // Map to store unique policy names
    for (const policy of policies) {
      if (!policiesToCreate.has(policy.name)) {
        policiesToCreate.set(policy.name, true);
        policiesUnique.push(policy);
      }
    }
    const batchCreatePolicy = policiesUnique.map(policy => this.createPolicy({ ...policy, tags: buildGenericTags() }));
    return forkJoin(batchCreatePolicy).pipe(concatMap(() => {
      const batchCreateRole = roles.map(role => this.createRole({ ...role, tags: buildGenericTags() }));
      return forkJoin(batchCreateRole).pipe(concatMap(() => {
        const batchAttachPolicies = roles.map(role => role.policies.map(policy => this.attachRolePolicy(role.name, policy.name))).flat();
        return forkJoin(batchAttachPolicies).pipe(concatMap(() => {
          const batchAttachRoleToTeam = roles.map(role => this.attachTeamRole(team.id, role.name));
          return forkJoin(batchAttachRoleToTeam);
        }));
      }));
    }));
  }

  // FIX ME Not scalable
  listUserGroupsRolesWithGroupId(id: string): Observable<any> {
    return this.listUserGroups(id).pipe(concatMap(res => {
      const { groups } = res;
      const reqGetGroupRoles = groups.map(group => this.listGroupRoles(group.id).pipe(map(({ roles }) => ((roles.map(role => ({ ...role, groupId: group.id, groupName: group.name })))))));
      return forkJoin(reqGetGroupRoles).pipe(map((res: any[]) => res.flat()));
    }));
  }

  listTeamHydratedRoles(id: string): Observable<any> {
    return this.listTeamRoles(id).pipe(concatMap(({ roles }) => {
      const teamRoles = roles.filter(role => role.name.split('$')[0] === id);
      return forkJoin(teamRoles.map(r => this.getRole(r.name).pipe(map(({ role }) => role)))).pipe(defaultIfEmpty([]));
    }));
  }

  findTeamWithUsers(id): Observable<any> {
    return this.http.get<any>('service:iam' + '/team/' + id, { headers: this.headers }).pipe(map(({ team }) => {
      let guestCount = 0;
      if (team?.config?.authentication?.rules?.length) {
        guestCount = team.config.authentication.rules.length;
      }
      return { ...team, guestCount };
    }));
  }

}
