import { HttpErrorResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { delay, map, retryWhen } from 'rxjs/operators';

export function retryOn500<T>(maxRetry = 30, delayTime = 5000): (source: Observable<T>) => Observable<T> {
    let retryCount = 0;
    return pipe(
        retryWhen(error =>
            error.pipe(
                map((errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status === 0 || errorResponse.status === 400) {
                        // Unlimited retry
                    } else if (errorResponse.status >= 500) {
                        if (++retryCount >= maxRetry) {
                            throw errorResponse;
                        }
                    } else {
                        throw errorResponse;
                    }
                }),
                delay(delayTime),
            )
        ),
    );
}
