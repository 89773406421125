import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Logger } from 'cloudwatch-front-logger';
import { v4 as uuidv4, v4 } from 'uuid';
import { environment } from '../../../environments/environment';
import { CookiesService } from '../services/cookies.service';
import { ErrorService } from '../services/error.service';
import { LoggerService } from '../services/logger.service';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    logger: Logger;
    constructor(
        private injector: Injector,
        private cookieService: CookiesService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        const identity = this.cookieService.getCookie('identity');
        if (isPlatformBrowser(this.platformId)) {
            this.logger = new Logger(environment.LambdastaticSmashAppUserAccessKey, environment.LambdastaticSmashAppUserSecretKey, environment.cloudwatchRegion, environment.cloudwatchLogGroupName);
            this.logger.install({
                logStreamNameResolver() {
                    return new Promise(async (resolve) => resolve(uuidv4() + '_' + Date.now()));
                },
                async messageFormatter(e, info = { type: 'unknown' }) {
                    if (!e.message) {
                        return null;
                    }

                    return JSON.stringify({
                        appVersion: environment.version,
                        message: e.message,
                        timestamp: new Date().getTime(),
                        account: identity?.account ? identity?.account : identity?.id,
                        id: identity?.account ? identity?.id : identity?.account,
                        region: identity?.region,
                        language: identity?.language,
                        username: identity?.username,
                        locationPathname: window.location.pathname,
                        locationSearch: window.location.search,
                        locationHost: window.location.host,
                        userAgent: window.navigator.userAgent,
                        ...info,
                    });
                },
            });
        }
    }

    handleError(error: Error | HttpErrorResponse) {
        const errorService = this.injector.get(ErrorService);
        const logger = this.injector.get(LoggerService);
        let message;
        let stackTrace;
        if (isPlatformBrowser(this.platformId)) {
            // Client Error
            message = errorService.getClientMessage(error);
            stackTrace = errorService.getClientStack(error);
        } else {
            // Server Error
            message = errorService.getServerMessage(error);
        }
        // Always log errors
        logger.error(message, stackTrace);
    }
}
