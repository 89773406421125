import { createAction, props } from '@ngrx/store';
import { CreateTeamDropboxOutput, GetDropboxPreviewOutput } from '@smash-sdk/transfer/01-2024';

export const CreateTeamDropbox = createAction(
  '[DropboxMobile] Create Team Dropbox',
  props<{ teamId: string }>()
);

export const CreateTeamDropboxSuccess = createAction(
  '[DropboxMobile] Create Team Dropbox Success',
  props<{ dropbox: CreateTeamDropboxOutput['dropbox'] }>()
);

export const CreateTeamDropboxFailure = createAction(
  '[DropboxMobile] Create Team Dropbox Failure',
  props<{ error: any }>()
);

export const GetDropboxPreview = createAction(
  '[DropboxMobile] Get Dropbox Preview',
  props<{ dropboxId: string }>()
);

export const GetDropboxPreviewSuccess = createAction(
  '[DropboxMobile] Get Dropbox Preview Success',
  props<{ dropbox: GetDropboxPreviewOutput['dropbox'] }>()
);

export const GetDropboxPreviewFailure = createAction(
  '[DropboxMobile] Get Dropbox Preview Failure',
  props<{ error: any }>()
);
