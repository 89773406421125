import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmashSelectLangComponent } from './smash-select-lang/smash-select-lang.component';
import { RouterModule } from '@angular/router';
import { NgClickOutsideDirective } from 'ng-click-outside2';


@NgModule({
  declarations: [SmashSelectLangComponent],
  imports: [
    CommonModule,
    NgClickOutsideDirective,
    RouterModule
  ],
  exports: [SmashSelectLangComponent]

})
export class SmashSelectLangModule { }
