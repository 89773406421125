import { SigninConfiguration } from '@app/shared/models/signinConfiguration';
import { createAction, props } from '@ngrx/store';

// Load signin configuration
export const loadSigninConfiguration = createAction(
  '[Signin] Load Signin Configuration',
  props<{ username: string }>()
);

export const loadSigninConfigurationSuccess = createAction(
  '[Signin] Load Signin Configuration Success',
  props<{ configuration: SigninConfiguration }>()
);

export const loadSigninConfigurationFailure = createAction(
  '[Signin] Load Signin Configuration Failure',
  props<{ error: any }>()
);

// Signin with password
export const signinWithPassword = createAction(
  '[Signin] Signin With Password',
  props<{ username: string, password: string }>()
);

export const signinWithPasswordSuccess = createAction(
  '[Signin] Signin With Password Success',
  props<{ identity: any, region?: string }>()
);

export const signinWithPasswordFailure = createAction(
  '[Signin] Signin With Password Failure',
  props<{ error: any }>()
);

export const signinSuccess = createAction(
  '[Signin] Signin success user',
  props<{ identity: any, region?: string }>()
);

// Automatic Redirect To SSO
export const automaticRedirectSso = createAction(
  '[Signin] Automatic Redirect To SSO',
  props<{ encodedSsoInformations: string }>()
);

export const automaticRedirectSsoSuccess = createAction(
  '[Signin] Automatic Redirect To SSO Success',
);

export const automaticRedirectSsoFailure = createAction(
  '[Signin] Automatic Redirect To SSO Failure',
  props<{ error: any }>()
);

// Reset signin
export const resetSignin = createAction(
  '[Signin] Reset Signin',
);

// Signin check account
export const signinCheckAccountType = createAction(
  '[Signin] CheckAccountType',
  props<{ identity: any, region?: string }>()
);

export const signinCheckAccountTypeSuccess = createAction(
  '[Signin] CheckAccountType Success',
  props<{ identity: any, region?: string, accountType: string }>()
);
