import { buildGenericTags } from "@app/shared/helpers/tags";

export const Policies = {
    teamFullAccess: {
        name: '${team}-adminTeamFullAccess',
        description: 'Provides full access to team resources.',
        statement: [
            {
                action: [
                    'iam:AttachTeamGroup',
                    'iam:AttachTeamUser',
                    'iam:AttachTeamGroupUser',
                    'iam:AttachTeamUserRole',
                    'iam:AttachTeamGroupRole',
                    'iam:DetachTeamGroupUser',
                    'iam:DetachTeamUserRole',
                    'iam:DetachTeamGroup',
                    'iam:DetachTeamUser',
                    'iam:GetTeam',
                    'iam:GetTeamUser',
                    'iam:GetTeamGroup',
                    'iam:ListTeamGroups',
                    'iam:ListTeamRoles',
                    'iam:ListTeamGroupRoles',
                    'iam:ListTeamUserGroups',
                    'iam:ListTeamUserRoles',
                    'iam:ListTeamGroupUsers',
                    'iam:ListUsers',
                    'iam:ListTeamUsers',
                    'iam:InviteTeamUser',
                    'iam:UpdateTeam',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:iam:$account:*:user',
                    'srn:smash:iam:$account:*:team/${team}',
                    'srn:smash:iam:$account:*:team/${team}/*'
                ]
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    teamAdministratorMemberAccess: {
        name: '${team}-teamAdministratorMemberAccess',
        description: 'Provides write access to members for team administrator.',
        statement: [
            {
                action: ['iam:CreateUser'],
                effect: 'Allow',
                resource: ['srn:smash:iam:$account:*:user']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    teamAdministratorGroupAccess: {
        name: '${team}-teamAdministratorGroupAccess',
        description: 'Provides write access to groups for team administrator.',
        statement: [
            {
                action: [
                    'iam:ListGroupUsers',
                    'iam:CreateGroup'
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:iam:$account:*:group',
                    'srn:smash:iam:$account:*:group/*',
                ]
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    transferFullAccess: {
        name: '${team}-TransferFullAccess',
        description: 'Provides full access to Transfer services and team resources.',
        statement: [
            {
                action: [
                    'transfer:CreateTeamTransfer',
                    'transfer:DeleteTeamTransfer',
                    'transfer:GetTeamTransfer',
                    'transfer:ListTeamTransfers',
                    'transfer:ListTeamBackgrounds',
                    'transfer:ListTeamTransferEvents',
                    'transfer:ListTeamTransferFiles',
                    'transfer:GetTeamTransferFile',
                    'transfer:CreateDropboxTransfer',
                    'transfer:CreateTeamUserDropbox',
                    'transfer:GetTeamReceipt',
                    'transfer:DeleteTeamReceipt',
                    'transfer:ListTeamReceipts',
                    'transfer:ListTeamReceiptFiles',
                    'transfer:ListTeamUserReceiptFiles',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:transfer:$account:*:team/${team}/*',
                    'srn:smash:transfer:$account:*:dropbox/*/transfer',
                ]

            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    transferReadAccess: {
        name: '${team}-TransferReadAccess',
        description: 'Provides read only access to Transfer services.',
        statement: [
            {
                // TODO Create endpoint for GetUserTeamTransfer
                action: [
                    'transfer:GetTeamTransfer',
                    'transfer:ListTeamBackgrounds',
                    'transfer:ListTeamTransferEvents',
                    'transfer:ListTeamTransferFiles',
                    'transfer:GetTeamTransferFile',
                    'transfer:GetTeamReceipt',
                    'transfer:GetTeamUserReceipt',
                    'transfer:GetTeamReceiptFile',
                    'transfer:GetTeamUserReceiptFile',
                    'transfer:ListTeamReceipts',
                    'transfer:ListTeamUserReceipts',
                    'transfer:ListTeamReceiptFiles',
                    'transfer:ListTeamUserReceiptFiles',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:transfer:$account:*:team/${team}/*',

                ]
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    transferStatsTeamAccess: {
        name: '${team}-transferStatsReadAccess',
        description: 'Provides access to team stats.',
        statement: [
            {
                action: [
                    'transfer:GetTeamMetricStatistic',
                    'transfer:GetTeamUserMetricStatistic',
                ],
                effect: 'Allow',
                resource: ['srn:smash:transfer:$account:*:team/${team}/*']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    transferStatsFullAccess: {
        name: '${team}-transferStatsReadAccess',
        description: 'Provides full access to stats.',
        statement: [
            {
                action: [
                    'transfer:GetMetricStatistic',
                    'transfer:GetTeamMetricStatistic',
                    'transfer:GetTeamUserMetricStatistic',
                    'transfer:GetUserMetricStatistic',
                ],
                effect: 'Allow',
                resource: ['srn:smash:transfer:$account:*:*']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    customizationFullAccess: {
        name: '${team}-CustomizationFullAccess',
        description: 'Provides full access to Customization services and team resources.',
        statement: [
            {
                action: [
                    'customization:ListTeamCustomizations',
                    'customization:ListTeamLogos',
                    'customization:ListTeamBackgrounds',
                    'customization:CreateTeamLogo',
                    'customization:DeleteTeamLogo',
                    'customization:CreateTeamBackground',
                    'customization:DeleteTeamBackground',
                    'customization:GetTeamCustomization',
                    'customization:CreateTeamCustomization',
                    'customization:UpdateTeamCustomization',
                    'customization:AttachTeamCustomizationLogo',
                    'customization:AttachTeamCustomizationBackground',
                    'customization:DetachTeamCustomizationLogo',
                    'customization:DetachTeamCustomizationBackground',
                    'customization:DeleteTeamCustomization',
                ],
                effect: 'Allow',
                resource: ['srn:smash:customization:$account:*:team/${team}/*']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    customizationReadAccess: {
        name: '${team}-CustomizationReadAccess',
        description: 'Provides read only access to Customization services.',
        statement: [
            {
                action: [
                    'customization:ListTeamCustomizations',
                    'customization:ListTeamLogos',
                    'customization:ListTeamBackgrounds',
                    'customization:GetTeamCustomization',
                ],
                effect: 'Allow',
                resource: ['srn:smash:customization:$account:*:team/${team}/*']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    domainCustomizationFullAccess: {
        name: '${team}-DomainCustomizationFullAccess',
        description: 'Provides full access to DomainCustomization services and team resources.',
        statement: [
            {
                action: [
                    'domain:GetDomainCustomization',
                    'domain:UpdateDomainCustomization',
                    'domain:DeleteDomainCustomization'
                ],
                effect: 'Allow',
                resource: ['srn:smash:domain:$account::domain/${domain}/customization']
            },
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    domainCustomizationReadAccess: {
        name: '${team}-DomainCustomizationReadAccess',
        description: 'Provides read access to DomainCustomization services and team resources.',
        statement: [
            {
                action: [
                    'domain:GetDomainCustomization'
                ],
                effect: 'Allow',
                resource: ['srn:smash:domain:$account::domain/${domain}/customization']
            },
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    promotionFullAccess: {
        name: '${team}-PromotionFullAccess',
        description: 'Provides full access to Promotion services and team resources.',
        statement: [
            {
                action: [
                    'promotion:GetTeamPromotion',
                    'promotion:ListTeamPromotions',
                    'promotion:ListTeamPromotionsExternal',
                    'promotion:ListTeamPromotionsInternal',
                    'promotion:CreateTeamPromotion',
                    'promotion:DeleteTeamPromotion',
                    'promotion:UpdateTeamPromotion',
                    'promotion:CreateTeamPromotionMedia',
                    'promotion:DeleteTeamPromotionMedia',
                ],
                effect: 'Allow',
                resource: ['srn:smash:promotion:$account:*:team/${team}/*']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    promotionReadAccess: {
        name: '${team}-PromotionReadAccess',
        description: 'Provides read only access to Promotion services.',
        statement: [
            {
                action: [
                    'promotion:GetTeamPromotion',
                    'promotion:ListTeamPromotionsExternal',
                    'promotion:ListTeamPromotionsInternal',
                ],
                effect: 'Allow',
                resource: ['srn:smash:promotion:$account:*:team/${team}/*']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    linkReadAccess: {
        name: '${team}-LinkReadAccess',
        description: 'Provides read only access to Link services.',
        statement: [
            {
                action: [
                    'link:GetTeamLink',
                    'link:ListTeamLinks', // not used
                ],
                effect: 'Allow',
                resource: ['srn:smash:link:$account:*:team/${team}/*']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    imageFullAccess: {
        name: '${team}-ImageFullAccess',
        description: 'Provides full access to Image services',
        statement: [
            {
                action: [
                    'image:CreateImage',
                    'image:GetImage',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:image:$account:*:image',
                    'srn:smash:image:$account:*:image/*'
                ]
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    domainWriteAccess: {
        name: '${team}-DomainWriteAccess',
        description: 'Provides write access to Domain services.',
        statement: [
            {
                action: [
                    'domain:UpdateDomain',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:domain:$account:*:domain/${domain}'
                ]
            },
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    domainReadAccess: {
        name: '${team}-DomainReadAccess',
        description: 'Provides read access to Domain services.',
        statement: [
            {
                action: [
                    'domain:GetDomain',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:domain:$account:*:domain/${domain}'
                ]
            },
        ],
        tags: {
            ...buildGenericTags(),
        }
    },
    baseMember: {
        name: '${team}-baseMember',
        description: 'Provides basic access to a team member.',
        statement: [
            {
                action: [
                    'billing:ListSubscriptionLimits',
                ],
                effect: 'Allow',
                resource: ['srn:smash:billing:$account:*:limit']
            },
            {
                action: [
                    'scitylana:CreateTneve',
                ],
                effect: 'Allow',
                resource: ['srn:smash:scitylana::*:tneve']
            },
            {
                action: [
                    'captcha:VerifyToken',
                ],
                effect: 'Allow',
                resource: ['srn:smash:captcha::*:token']
            },
            {
                action: [
                    'iam:UpdatePassword',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:iam:$account:*:password',
                ]
            },
            {
                action: [
                    'iam:ListUserTeams',
                    'iam:ListUserGroupsTeams',
                    'iam:ListUserRoles',
                    'iam:ListUserGroupsRoles',
                    'iam:GetUser',
                    'iam:UpdateUser',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:iam:$account:*:user/$id',
                    'srn:smash:iam:$account:*:user/$id/*',
                ]
            },
            {
                action: [
                    'customization:GetManagedCustomization',
                    'customization:ListManagedCustomizations',
                    'customization:ListManagedBackgrounds',
                    'customization:ListManagedLogos',
                ],
                effect: 'Allow',
                resource: [
                    '*',
                    // 'srn:smash:customization::*:*', // Need to fix this it should work
                ]
            },
            {
                action: [
                    'transfer:CreateTeamTransfer',
                    'transfer:ListUserTransfers',
                    'transfer:GetUserTransfer',
                    'transfer:ListUserTransferFiles',
                    'transfer:ListTeamUserTransfers',
                    'transfer:ListUserTransferEvents',
                    'transfer:DeleteUserTransfer',
                    'transfer:LockTransfer',
                    'transfer:CreateTransferFile',
                    'transfer:DeleteTransferFile',
                    'transfer:UpdateTransferFile',
                    'transfer:CreateTransferFilePart',
                    'transfer:CreateTransferFileParts',
                    'transfer:UpdateTransferFilePart',
                    'transfer:UpdateTransferFileParts',
                    'transfer:GetTransferPreview',
                    'transfer:ListTransferFilesPreview',
                    'transfer:CreateTransferSignedUrls',
                    'transfer:GetReport',
                    'transfer:DeleteReport',
                    'transfer:CreateDropboxTransfer',
                    'transfer:GetDropboxPreview',
                    'transfer:CreateTeamUserDropbox',
                    'transfer:GetTeamUserReceipt',
                    'transfer:GetTeamUserReceiptFile',
                    'transfer:DeleteTeamUserReceipt',
                    'transfer:ListTeamUserReceipts',
                    'transfer:ListTeamUserReceiptFiles',
                ],
                effect: 'Allow',
                resource: [
                    'srn:smash:transfer:$account:*:team/${team}/transfer/queue',
                    'srn:smash:transfer:$account:*:team/${team}/transfer',
                    'srn:smash:transfer:$account:*:team/${team}/user/$id/transfer/*',
                    'srn:smash:transfer:$account:*:team/${team}/user/$id/transfer',
                    'srn:smash:transfer:$account:*:user/$id/transfer/*',
                    'srn:smash:transfer:$account:*:user/$id/transfer',
                    'srn:smash:transfer:$account:*:transfer/*',
                    'srn:smash:transfer:$account:*:transfer',
                    'srn:smash:transfer::*:transfer/*',
                    'srn:smash:transfer:$account:*:report/*',
                    'srn:smash:transfer:$account:*:dropbox/*',
                    'srn:smash:transfer:$account:*:dropbox/*/transfer',
                    'srn:smash:$service:$account:*:team/${team}/user/$id/dropbox',
                    'srn:smash:transfer:$account:*:team/${team}/user/$id/receipt',
                    'srn:smash:transfer:$account:*:team/${team}/user/$id/receipt/*',
                    'srn:smash:transfer:$account:*:team/${team}/user/$id/receipt/*/file',
                    'srn:smash:transfer:$account:*:team/${team}/user/$id/receipt/*/file/*',
                ]
            },
            {
                action: [
                    'link:GetTarget',
                ],
                effect: 'Allow',
                resource: ['srn:smash:link::*:target/*']
            },
            {
                action: [
                    'vat:ListCountries',
                ],
                effect: 'Allow',
                resource: [
                    '*'
                ]
            },
            {
                action: [
                    'promotion:GetPromotionPreview',
                ],
                effect: 'Allow',
                resource: ['srn:smash:promotion::*:promotion/*']
            }
        ],
        tags: {
            ...buildGenericTags(),
        }
    }
};

