import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LoggerService } from '@app/core/services/logger.service';
import { cleanEmptyValuesFromBody } from '@app/shared/helpers/helpers';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BodyInterceptor implements HttpInterceptor {

    constructor(
        private loggerService: LoggerService,
        @Inject(PLATFORM_ID) private platformId: any) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (isPlatformBrowser(this.platformId)) {
            if (!(req.body instanceof Blob) && !!req.body) {
                req = req.clone({
                    body: this.cleanBody(req.body),
                });
            }
        }
        return next.handle(req);
    }

    cleanBody(body: any) {
        return cleanEmptyValuesFromBody(body);
    }
}
