import { CommonModule } from '@angular/common';
import { NgModule, PLATFORM_ID } from '@angular/core';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { TranslateModule } from '@ngx-translate/core';
import { LocalStorageService } from './services/local-storage.service';

const localStorageService = new LocalStorageService(PLATFORM_ID);

const JWTModuleOptions: JwtModuleOptions = {
  config: {
    tokenGetter: getAccessToken,
    allowedDomains: ['localhost:4200', 'fromsmash.ninja', 'fromsmash.com']
  }
};

export function getAccessToken() {
  if (typeof window !== "undefined")
    return localStorageService.getItem('access_token');
}

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    JwtModule.forRoot(JWTModuleOptions),
  ],
  providers: [],
  exports: [TranslateModule],
})
export class CoreModule { }
