import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from '@app/home/homepage/homepage.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { routes } from './home.routes';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import * as fromUploaderMobile from '@app/client/uploader-mobile/store/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { SmashSelectLangModule } from '@app/shared/modules/smash-select-lang/smash-select-lang.module';

@NgModule({
    declarations: [
        HomepageComponent,
    ],
    providers: [
        TranslateService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        SmashSelectLangModule,
        // On mobile we rely on the current step ('landing') to show or hide  
        StoreModule.forFeature(fromUploaderMobile.uploaderFeatureKey, fromUploaderMobile.reducers),
        RouterModule.forChild(routes)
    ]
})
export class HomeModule { }
