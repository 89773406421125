<div class="languages" (clickOutside)="closeMenu($event)">
    <div class="languages-menu" [ngClass]="{'open': menuToggle, 'up': dropdownDirectionCompute === 'up'}" (click)="toggleMenu()" >
        <div *ngFor="let language of supportedLanguages" >
            <a *ngIf="headerLocation === 'viewer'; else default" (click)="selectLanguage($event, language.id)">{{language.name}}</a>
            <ng-template #default>
                <a [routerLink]="[ '/' + (language.id === 'en' ? '' :  language.id) + (pathname ? pathname : '') ]"  (click)="selectLanguage($event, language.id)">{{language.name}}</a>
            </ng-template>
        </div>
    </div>
    <div #elementRef class="current-language" [ngClass]="{'with-border': withBorder }" (click)="toggleMenu()">
        <div>{{ selectedLanguage }}</div>
        <div class="chevron" [ngStyle]="{'border-color': chevronColor}" [ngClass]="{'open-up': menuToggle && dropdownDirectionCompute === 'up', 'open-down': menuToggle && dropdownDirectionCompute === 'down'}"></div>
    </div>
</div>