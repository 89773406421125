import { createAction, props } from '@ngrx/store';
import { Customization } from '@app/shared/models/customization';
import { Background } from '@app/shared/models/background';
import { Logo } from '@app/shared/models/logo';
import { GetDomainCustomizationOutput } from '@smash-sdk/domain/01-2024';

export const loadManagedCustomization = createAction(
    '[System] Load Managed Customization',
);

export const loadManagedCustomizationSuccess = createAction(
    '[System] Load Managed Customization Success',
    props<{ customization: GetDomainCustomizationOutput['customization'] }>()
);

export const loadManagedCustomizationFailure = createAction(
    '[System] Load Managed Customization Failure',
    props<{ error: any }>()
);

export const loadManagedBackgrounds = createAction(
    '[System] Load Managed Backgrounds',
);

export const loadManagedBackgroundsSuccess = createAction(
    '[System] Load Managed Backgrounds Success',
    props<{ backgrounds: Background[] }>()
);

export const loadManagedBackgroundsFailure = createAction(
    '[System] Load Managed Backgrounds Failure',
    props<{ error: any }>()
);

export const loadManagedLogos = createAction(
    '[System] Load Managed Logos',
);

export const loadManagedLogosSuccess = createAction(
    '[System] Load Managed Logos Success',
    props<{ logos: Logo[] }>()
);

export const loadManagedLogosFailure = createAction(
    '[System] Load Managed Logos Failure',
    props<{ error: any }>()
);
