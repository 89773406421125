import { Injectable } from '@angular/core';
import { getProtocolHostnameAndPort, getProtocolMainDomainAndPort } from '@app/shared/helpers/location';
import { Constant } from 'src/constant';

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {

  constructor() { }
  // rewriter redirect using angular router to stay in angular context
  redirectToPricingPage(language) {
    const domainWithProtocol = getProtocolHostnameAndPort();
    if (language && Constant.pricingUrls[language]) {
      window.location.href = domainWithProtocol + Constant.pricingUrls[language];
    } else {
      window.location.href = domainWithProtocol + Constant.pricingUrls.fallback;
    }
  }

  redirectToInvalidConfiguration() {
    const domainWithProtocol = getProtocolHostnameAndPort();
    window.location.href = domainWithProtocol + '/invalid-configuration';
  }

  redirectToUploader() {
    const domainWithProtocol = getProtocolHostnameAndPort();
    window.location.href = domainWithProtocol + '/';
  }

  redirectToSubscriptionUpdate() {
    const domainWithProtocol = getProtocolMainDomainAndPort();
    window.location.href = domainWithProtocol + '/subscription/update';
  }

  redirectToSignupDomain() {
    const domainWithProtocol = getProtocolMainDomainAndPort();
    window.location.href = domainWithProtocol + '/signup/domain';
  }

  redirectToSignin() {
    const domainWithProtocol = getProtocolHostnameAndPort();
    window.location.href = domainWithProtocol + '/signin';
  }

  redirectToSignout() {
    const domainWithProtocol = getProtocolHostnameAndPort();
    window.location.href = domainWithProtocol + '/signout';
  }

  redirectTo404() {
    const domainWithProtocol = getProtocolHostnameAndPort();
    window.location.href = domainWithProtocol + '/404';
  }

  redirectTo(url) {
    window.location.href = url;
  }
}
