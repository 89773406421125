import { Provider } from '@app/shared/models/provider';
import { Action, createReducer, on } from '@ngrx/store';
import { GetRecordOutput } from '@smash-sdk/domain/01-2024';
import { Constant } from 'src/constant';
import { ThemeActions, DomainActions } from '../actions';


export const domainFeatureKey = 'domain';

export interface State {
  currentDomain: GetRecordOutput['record'];
  currentDomainLoading: boolean;
  currentDomainLoaded: boolean;
  currentDomainProviders: Provider[];
  domainUpdatedError: number;
  domainToCreate: any;
  domainCreating: boolean;
  domainCreatingError: any;
  loadDomainError: string;
  mainDomain: string;
}

export const initialState: State = {
  currentDomain: null,
  currentDomainLoading: false,
  currentDomainLoaded: false,
  currentDomainProviders: [],
  domainUpdatedError: null,
  domainToCreate: null,
  domainCreating: false,
  domainCreatingError: null,
  loadDomainError: null,
  mainDomain: '',
};

const domainReducer = createReducer(
  initialState,
  // Domain
  on(DomainActions.loadDomain, (state) => ({
    ...state,
    currentDomain: initialState.currentDomain,
    currentDomainLoading: true,
    currentDomainLoaded: initialState.currentDomainLoaded,
  })),
  on(DomainActions.loadDomainSuccess, (state, { domain, providers, mainDomain }) => ({
    ...state,
    currentDomain: domain,
    currentDomainProviders: providers,
    currentDomainLoading: false,
    currentDomainLoaded: true,
    mainDomain,
  })),
  on(DomainActions.loadDomainFailure, (state, { error }) => ({
    ...state,
    loadDomainError: error,
    currentDomainLoaded: true,
  })),
  on(DomainActions.updateDomainFailure, (state, { error }) => ({
    ...state,
    domainUpdatedError: error,
  })),
  on(DomainActions.updateDomainToCreate, (state, { domain }) => ({
    ...state,
    domainToCreate: domain
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return domainReducer(state, action);
}

export const canConfigSmash = (state: State) => {
  if (state?.currentDomain?.options?.transfer) {
    const { availabilityDuration, customization, promotion, notification, password, preview } = state.currentDomain.options.transfer;
    return availabilityDuration.active || customization.active || promotion.active || notification.sender.active || notification.link.active || notification.noDownload.active || notification.download.active || password.active || preview.active;
  } else {
    return true; // default to true for now if no options
  }
};

export const getDomainTransferSettings = (state: State) => {
  if (state?.currentDomain?.options?.transfer) {
    return state.currentDomain.options.transfer;
  }
  return Constant.defaultManagedDomainSettings.transfer;
};

export const getDropboxTransferSettings = (state: State) => {
  if (state?.currentDomain?.options?.dropbox?.transfer) {
    return state.currentDomain.options.dropbox.transfer;
  }
  return Constant.defaultManagedDomainSettings.transfer;
};
