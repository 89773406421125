import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private jwtHelperService: JwtHelperService) { }

  /**
   * decodeToken
   */
  public decodeToken(encodedToken: string): any {
    return this.jwtHelperService.decodeToken(encodedToken);
  }

  public getAccountFromToken(encodedToken: string): string {
    const decodedToken = this.decodeToken(encodedToken);
    const account = decodedToken.account;
    return account;
  }

  public getRegionFromToken(encodedToken: string): string {
    const decodedToken = this.decodeToken(encodedToken);
    const region = decodedToken.region;
    return region;
  }

  /**
   * getTokenExpirationDate
   */
  public getTokenExpirationDate(encodedToken: string): any {
    return this.jwtHelperService.getTokenExpirationDate(encodedToken);
  }

  /**
   * isTokenExpired
   */
  public isTokenExpired(encodedToken: string): boolean {
    return this.jwtHelperService.isTokenExpired(encodedToken);
  }

  public isAnonymousAccount(encodedToken: string): boolean {
    if (encodedToken) {
      const decodedToken = this.decodeToken(encodedToken);
      return !decodedToken.username;
    }
    throw new Error('Invalid encodedToken value');
  }

  /**
   * isRoot
   */
  public isRoot(token): boolean {
    const decodedToken = this.decodeToken(token);
    if (decodedToken) {
      return decodedToken.account === decodedToken.id;
    }
    return false;
  }
}
