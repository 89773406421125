import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  debug(message: string) {
    console.log('Debug:', message);
  }

  log(message: string) {
    console.log(message);
  }

  error(message: any, stack?: string) {
    if (typeof message === 'object') {
      console.error(`Error: ${JSON.stringify(message)}`, stack);
    } else {
      console.error(`Error: ${message}`, stack);
    }
  }
}
