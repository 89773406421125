import { Action, createReducer, on } from '@ngrx/store';
import { CreateTeamDropboxOutput, GetDropboxPreviewOutput } from '@smash-sdk/transfer/01-2024';
import { DropboxActions } from '../actions';


export const dropboxFeatureKey = 'dropboxMobile';

export interface State {
  dropbox: {
    dropbox: CreateTeamDropboxOutput['dropbox'];
    dropboxCreating: boolean;
    dropboxCreated: boolean;
    dropboxError: any;
  };
  dropboxPreview: {
    dropbox: GetDropboxPreviewOutput['dropbox'];
    dropboxPreviewLoading: boolean;
    dropboxPreviewLoaded: boolean;
    dropboxPreviewError: any;
  };
}

export const initialState: State = {
  dropbox: {
    dropbox: null,
    dropboxCreating: false,
    dropboxCreated: false,
    dropboxError: null,
  },
  dropboxPreview: {
    dropbox: null,
    dropboxPreviewLoading: false,
    dropboxPreviewLoaded: false,
    dropboxPreviewError: null,
  },
};

const layoutReducer = createReducer(
  initialState,
  on(DropboxActions.CreateTeamDropbox, (state, { teamId }) => ({
    ...state,
    dropbox: {
      ...state.dropbox,
      dropboxCreating: true,
      dropboxCreated: false,
      dropboxError: null,
    },
  })),
  on(DropboxActions.CreateTeamDropboxSuccess, (state, { dropbox }) => ({
    ...state,
    dropbox: {
      ...state.dropbox,
      dropboxCreating: false,
      dropboxCreated: true,
      dropboxError: null,
      dropbox,
    },
  })),
  on(DropboxActions.CreateTeamDropboxFailure, (state, { error }) => ({
    ...state,
    dropbox: {
      ...state.dropbox,
      dropboxCreating: false,
      dropboxCreated: false,
      dropboxError: error,
    },
  })),

  on(DropboxActions.GetDropboxPreview, (state) => ({
    ...state,
    dropboxPreview: {
      ...state.dropboxPreview,
      dropboxPreviewLoading: true,
      dropboxPreviewLoaded: false,
      dropboxPreviewError: null,
    },
  })),
  on(DropboxActions.GetDropboxPreviewSuccess, (state, { dropbox }) => ({
    ...state,
    dropboxPreview: {
      ...state.dropboxPreview,
      dropboxPreviewLoading: false,
      dropboxPreviewLoaded: true,
      dropboxPreviewError: null,
      dropbox,
    },
  })),
  on(DropboxActions.GetDropboxPreviewFailure, (state, { error }) => ({
    ...state,
    dropboxPreview: {
      ...state.dropboxPreview,
      dropboxPreviewLoading: false,
      dropboxPreviewLoaded: false,
      dropboxPreviewError: error,
    },
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return layoutReducer(state, action);
}

export const getDropbox = (state: State) => state.dropbox.dropbox;
export const getDropboxCreating = (state: State) => state.dropbox.dropboxCreating;
export const getDropboxCreated = (state: State) => state.dropbox.dropboxCreated;
export const getDropboxError = (state: State) => state.dropbox.dropboxError;

export const getDropboxPreview = (state: State) => state.dropboxPreview.dropbox;
export const getDropboxPreviewLoading = (state: State) => state.dropboxPreview.dropboxPreviewLoading;
export const getDropboxPreviewLoaded = (state: State) => state.dropboxPreview.dropboxPreviewLoaded;


export const getCanConfigDropboxTransfer = (state: State) => {
  if (state?.dropboxPreview?.dropbox?.configuration?.transfer) {
    const { availabilityDuration, notification, password, preview } = state.dropboxPreview.dropbox.configuration.transfer;
    return availabilityDuration.active || notification.sender.active || notification.noDownload.active || notification.download.active || password.active || preview.active;
  } else {
    return true; // default to true for now if no options
  }
};
