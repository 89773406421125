import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { UserCustomizationActions } from './../actions';
import { Logo } from '@app/shared/models/logo';
import { Background } from '@app/shared/models/background';
import { DomainActions, TeamActions, SystemActions } from '@app/core/actions';
import { GetDomainCustomizationOutput } from '@smash-sdk/domain/01-2024';

export const customizationFeatureKey = 'userCustomization';

export interface State {
  customizationReceiver: GetDomainCustomizationOutput['customization'];
  customizationReceiverLoading: boolean;
  customizationReceiverError: boolean;
  customizationReceiverIsSystem: boolean;

  customizationSender: GetDomainCustomizationOutput['customization'];
  customizationSenderLoading: boolean;
  customizationSenderError: boolean;
  customizationSenderIsSystem: boolean;

  customizationToUpdate: GetDomainCustomizationOutput['customization'];
  customizationMediaLoading: boolean;
  customizationUpdating: boolean;
  customizationUpdated: boolean;
  customizationUpdatingError: any;


  backgrounds: Background[];
  backgroundsLoading: boolean;
  backgroundsError: any;

  logosToCreate: any[];
  logosToDelete: Logo[];
  backgroundsToCreate: any[];
  backgroundsToDelete: Background[];

  logos: Logo[];
  logosLoading: boolean;
  logosError: any;

  selectedBackground: Background;
  selectedLogo: Logo;

}

export const initialState: State = {
  customizationReceiver: null,
  customizationReceiverLoading: false,
  customizationReceiverError: null,
  customizationReceiverIsSystem: false,

  customizationSender: null,
  customizationSenderLoading: false,
  customizationSenderError: null,
  customizationSenderIsSystem: false,


  customizationToUpdate: null,
  customizationMediaLoading: false,
  customizationUpdating: false,
  customizationUpdated: false,
  customizationUpdatingError: null,

  backgrounds: [],
  backgroundsLoading: false,
  backgroundsError: null,

  logosToCreate: [],
  logosToDelete: [],
  backgroundsToCreate: [],
  backgroundsToDelete: [],

  logos: [],
  logosLoading: false,
  logosError: null,

  selectedBackground: null,
  selectedLogo: null,
};

const customizationReducer = createReducer(
  initialState,

  // Load system customization
  on(SystemActions.loadManagedCustomizationSuccess, (state, { customization }) => ({
    ...state,
    customizationReceiver: customization,
    customizationToUpdate: customization,
    customizationReceiverLoading: false,
    selectedBackground: customization.background,
    selectedLogo: customization.logo,
    customizationReceiverIsSystem: true
  })),

  // Load Recipient customization
  on(TeamActions.loadTeamCustomization, SystemActions.loadManagedCustomization, (state) => ({
    ...state,
    customizationReceiver: initialState.customizationReceiver,
    customizationReceiverLoading: true,
    customizationReceiverError: initialState.customizationReceiverError,
    customizationReceiverIsSystem: initialState.customizationReceiverIsSystem,
    customizationToUpdate: initialState.customizationToUpdate
  })),
  on(TeamActions.loadTeamCustomizationSuccess, (state, { customization }) => ({
    ...state,
    customizationReceiver: customization,
    customizationReceiverLoading: false,
    selectedBackground: customization.background,
    selectedLogo: customization.logo,
  })),
  on(TeamActions.loadTeamCustomizationFailure, SystemActions.loadManagedCustomizationFailure, (state, { error }) => ({
    ...state,
    customizationReceiver: initialState.customizationReceiver,
    customizationReceiverLoading: false,
    customizationReceiverError: error,
  })),

  // Load Team Assets logos & backgrounds (for recipient customization edit)
  on(TeamActions.loadTeamBackgrounds, (state) => ({
    ...state,
    backgrounds: initialState.backgrounds,
    backgroundsLoading: true,
    backgroundsError: initialState.backgroundsError,
  })),
  on(TeamActions.loadTeamBackgroundsSuccess, (state, { backgrounds }) => ({
    ...state,
    backgrounds,
    backgroundsLoading: false,
    backgroundsError: initialState.backgroundsError,
  })),
  // for now we dont show backgrounds in admin but need to resolve loading state
  on(SystemActions.loadManagedBackgroundsSuccess, (state) => ({
    ...state,
    backgroundsLoading: false,
    backgroundsError: initialState.backgroundsError,
  })),
  on(SystemActions.loadManagedBackgroundsFailure, (state, { error }) => ({
    ...state,
    backgroundsLoading: false,
    backgroundsError: error,
  })),
  on(TeamActions.loadTeamBackgroundsFailure, (state, { error }) => ({
    ...state,
    backgroundsLoading: false,
    backgroundsError: error,
  })),
  on(TeamActions.loadTeamLogos, (state) => ({
    ...state,
    logos: initialState.logos,
    logosLoading: true,
    logosError: initialState.logosError,
  })),
  on(TeamActions.loadTeamLogosSuccess, (state, { logos }) => ({
    ...state,
    logos,
    logosLoading: false,
    logosError: initialState.logosError,
  })),

  // for now we dont show logos in admin but need to resolve loading state
  on(SystemActions.loadManagedLogosSuccess, (state) => ({
    ...state,
    logosLoading: false,
    logosError: initialState.logosError,
  })),
  on(SystemActions.loadManagedLogosFailure, (state, { error }) => ({
    ...state,
    logosLoading: false,
    logosError: error,
  })),

  on(TeamActions.loadTeamLogosFailure, (state, { error }) => ({
    ...state,
    logosLoading: false,
    logosError: error,
  })),
  on(UserCustomizationActions.selectBackground, (state, { background }) => ({
    ...state,
    selectedBackground: background,
    customizationToUpdate: { ...state.customizationToUpdate, background },
  })),
  on(UserCustomizationActions.selectLogo, (state, { logo }) => ({
    ...state,
    selectedLogo: logo,
    customizationToUpdate: { ...state.customizationToUpdate, logo },
  })),
  on(UserCustomizationActions.addTeamBackgroundImageToCreate, (state) => ({
    ...state,
    customizationMediaLoading: true,
  })),
  on(UserCustomizationActions.addTeamBackgroundImageToCreateSuccess, (state, { background }) => ({
    ...state,
    customizationMediaLoading: false,
    backgroundsToCreate: [...state.backgroundsToCreate, background],
    backgrounds: [...state.backgrounds, background],
    customizationToUpdate: { ...state.customizationToUpdate, background },
    selectedBackground: background,
  })),
  on(UserCustomizationActions.addTeamBackgroundVideo, (state) => ({
    ...state,
    customizationMediaLoading: true,
  })),
  on(UserCustomizationActions.addTeamBackgroundVideoSuccess, (state, { background }) => ({
    ...state,
    customizationMediaLoading: false,
    customizationToUpdate: { ...state.customizationToUpdate, background: background as GetDomainCustomizationOutput['customization']['background'] }, // FIX ME cast
    backgrounds: [...state.backgrounds, background],
    selectedBackground: background,
  })),
  on(UserCustomizationActions.addTeamLogoToCreate, (state, { logo }) => ({
    ...state,
    customizationMediaLoading: true,
    logosToCreate: [...state.logosToCreate, logo],
  })),
  on(UserCustomizationActions.addTeamLogoToCreateSuccess, (state, { logo }) => ({
    ...state,
    customizationMediaLoading: false,
    logosToCreate: [...state.backgroundsToCreate, logo],
    logos: [...state.logos, logo],
    customizationToUpdate: { ...state.customizationToUpdate, logo },
    selectedLogo: logo,
  })),
  on(UserCustomizationActions.addTeamBackgroundToDelete, (state, { background }) => ({
    ...state,
    backgroundsToDelete: [...state.backgroundsToDelete, background],
    backgrounds: state.backgrounds.filter(_background => background.id !== _background.id),
  })),
  on(UserCustomizationActions.addTeamLogoToDelete, (state, { logo }) => ({
    ...state,
    logosToDelete: [...state.logosToDelete, logo],
    logos: state.logos.filter(_logo => logo.id !== _logo.id),
  })),

  // Save Recipient customization
  on(UserCustomizationActions.updateTeamCustomization, (state) => ({
    ...state,
    customizationUpdating: true,
    customizationUpdated: initialState.customizationUpdated,
    customizationUpdatingError: initialState.customizationUpdatingError,
  })),
  on(UserCustomizationActions.updateCustomizationSuccess, (state) => ({
    ...state,
    customizationUpdated: true,
    ...initialState,

  })),
  on(UserCustomizationActions.updateTeamCustomizationFailure, (state, { error }) => ({
    ...state,
    customizationUpdating: false,
    customizationUpdatingError: error,
  })),


  // Load Sender customization
  on(DomainActions.loadDomainCustomization, (state) => ({
    ...state,
    customizationSender: initialState.customizationSender,
    customizationSenderLoading: true,
    customizationSenderError: initialState.customizationSenderError,
    customizationSenderIsSystem: initialState.customizationSenderIsSystem,
    customizationToUpdate: initialState.customizationToUpdate
  })),
  on(DomainActions.loadDomainCustomizationSuccess, (state, { customization }) => ({
    ...state,
    customizationSender: customization,
    customizationSenderLoading: false,
    selectedBackground: customization.background,
    selectedLogo: customization.logo,
  })),
  on(DomainActions.loadDomainCustomizationFailure, (state, { error }) => ({
    ...state,
    customizationSender: initialState.customizationSender,
    customizationSenderLoading: false,
    customizationSenderError: error,
  })),

  // Add logo & background asset to domain (sender customization)
  on(DomainActions.addNewLogoToDomainCustomization, (state) => ({
    ...state,
    customizationMediaLoading: true,
  })),
  on(DomainActions.addNewLogoToDomainCustomizationSuccess, (state, { logo }) => ({
    ...state,
    customizationMediaLoading: false,
    customizationToUpdate: {
      ...state.customizationToUpdate,
      logo: {
        ...state.customizationToUpdate.logo,
        sourceUrl: logo.downloadUrl,
        thumbnail: logo.downloadUrl,
        type: 'Image' as GetDomainCustomizationOutput['customization']['logo']['type']
      }
    },
  })),
  on(DomainActions.addNewBackgroundImageToDomainCustomization, (state) => ({
    ...state,
    customizationMediaLoading: true,
  })),
  on(DomainActions.addNewBackgroundImageToDomainCustomizationSuccess, (state, { background }) => ({
    ...state,
    customizationMediaLoading: false,
    customizationToUpdate: {
      ...state.customizationToUpdate,
      background: {
        ...state.customizationToUpdate.background,
        sourceUrl: background.downloadUrl,
        type: 'Image' as GetDomainCustomizationOutput['customization']['background']['type']
      }
    }
  })),
  on(DomainActions.addNewBackgroundVideoToDomainCustomization, (state) => ({
    ...state,
    customizationMediaLoading: true,
  })),
  on(DomainActions.addNewBackgroundVideoToDomainCustomizationSuccess, (state, { video }) => ({
    ...state,
    customizationMediaLoading: false,
    customizationToUpdate: {
      ...state.customizationToUpdate,
      background: {
        sourceId: video.id,
        type: video.type as GetDomainCustomizationOutput['customization']['background']['type'],
        sourceUrl: video.url
      }
    } as GetDomainCustomizationOutput['customization'], // FIX ME cast
  })),

  // Save Sender customization
  on(DomainActions.updateDomainCustomization, (state) => ({
    ...state,
    customizationUpdating: true,
    customizationUpdated: initialState.customizationUpdated,
    customizationUpdatingError: initialState.customizationUpdatingError,
  })),
  on(DomainActions.updateDomainCustomizationSuccess, (state) => ({
    ...state,
    customizationUpdated: true,
    ...initialState
  })),
  on(DomainActions.updateDomainCustomizationFailure, (state, { error }) => ({
    ...state,
    customizationUpdating: false,
    customizationUpdatingError: error,
  })),

  // Set the customization to update
  on(UserCustomizationActions.setCustomizationToUpdate, (state, { target }) => ({
    ...state,
    customizationToUpdate: target === 'sender' ? { ...state.customizationSender } : { ...state.customizationReceiver }
  }))
);


export const getCustomizationState = createFeatureSelector<State>('userCustomization');

export const getCustomizationReceiver = createSelector(
  getCustomizationState,
  (state: State) => state.customizationReceiver
);

export const getCustomizationReceiverLoading = createSelector(
  getCustomizationState,
  (state: State) => state.customizationReceiverLoading
);

export const getCustomizationSender = createSelector(
  getCustomizationState,
  (state: State) => state.customizationSender
);

export const getCustomizationSenderLoading = createSelector(
  getCustomizationState,
  (state: State) => state.customizationSenderLoading
);


export const getCustomizationReceiverIsSystem = createSelector(
  getCustomizationState,
  (state: State) => state.customizationReceiverIsSystem
);

export const getTeamBackgrounds = createSelector(
  getCustomizationState,
  (state: State) => state.backgrounds
);

export const getTeamBackgroundsLoading = createSelector(
  getCustomizationState,
  (state: State) => state.backgroundsLoading
);

export const getTeamLogos = createSelector(
  getCustomizationState,
  (state: State) => state.logos
);

export const getTeamLogosLoading = createSelector(
  getCustomizationState,
  (state: State) => state.logosLoading
);

export const getSelectedBackground = createSelector(
  getCustomizationState,
  (state: State) => state.selectedBackground
);

export const getSelectedLogo = createSelector(
  getCustomizationState,
  (state: State) => state.selectedLogo
);

export const getCustomizationToUpdate = createSelector(
  getCustomizationState,
  (state: State) => state.customizationToUpdate
);

export const getCustomizationMediaLoading = createSelector(
  getCustomizationState,
  (state: State) => state.customizationMediaLoading
);

export const getCustomizationUpdating = createSelector(
  getCustomizationState,
  (state: State) => state.customizationUpdating
);

export const getCustomizationUpdated = createSelector(
  getCustomizationState,
  (state: State) => state.customizationUpdated
);

export const getLogosToCreate = createSelector(
  getCustomizationState,
  (state: State) => state.logosToCreate
);
export const getBackgroundsToCreate = createSelector(
  getCustomizationState,
  (state: State) => state.backgroundsToCreate
);
export const getLogosToDelete = createSelector(
  getCustomizationState,
  (state: State) => state.logosToDelete
);

export const getBackgroundToDelete = createSelector(
  getCustomizationState,
  (state: State) => state.backgroundsToDelete
);

export function reducer(state: State | undefined, action: Action) {
  return customizationReducer(state, action);
}


