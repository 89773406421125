import { Group } from '@app/shared/models/group';
import { Team } from '@app/shared/models/team';
import { User } from '@app/shared/models/user';
import { createAction, props } from '@ngrx/store';

export const loadAccountTeams = createAction(
  '[Teams] Load Account Teams'  
);

export const loadAccountTeamsSuccess = createAction(
  '[Teams] Load Account Teams Success',
  props<{ teams: Team[] }>()
);

export const loadAccountTeamsFailure = createAction(
  '[Teams] Load Account Teams Failure',
  props<{ error: any }>()
);

// Load Team Details
export const LoadTeamDetails = createAction(
  '[Teams] Load Team Details',
  props<{ id: string }>()
);

export const LoadTeamDetailsSuccess = createAction(
  '[Teams] Load Team Details Success',
  props<{ team: Team }>()
);

export const LoadTeamDetailsFailure = createAction(
  '[Teams] Load Team Details Failure',
  props<{ error: any }>()
);

// Load Team Groups
export const LoadTeamGroups = createAction(
  '[Teams] Load Team Groups',
  props<{ id: string }>()
);

export const LoadTeamGroupsSuccess = createAction(
  '[Teams] Load Team Groups Success',
  props<{ groups: Group[] }>()
);

export const LoadTeamGroupsFailure = createAction(
  '[Teams] Load Team Groups Failure',
  props<{ error: any }>()
);

// Load Team Members
export const LoadTeamMembers = createAction(
  '[Teams] Load Team Members',
  props<{ id: string }>()
);

export const LoadTeamMembersSuccess = createAction(
  '[Teams] Load Team Members Success',
  props<{ members: any[] }>()
);

export const LoadTeamMembersFailure = createAction(
  '[Teams] Load Team Members Failure',
  props<{ error: any }>()
);

export const addTeam = createAction(
  '[Teams] Add Account Teams',
  props<{ team: any }>()
);

export const addTeamSuccess = createAction(
  '[Teams] Add Account Team Success',
  props<{ team: Team }>()
);

export const addTeamFailure = createAction(
  '[Teams] Add Account Team Failure',
  props<{ error: any }>()
);

// Delete team
export const DeleteTeam = createAction(
  '[Teams] Delete Teams',
  props<{ teamId: string, domain: string }>()
);

export const DeleteTeamSuccess = createAction(
  '[Teams] Delete Team Success'
);

export const DeleteTeamFailure = createAction(
  '[Teams] Delete Team Failure',
  props<{ error: any }>()
);


export const addMember = createAction(
  '[Teams] Add Member',
  props<{ member: User, team: any, role: any }>()
);

export const addMemberSuccess = createAction(
  '[Teams] Add Member Success',
  props<{ member: User }>()
);

export const addMemberFailure = createAction(
  '[Teams] Add Member Failure',
  props<{ error: any }>()
);

export const updateTeam = createAction(
  '[Teams] Update Team',
  props<{ team: any }>()
);
export const updateTeamSuccess = createAction(
  '[Teams] Update Team Success',
  props<{ team: Team }>()
);
export const updateTeamFailure = createAction(
  '[Teams] Update Team Failure',
  props<{ error: number }>()
);

export const resetTeamCreationError = createAction(
  '[Teams] Reset Team Creation Error',
);

export const resetTeamEditionError = createAction(
  '[Teams] Reset Team Edition Error',
);

export const AddEmail = createAction(
  '[Teams] Add Email',
  props<{ team: string, email: string }>()
);

export const AddEmailFailure = createAction(
  '[Teams] Add Email Failure',
  props<{ error: string }>()
);

export const DetachMemberFromTeam = createAction(
  '[Members] Detach Member From Team',
  props<{ member: User, team: any }>()
);

export const DetachMemberFromTeamSuccess = createAction(
  '[Members] Detach Member From Team Success'
);

export const DetachMemberFromTeamFailure = createAction(
  '[Members] Detach Member From Team Failure',
  props<{ error: any }>()
);

export const DetachGuestFromTeam = createAction(
  '[Teams] Delete Guest From Team',
  props<{ team: string, guest: any }>()
);

export const DetachGuestFromTeamSuccess = createAction(
  '[Teams] Delete Guest From Team Success',
  props<{ team: Team }>()
);

export const DetachGuestFromTeamFailure = createAction(
  '[Teams] Delete Guest From Team Failure',
  props<{ error: any }>()
);

export const AddEmailPattern = createAction(
  '[Teams] Add Email Pattern',
  props<{ team: string, pattern: string }>()
);

export const AddEmailPatternFailure = createAction(
  '[Teams] Add Email Pattern Failure',
  props<{ error: string }>()
);

export const UpdateTeamConfig = createAction(
  '[Teams] Update Team Config',
  props<{ team: string, config: any }>()
);

export const UpdateTeamConfigSuccess = createAction(
  '[Teams] Update Team Config Success',
  props<{ team: any }>()
);

export const UpdateTeamConfigFailure = createAction(
  '[Teams] Update Team Config Failure',
  props<{ error: string }>()
);

export const ResetError = createAction(
  '[Teams] Reset Error'
);

export const ResetDeleteTeam = createAction(
  '[Teams] Reset Delete Team'
);