import { ThemeSimplified } from '@app/shared/helpers/theme';
import { Action, createReducer, on } from '@ngrx/store';
import { ThemeActions } from '../actions';
export const themeFeatureKey = 'theme';

export interface State {
  theme: ThemeSimplified;
  themeLoading: boolean;
  themeLoaded: boolean;
}

export const initialState: State = {
  theme: null,
  themeLoading: false,
  themeLoaded: false,
};

const themeReducer = createReducer(
  initialState,
  on(ThemeActions.LoadTheme, (state) => ({
    ...state,
    theme: null,
    themeLoading: true,
    themeLoaded: false,
  })),
  on(ThemeActions.LoadThemeSuccess, (state, { theme }) => ({
    ...state,
    theme,
    themeLoading: false,
    themeLoaded: true,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return themeReducer(state, action);
}
