import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { REQUEST } from '../../../express.tokens';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(
    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  detectDomain(): string {
    if (isPlatformServer(this.platformId)) {
      return this.req.headers['x-forwarded-host'] as string;
    }
    return document.location.hostname;
  }

  detectDomainUrl() {
    if (isPlatformServer(this.platformId)) {
      return this.req.protocol + '://' + this.detectDomain() + this.req.baseUrl;
    }
    return document.location.href;
  }

  detectDomainUrlWithoutProtocol() {
    if (isPlatformServer(this.platformId)) {
      return this.detectDomain() + this.req.originalUrl;
    }
    return document.location.href.replace(/https?:\/\//i, '');
  }

  detectDomainUrlWithoutQueryString() {
    if (isPlatformServer(this.platformId)) {
      return this.detectDomain() + this.req.originalUrl.split('?').shift();
    }
    return document.location.hostname + document.location.pathname;
  }

  detectPath() {
    if (isPlatformServer(this.platformId)) {
      return this.req.originalUrl;
    }
    return document.location.pathname;
  }

}
