import { createAction, props } from '@ngrx/store';

export const loadScripts = createAction(
  '[Script] Load Scripts',
  props<{ scripts: string[] }>()
);

export const loadScriptsSuccess = createAction(
  '[Script] Load Scripts Success',
  props<{ scripts: string[] }>()

);

export const loadScriptsFailure = createAction(
  '[Script] Load Scripts Failure',
  props<{ errorMessage: string }>()

);

export const addScript = createAction(
  '[Script] Add Script',
  props<{ script: string }>()
);

export const addScriptSuccess = createAction(
  '[Script] Add Script Success',
  props<{ script: string }>()
);

export const addScriptFailure = createAction(
  '[Script] Add Script Failure',
  props<{ error: any }>()
);

export const loadStripeV3 = createAction(
  '[Script] Load Stripe V3',
);

export const loadStripeV3Success = createAction(
  '[Script] Load Stripe V3 Success',
  props<{ script: string }>()
);

export const loadStripeV3Failure = createAction(
  '[Script] Load Stripe V3 Failure',
  props<{ error: any }>()
);

export const loadStripeV2 = createAction(
  '[Script] Load Stripe V2',
);

export const loadStripeV2Success = createAction(
  '[Script] Load Stripe V2 Success',
  props<{ script: string }>()
);

export const loadStripeV2Failure = createAction(
  '[Script] Load Stripe V2 Failure',
  props<{ error: any }>()
);



