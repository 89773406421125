import { Pipe, PipeTransform } from '@angular/core';
import { getPropByString } from '../helpers/helpers';

@Pipe({
  name: 'arraySorter'
})
export class ArraySorterPipe implements PipeTransform {

  transform(array: Array<any>, type: string, parameter?: string, order?: string): any {
    if (!parameter) {
      switch (type) {
        case 'string':
          return this.sortArrayByString(array, order);
        case 'date':
          return this.sortArrayByDate(array, order);
        case 'number':
          return this.sortArrayByNumber(array, order);
        default:
          return array;
      }
    } else {
      switch (type) {
        case 'string':
          return this.sortArrayByStringWithParameter(array, parameter, order);
        case 'date':
          return this.sortArrayByDateWithParameter(array, parameter, order);
        case 'number':
          return this.sortArrayByNumberWithParameter(array, parameter, order);
        default:
          return array;
      }
    }
  }


  sortArrayByString(array: Array<any>, order?: string) {
    return array.slice().sort((a, b) => {
      if (order === 'asc') {
        if (a < b) { return -1; } else if (a > b) { return 1; } else { return 0; }
      } else if (order === 'desc') {
        if (a > b) { return -1; } else if (a < b) { return 1; } else { return 0; }
      } else {
        if (a < b) { return -1; } else if (a > b) { return 1; } else { return 0; }
      }
    });
  }

  sortArrayByNumber(array: Array<any>, order?: string) {
    return array.slice().sort((a, b) => {
      if (order === 'asc' || !order) {
        return a - b;
      } else if (order === 'desc') {
        return b - a;
      }
    });
  }

  sortArrayByDate(array: Array<any>, order?: string) {
    return array.slice().sort((a, b) => {
      if (order === 'asc' || !order) {
        return new Date(b).getTime() - new Date(a).getTime();
      } else if (order === 'desc') {
        return new Date(a).getTime() - new Date(b).getTime();
      }
    });
  }

  sortArrayByStringWithParameter(array: Array<any>, parameter: string, order?: string) {
    return array.slice().sort((a, b) => {
      const aValue = getPropByString(a, parameter);
      const bValue = getPropByString(b, parameter);
      if (order === 'asc') {
        if (!aValue && bValue) { return 1; }
        if (aValue && !bValue) { return -1; }
        if (!aValue && !bValue) { return 0; }
        if (aValue < bValue) { return -1; } else if (aValue > bValue) { return 1; } else { return 0; }
      } else if (order === 'desc') {
        if (!aValue && bValue) { return -1; }
        if (aValue && !bValue) { return 1; }
        if (!aValue && !bValue) { return 0; }
        if (aValue > bValue) { return -1; } else if (aValue < bValue) { return 1; } else { return 0; }
      } else {
        if (aValue < bValue) { return -1; } else if (aValue > bValue) { return 1; } else { return 0; }
      }
    });
  }

  sortArrayByNumberWithParameter(array: Array<any>, parameter: string, order?: string) {
    return array.slice().sort((a, b) => {
      const aValue = getPropByString(a, parameter);
      const bValue = getPropByString(b, parameter);
      if (order === 'asc' || !order) {
        return aValue - bValue;
      } else if (order === 'desc') {
        return bValue - aValue;
      }
    });
  }

  sortArrayByDateWithParameter(array: Array<any>, parameter: string, order?: string) {
    return array.slice().sort((a, b) => {
      const aValue = getPropByString(a, parameter);
      const bValue = getPropByString(b, parameter);
      if (order === 'asc' || !order) {
        return new Date(bValue).getTime() - new Date(aValue).getTime();
      } else if (order === 'desc') {
        return new Date(aValue).getTime() - new Date(bValue).getTime();
      }
    });
  }
}
