import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import * as fromRoot from '@app/reducers';
import { LoggerService } from '@app/core/services/logger.service';

@Injectable()
export class EndpointInterceptor implements HttpInterceptor {

    regexpService = /service:\w+/g; // Move to a dedicated file ?

    constructor(private store: Store<fromRoot.State>, private loggerService: LoggerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.addEndpoint(req).pipe(
            first(),
            mergeMap((requestWithEndpoint: HttpRequest<any>) => next.handle(requestWithEndpoint))
        );
    }

    private addEndpoint(req: HttpRequest<any>): Observable<HttpRequest<any>> {
        if (this.regexpService.test(req.url)) {
            return this.store.pipe(
                select(fromRoot.selectServices),
                first(),
                mergeMap(({ services, region }) => {
                    if (services.length) {
                        const splitedUrl = req.url.match(this.regexpService)[0].split(':');
                        const serviceFound = services.find(service => (service.name === splitedUrl[1] && service.region === region));
                        req = req.clone({
                            url: req.url.replace(this.regexpService, serviceFound.url)
                        });
                    } else {
                        this.loggerService.error('Service not found in service');
                    }
                    return of(req);
                })
            );
        } else {
            return of(req);
        }
    }
}
