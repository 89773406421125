import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/reducers/index';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { EnvironmentActions } from '@app/core/actions';
import { Constant } from 'src/constant';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-smash-select-lang',
  templateUrl: './smash-select-lang.component.html',
  styleUrls: ['./smash-select-lang.component.scss']
})
export class SmashSelectLangComponent implements OnInit {

  @Input() dropdownDirection?: string = "down";
  @Input() chevronColor?: string = "#CCC"
  @Input() withBorder?: boolean = false;
  @Input() headerLocation?: string;
  @ViewChild('elementRef') elementRef: ElementRef;
  menuToggle = false;
  subscriptions: Subscription[] = [];
  selectedLanguage: string;
  supportedLanguages: { id: string, name: string }[] = [];
  pathname: string;
  constructor(
    private readonly store$: Store<fromRoot.State>,
    @Inject(PLATFORM_ID) private platformId: any
  ) {

    this.subscriptions.push(
      this.store$.pipe(
        withLatestFrom(
          this.store$.select(fromRoot.getSupportedLanguages),
          this.store$.select(fromRoot.getSelectedLanguage)
        ),
        distinctUntilChanged()
      ).subscribe(([state, supportedLanguages, selectedLanguage]) => {
        this.supportedLanguages = supportedLanguages.filter(sl => sl.id !== selectedLanguage)
        this.selectedLanguage = supportedLanguages.find(l => l.id === selectedLanguage)?.name
      })
    )
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      const pathFragments = location.pathname.split('/');
      const languageExistsInPath = Constant.supportedLanguages.find(lang => lang.id === pathFragments[1]);
      if (languageExistsInPath) {
        pathFragments.splice(1, 1);
      }
      this.pathname = pathFragments.length === 1 ? '/' : pathFragments.join('/');
    }
  }

  selectLanguage($event, languageId) {
    // only if it's english
    if (languageId === 'en' || this.headerLocation === 'viewer') {
      this.store$.dispatch(EnvironmentActions.SelectLanguage({ language: languageId }))
    }
    // otherwise it triggers the switch language guard
  }

  closeMenu($event) {
    if (this.menuToggle === true) {
      this.menuToggle = false;
    }
  }

  toggleMenu() {
    this.menuToggle = !this.menuToggle;
  }

  get dropdownDirectionCompute() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerHeight > 660) {
        return this.dropdownDirection;
      }
      return 'up';
    }
  }
}
