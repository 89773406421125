import { ListManagedThemesPublicOutput } from '@smash-sdk/theme/04-2024';
import moment from "moment";

interface SelectThemeParameters {
    themeId?: string;
    language?: string;
    location?: string;
    device?: 'Desktop' | 'Mobile';
    target?: ListManagedThemesPublicOutput['themes'][0]['target'];
    status?: ListManagedThemesPublicOutput['themes'][0]['status'];
    previousThemeId?: string;
}

const defaultSelectThemeParameters: SelectThemeParameters = {
    language: 'Default',
    location: 'Global',
    device: 'Desktop',
    target: 'All',
    status: 'Published',
};

export interface ThemeSimplified {
    id?: string;
    name?: string;
    status?: ListManagedThemesPublicOutput['themes'][0]['status'];
    target: ListManagedThemesPublicOutput['themes'][0]['target'];
    locations?: {
        type: ListManagedThemesPublicOutput['themes'][0]['locations'][0]['type'];
        name: string;
        voiceShare?: number;
        rule?: ListManagedThemesPublicOutput['themes'][0]['locations'][0]['rule'];
    }[];
    periods?: {
        startDate: string;
        endDate: string;
        periodsOfDay?: {
            startTime: string;
            endTime: string;
        }[];
    }[];
    teasing?: {
        headerLogo?: string;
        headerUrl?: string;
        centralLogo?: string;
        headerText?: string;
        background?: {
            type: ListManagedThemesPublicOutput['themes'][0]['desktop']['teasing']['background']['type'];
            url: string;
        };
    };
    reveal?: {
        teasing?: {
            text?: string;
            background?: {
                type: ListManagedThemesPublicOutput['themes'][0]['desktop']['reveal']['teasing']['background']['type'];
                url: string;
            };
        };
        text?: string;
        callToAction?: {
            label: string;
            url: string;
        };
        medias?: {
            type: ListManagedThemesPublicOutput['themes'][0]['desktop']['reveal']['medias']['default'][0]['type'];
            position: number;
            url: string;
        }[];
    };
    trackingMethods?: {
        impressionTag?: {
            iframe?: {
                url: string;
            };
            script?: {
                url: string;
            };
            pixel?: {
                url: string;
            };
            noscript?: {
                url: string;
            };
        };
        clickTag?: {
            url: string;
        };
    };
}

export function isLocationWorldwideIncluded(locations: ListManagedThemesPublicOutput['themes'][0]['locations']): boolean {
    return locations.some(location => location.name === 'WW' && location.rule === 'Include');
}

export function isLocationExplicitlyIncluded(locations: ListManagedThemesPublicOutput['themes'][0]['locations'], targetLocation: string): boolean {
    return locations.some(location => location.name.toLowerCase() === targetLocation.toLowerCase() && location.rule === 'Include');
}

export function isExclusionRuleExists(locations: ListManagedThemesPublicOutput['themes'][0]['locations']): boolean {
    return locations.some(location => location.rule === 'Exclude');
}

export function isLocationExplicitlyExcluded(locations: ListManagedThemesPublicOutput['themes'][0]['locations'], targetLocation: string): boolean {
    return locations.some(location => location.name.toLowerCase() === targetLocation.toLowerCase() && location.rule === 'Exclude');
}

export function getLocationVoiceShare(locations: ListManagedThemesPublicOutput['themes'][0]['locations'], targetLocation: string): number {
    if (isLocationExplicitlyExcluded(locations, targetLocation)) {
        return 0;
    }
    if (isLocationExplicitlyIncluded(locations, targetLocation)) {
        return locations.find(location => location.name === targetLocation).voiceShare ?? 0;
    }
    if (isLocationWorldwideIncluded(locations)) {
        return locations.find(location => location.name === 'WW').voiceShare ?? 0;
    }
    return 0;
}

export function calculateVoiceSharesByLocation(themes: ListManagedThemesPublicOutput['themes'], targetLocation: string): number[] {
    const voiceShares: number[] = [];
    let targetLocationTotalVoiceShare = 0;

    for (const theme of themes) {
        targetLocationTotalVoiceShare += getLocationVoiceShare(theme.locations, targetLocation);
    }

    // Normalize voice shares
    for (const theme of themes) {
        const themeVoiceShare = getLocationVoiceShare(theme.locations, targetLocation);
        const normalizedShare = targetLocationTotalVoiceShare ? themeVoiceShare / targetLocationTotalVoiceShare : 0;
        voiceShares.push(normalizedShare);
    }

    return voiceShares;
}

export function selectThemeBasedOnVoiceShare(
    themes: ListManagedThemesPublicOutput['themes'],
    params: SelectThemeParameters,
): ListManagedThemesPublicOutput['themes'][0] {
    const { previousThemeId, location } = params;
    const normalizedVoiceShares = calculateVoiceSharesByLocation(themes, location).filter(share => share > 0);
    const randomValue = Math.random(); // Random number between 0 and 1
    let accumulatedShare = 0;

    // we don't want select a 0 voice share theme
    for (let i = 0; i < normalizedVoiceShares.length; i++) {
        accumulatedShare += normalizedVoiceShares[i];
        if (randomValue <= accumulatedShare) {
            const selectedTheme = themes[i];

            if (selectedTheme.id !== previousThemeId) {
                return selectedTheme;
            }

            if (themes.length === 1) {
                return selectedTheme;
            }

            return selectThemeBasedOnVoiceShare(themes, params);
        }
    }
}

export function isThemeInDisplayPeriod(theme: ListManagedThemesPublicOutput['themes'][0]): boolean {
    if (theme?.periods?.length > 0) {
        if (!theme.periods.some(period => {
            const startDate = moment(period.startDate);
            const endDate = moment(period.endDate);
            if (period.periodsOfDay?.length) {
                const format = 'hh:mm:ss';
                return period.periodsOfDay.some(periodOfDay => {
                    const startTime = moment(periodOfDay.startTime, format);
                    const endTime = moment(periodOfDay.endTime, format);
                    return moment().isBetween(startDate, endDate) && moment().isBetween(startTime, endTime);
                });
            }
            return moment().isBetween(startDate, endDate);
        })) {
            return false;
        }
    }

    return true;
}

function simplifyTheme(theme: ListManagedThemesPublicOutput['themes'][0], device: 'Desktop' | 'Mobile', language: string): ThemeSimplified {
    const parsedTeasing = device === 'Mobile' ? theme.mobile?.teasing : theme.desktop?.teasing;
    const parsedReveal = device === 'Mobile' ? theme.mobile?.reveal : theme.desktop?.reveal;
    const trackingMethods = device === 'Mobile' ? theme.mobile?.trackingMethods : theme.desktop?.trackingMethods;
    const parsedLanguage = language.toLowerCase();
    const teasingCorrespondingToLanguage = {
        headerLogo: parsedTeasing?.headerLogo?.url[parsedLanguage] ?? parsedTeasing?.headerLogo?.url.default,
        headerUrl: parsedTeasing?.headerUrl?.[parsedLanguage] ?? parsedTeasing?.headerUrl?.default,
        centralLogo: parsedTeasing?.centralLogo?.url[parsedLanguage] ?? parsedTeasing?.centralLogo?.url.default,
        headerText: parsedTeasing?.headerText?.[parsedLanguage] ?? parsedTeasing?.headerText?.default,
        background: {
            type: parsedTeasing?.background?.type,
            url: parsedTeasing?.background?.url[parsedLanguage] ?? parsedTeasing?.background?.url.default,
        },
    };
    const revealCorrespondingToLanguage = {
        teasing: {
            text: parsedReveal?.teasing?.text?.[parsedLanguage] ?? parsedReveal?.teasing?.text?.default,
            background: {
                type: parsedReveal?.teasing?.background?.type,
                url: parsedReveal?.teasing?.background?.url[parsedLanguage] ?? parsedReveal?.teasing?.background?.url.default,
            },
        },
        text: parsedReveal?.text?.[parsedLanguage] ?? parsedReveal?.text?.default,
        callToAction: {
            label: parsedReveal?.callToAction?.label[parsedLanguage] ?? parsedReveal?.callToAction?.label.default,
            url: parsedReveal?.callToAction?.url[parsedLanguage] ?? parsedReveal?.callToAction?.url.default,
        },
        medias: parsedReveal?.medias?.[parsedLanguage] ?? parsedReveal?.medias?.default ?? [],
    };

    return {
        id: theme.id,
        name: theme.name,
        status: theme.status,
        target: theme.target,
        locations: theme.locations,
        periods: theme.periods,
        teasing: teasingCorrespondingToLanguage,
        reveal: revealCorrespondingToLanguage,
        trackingMethods,
    };
}

export function selectTheme(
    themes: ListManagedThemesPublicOutput['themes'],
    params: SelectThemeParameters = defaultSelectThemeParameters,
): ThemeSimplified {
    const { themeId, language, location, device, target } = params;

    // Filter themes base on the themeId
    if (themeId) {
        const theme = themes.find(theme => theme.id === themeId);
        if (theme) {
            return simplifyTheme(theme, device, language);
        }
    }

    // Filter themes base on the device
    themes = themes.filter(theme => theme[device.toLowerCase()] !== undefined);
    // Filter themes base on the target
    themes = themes.filter(theme => theme.target === target);
    // Filter themes base on the status
    themes = themes.filter(theme => theme.status === 'Published');
    // Filter on theme display period
    themes = themes.filter(theme => isThemeInDisplayPeriod(theme));
    // Filter on location
    themes = themes.filter(theme => {
        if (isExclusionRuleExists(theme.locations)) {
            return !isLocationExplicitlyExcluded(theme.locations, location);
        }
        return isLocationExplicitlyIncluded(theme.locations, location) || isLocationWorldwideIncluded(theme.locations);
    });
    const selectedTheme = selectThemeBasedOnVoiceShare(themes, params);

    return selectedTheme ? simplifyTheme(selectedTheme, device, language) : null;
}
